@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */ /* Add your variable customizations of layout here */ /* Utils */
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

.card {
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.9);
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.card .card-header {
  font-weight: 500;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.card .card-subtitle {
  color: #8A8EA6;
  font-size: 0.857rem;
  font-weight: 500;
  margin: -1rem 0 1rem 0;
}

.p-toast.p-toast-top-right, .p-toast.p-toast-top-left, .p-toast.p-toast-top-center {
  top: 70px;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}
h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

mark {
  background: #FFF8E1;
  padding: 0.25rem 0.4rem;
  font-family: monospace;
}

blockquote {
  margin: 1rem 0;
  padding: 0 2rem;
  border-left: 4px solid #90A4AE;
}

hr {
  border-top: solid rgba(68, 72, 109, 0.2);
  border-width: 1px 0 0 0;
  margin: 1rem 0;
}

p {
  margin: 0 0 1rem 0;
  line-height: 1.5;
}
p:last-child {
  margin-bottom: 0;
}

.loading-wrapper {
  height: 100vh;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.loading-wrapper > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
}

.layout-breadcrumb-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding-top: 30px;
}
.layout-breadcrumb-container .layout-breadcrumb {
  background: transparent;
  border: 0 none;
  border-radius: 0;
  padding: 7px 0 7px 30px;
}
.layout-breadcrumb-container .layout-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.75rem;
}
.layout-breadcrumb-container .layout-breadcrumb ul li.p-breadcrumb-chevron::before {
  content: "⁄";
  font-weight: bold;
  font-size: 1.25rem;
}
.layout-breadcrumb-container .layout-breadcrumb ul li .p-menuitem-link {
  font-size: 1.25rem;
}
.layout-breadcrumb-container .layout-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #8A8EA6;
}
.layout-breadcrumb-container .layout-breadcrumb ul li:last-child .p-menuitem-text {
  color: #44486D;
}
.layout-breadcrumb-container .layout-breadcrumb-left-items {
  display: flex;
  align-items: center;
}
.layout-breadcrumb-container .layout-breadcrumb-right-items {
  display: flex;
}
.layout-breadcrumb-container .layout-breadcrumb-right-items .search-icon {
  display: none;
  align-items: center;
  justify-content: center;
  color: #44486D;
  width: 32px;
  height: 32px;
  margin-right: 0.5rem;
}
.layout-breadcrumb-container .layout-breadcrumb-right-items .search-wrapper {
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}
.layout-breadcrumb-container .layout-breadcrumb-right-items .search-wrapper .p-input-icon-left {
  margin-right: 0.5rem;
  width: 100%;
}
.layout-breadcrumb-container .layout-breadcrumb-right-items .search-wrapper .p-input-icon-left input {
  background: transparent;
  border-color: transparent;
}

@media screen and (max-width: 991px) {
  .layout-breadcrumb-container .layout-breadcrumb-right-items .search-icon {
    display: flex;
  }
  .layout-breadcrumb-container .layout-breadcrumb-right-items .search-wrapper {
    width: 0;
    position: absolute;
    right: 40px;
    background: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%);
    border-radius: 6px;
    transition: width 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  }
  .layout-breadcrumb-container .layout-breadcrumb-right-items .search-wrapper input {
    width: 0;
  }
  .layout-breadcrumb-container .layout-breadcrumb-right-items .search-wrapper i {
    color: transparent;
  }
  .layout-breadcrumb-container .layout-breadcrumb-right-items .search-wrapper.active-search-wrapper {
    width: calc(100% - 70px);
    transition: width 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  }
  .layout-breadcrumb-container .layout-breadcrumb-right-items .search-wrapper.active-search-wrapper input {
    width: 100%;
  }
}
.layout-topbar {
  z-index: 100;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
}
.layout-topbar .layout-topbar-left {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: none;
  align-items: center;
}
.layout-topbar .layout-topbar-left .logo {
  display: none;
  margin-left: 20px;
}
.layout-topbar .layout-topbar-left .logo img {
  height: 2rem;
}
.layout-topbar .layout-topbar-left .app-name {
  display: none;
  margin-left: 9px;
  height: 1.714rem;
}
.layout-topbar .layout-topbar-left .topbar-menu-button {
  display: none;
  width: 32px;
  height: 32px;
  line-height: 35px;
  margin-left: 20px;
  text-align: center;
  border-radius: 6px;
  background-color: rgba(68, 72, 109, 0.07);
  color: #676B89;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.layout-topbar .layout-topbar-left .topbar-menu-button:hover {
  color: #44486D;
}
.layout-topbar .layout-topbar-left .topbar-menu-button i {
  font-size: 1.25rem;
}
.layout-topbar .layout-topbar-right {
  display: none;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items {
  list-style-type: none;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  height: 100%;
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li {
  display: flex;
  align-items: center;
  position: relative;
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > a,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li button {
  position: relative;
  display: flex;
  align-items: center;
  color: #676B89;
  background-color: rgba(68, 72, 109, 0.07);
  width: 36px;
  height: 36px;
  margin: 0 0.4rem;
  padding: 0.75rem 0.5rem;
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > a:hover,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li button:hover {
  color: #44486D;
  background-color: rgba(68, 72, 109, 0.12);
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > a .topbar-icon,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li button .topbar-icon {
  font-size: 1.5rem;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > a .topbar-item-name,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li button .topbar-item-name {
  display: none;
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > a .topbar-badge,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li button .topbar-badge {
  position: absolute;
  right: 2px;
  top: 2px;
  background-color: #FCB71E;
  color: #ffffff;
  padding: 2px 4px;
  display: block;
  font-size: 11px;
  line-height: 10px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li.profile-item {
  margin-right: 20px;
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li.profile-item > a,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li.profile-item button {
  background-color: transparent;
  padding: 0;
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li.profile-item > a img,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li.profile-item button img {
  width: 32px;
  height: 32px;
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > ul {
  position: absolute;
  top: 56px;
  right: 0;
  display: none;
  min-width: 12rem;
  animation-duration: 0.5s;
  list-style-type: none;
  margin: 0;
  z-index: 1;
  padding: 8px 0;
  border-radius: 6px;
  background: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%);
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > ul a,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > ul button {
  padding: 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  color: #676B89;
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > ul a i,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > ul button i {
  margin-right: 8px;
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > ul a img,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > ul button img {
  margin-right: 8px;
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > ul a i,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > ul a img,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > ul a span,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > ul button i,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > ul button img,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > ul button span {
  vertical-align: middle;
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > ul a:hover,
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li > ul button:hover {
  color: #44486D;
  background-color: rgba(68, 72, 109, 0.07);
}
.layout-topbar .layout-topbar-right .layout-topbar-right-items > li.active-topmenuitem > ul {
  display: block;
}

@media (max-width: 991px) {
  .layout-wrapper .layout-topbar {
    padding: 0;
    display: flex;
  }
  .layout-wrapper .layout-topbar .layout-topbar-left {
    display: flex;
  }
  .layout-wrapper .layout-topbar .layout-topbar-left .logo {
    display: inline;
  }
  .layout-wrapper .layout-topbar .layout-topbar-left .app-name {
    display: inline;
    height: 0.714em;
  }
  .layout-wrapper .layout-topbar .layout-topbar-left .topbar-menu-button {
    display: inline;
  }
  .layout-wrapper .layout-topbar .layout-topbar-right {
    display: inline;
  }
}
html {
  height: 100%;
  font-size: 14px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  color: #44486D;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
body a, body button {
  color: #FCB71E;
  text-decoration: none;
}

.layout-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background: url("../../../layout/images/effect-onlight.png");
  background-repeat: no-repeat;
}
.layout-wrapper .layout-main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 100vh;
  -moz-transition: margin-left 0.3s;
  -o-transition: margin-left 0.3s;
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
}
.layout-wrapper .layout-main .layout-main-content {
  flex: 1 1 0;
  padding: 30px;
}

@media screen and (max-width: 991px) {
  .layout-wrapper .layout-main .layout-menu-wrapper {
    display: none;
  }
  .layout-wrapper .layout-main .layout-topbar .layout-menu-wrapper {
    display: flex;
  }
}
.layout-menu-wrapper {
  position: fixed;
  height: 100%;
  width: 21rem;
  left: 0;
  top: 0;
  z-index: 997;
  transform: none;
  border-radius: 0px 40px 40px 0px;
  display: flex;
  flex-direction: column;
}
.layout-menu-wrapper .menu-logo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px 22px 0 34px;
}
.layout-menu-wrapper .menu-logo .menu-pin {
  display: none;
  width: 32px;
  height: 32px;
  margin-left: auto;
  border-radius: 6px;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.layout-menu-wrapper .menu-logo .menu-pin > span {
  font-size: 1.25rem;
}
.layout-menu-wrapper .menu-logo .logo img {
  height: 2.5rem;
  border: 0 none;
}
.layout-menu-wrapper .menu-logo .app-name img {
  height: 1.714rem;
  padding-left: 16px;
}
.layout-menu-wrapper ul {
  margin: 0;
  padding: 0;
  outline: 0;
  overflow: hidden;
  list-style-type: none;
}
.layout-menu-wrapper .layout-menu-container {
  height: 100%;
  overflow: auto;
  padding-bottom: 2rem;
}
.layout-menu-wrapper .layout-menu-container .layout-menu {
  padding: 0 1.5rem;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li {
  border-radius: 8px;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li.layout-root-menuitem > div {
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 12px;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li.layout-root-menuitem > div > .layout-menuitem-text {
  font-size: 0.857rem;
  font-weight: 600;
  text-transform: uppercase;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > a {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  transition: background-color 0.2s, color 0.2s;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > a .layout-menuitem-text {
  margin-left: 1rem;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > a .layout-submenu-toggler {
  margin-left: auto;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > a.rotated-icon .layout-menuitem-icon {
  transform: rotate(90deg);
}
.layout-menu-wrapper .layout-menu-container .layout-menu li a .p-badge {
  margin-left: auto !important;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li.active-menuitem > a .layout-submenu-toggler {
  transition: all 0.2s;
  transform: rotate(-180deg);
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > ul {
  padding: 0;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > ul li ul {
  padding: 0 0 0.25rem;
  overflow: hidden;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > ul > li {
  margin-top: 0.2rem;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > ul > li.layout-root-menuitem > a {
  display: flex;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > ul > li ul li a {
  padding-left: 16px;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > ul > li ul li ul li a {
  padding-left: 24px;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > ul > li ul li ul li ul li a {
  padding-left: 32px;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > ul > li ul li ul li ul li ul li a {
  padding-left: 40px;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > ul > li ul li ul li ul li ul li ul li a {
  padding-left: 48px;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > ul > li ul li ul li ul li ul li ul li ul li a {
  padding-left: 56px;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > ul > li ul li ul li ul li ul li ul li ul li ul li a {
  padding-left: 64px;
}
.layout-menu-wrapper .layout-menu-container .layout-menu > li:first-child {
  margin-top: 2rem;
}
.layout-menu-wrapper .layout-menu-container .layout-menu .layout-menu-enter-from,
.layout-menu-wrapper .layout-menu-container .layout-menu .layout-menu-leave-to {
  max-height: 0;
}
.layout-menu-wrapper .layout-menu-container .layout-menu .layout-menu-enter-to,
.layout-menu-wrapper .layout-menu-container .layout-menu .layout-menu-leave-from {
  max-height: 1000px;
}
.layout-menu-wrapper .layout-menu-container .layout-menu .layout-menu-enter-active {
  overflow: hidden;
  transition: max-height 0.45s ease-in-out;
}
.layout-menu-wrapper .layout-menu-container .layout-menu .layout-menu-leave-active {
  overflow: hidden;
  transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
}

@media screen and (max-width: 991px) {
  .layout-wrapper .layout-menu-wrapper {
    mix-blend-mode: normal;
    transform: translateX(-100%);
    transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  }
  .layout-wrapper .layout-menu-wrapper .layout-menu {
    padding: 0 1.5rem;
    overflow: auto;
  }
  .layout-wrapper .layout-menu-wrapper .layout-menu li.layout-root-menuitem > div {
    display: none;
  }
  .layout-wrapper .layout-menu-wrapper .layout-menu li ul li {
    padding-top: 6px;
  }
  .layout-wrapper .layout-menu-wrapper .layout-menu li ul ul li a {
    padding-right: 11px;
  }
  .layout-wrapper .layout-menu-wrapper .layout-menu li > ul li {
    padding-left: 0.3rem;
    padding-right: 0.2rem;
  }
  .layout-wrapper .layout-menu-wrapper .layout-menu > li {
    padding: 6px;
  }
  .layout-wrapper .layout-menu-wrapper .layout-menu > li > a {
    padding: 0.75rem 1.25rem;
  }
  .layout-wrapper.layout-mobile-active .layout-menu-wrapper {
    transform: translateX(0);
  }
}
@media screen and (max-width: 991px) {
  .layout-wrapper.layout-slim .layout-topbar .layout-menu-wrapper .menu-logo .logo {
    display: inline;
  }
  .layout-wrapper.layout-slim .layout-topbar .layout-menu-wrapper .menu-logo .app-name {
    display: inline;
  }
}
@media (min-width: 992px) {
  .layout-wrapper.layout-static .menu-button {
    width: 32px;
    height: 32px;
    line-height: 35px;
    margin-left: 30px;
    text-align: center;
    border-radius: 6px;
    background-color: rgba(68, 72, 109, 0.07);
    color: #676B89;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .layout-wrapper.layout-static .menu-button:hover {
    color: #44486D;
  }
  .layout-wrapper.layout-static .menu-button i {
    font-size: 1.25rem;
  }
  .layout-wrapper.layout-static .layout-menu-wrapper {
    position: fixed;
    transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container ul .layout-root-menuitem > a {
    display: none;
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container ul .layout-root-menuitem:not(.active-menuitem) ul {
    transition: none;
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu > li {
    margin-bottom: 2.25rem;
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu li a {
    border-left: 8px solid transparent;
  }
  .layout-wrapper.layout-static .layout-main {
    margin-left: 21rem;
  }
  .layout-wrapper.layout-static .layout-main .layout-topbar {
    display: none;
  }
  .layout-wrapper.layout-static .layout-main .layout-main-content {
    padding-top: 20px;
  }
  .layout-wrapper.layout-static.layout-static-inactive .layout-menu-wrapper {
    transform: translateX(-100%);
  }
  .layout-wrapper.layout-static.layout-static-inactive .layout-main {
    margin-left: 0;
  }
}
@media screen and (max-width: 991px) {
  .layout-wrapper.layout-static .menu-button {
    display: none;
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul {
    max-height: 0;
    transition: max-height 400ms cubic-bezier(0.86, 0, 0.07, 1);
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > ul {
    max-height: 500px;
  }
}
@media (min-width: 992px) {
  .layout-wrapper.layout-overlay .layout-menu-wrapper {
    position: fixed;
    mix-blend-mode: normal;
    transform: translate3d(-21rem, 0px, 0px);
    transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu {
    padding: 0 1.5rem;
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu li.layout-root-menuitem > div {
    display: none;
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu li ul li {
    padding-top: 6px;
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu > li {
    padding: 6px;
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu > li > a {
    padding: 0.75rem 1rem;
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu .layout-menu-enter-active {
    transition: max-height 0.45s ease-in-out;
  }
  .layout-wrapper.layout-overlay.layout-overlay .layout-topbar .layout-topbar-left {
    display: flex;
  }
  .layout-wrapper.layout-overlay.layout-overlay .layout-topbar .layout-topbar-left .logo {
    display: inline;
  }
  .layout-wrapper.layout-overlay.layout-overlay .layout-topbar .layout-topbar-left .app-name {
    display: inline;
  }
  .layout-wrapper.layout-overlay.layout-overlay .layout-topbar .layout-topbar-left .topbar-menu-button {
    display: inline;
  }
  .layout-wrapper.layout-overlay.layout-overlay .layout-topbar .layout-topbar-right {
    display: inline;
  }
  .layout-wrapper.layout-overlay.layout-overlay-active .layout-menu-wrapper {
    transform: translate3d(0px, 0px, 0px);
  }
  .layout-wrapper.layout-overlay.layout-overlay-active .layout-menu-wrapper .menu-pin {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 992px) {
  .layout-wrapper.layout-slim .layout-menu-wrapper {
    height: 100%;
    position: fixed;
    width: 5.857rem;
    padding: 0;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .menu-logo .app-name {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container {
    overflow: visible;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu {
    overflow: visible;
    padding-right: 0;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li {
    position: relative;
    padding: 5px 0 5px 12px;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li .layout-menuitem-root-text {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > a {
    margin: 0px;
    padding: 8px;
    width: 40px;
    height: 40px;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    position: relative;
    border: none;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > a:before {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > a span {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > a i {
    margin-right: 0;
    font-size: 1.5rem;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > a i.layout-submenu-toggler {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > a .menuitem-badge {
    display: none;
    margin-left: auto;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > a:hover + .layout-menu-tooltip {
    display: block;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > ul {
    position: absolute;
    top: 0;
    left: 60px;
    min-width: 250px;
    max-height: 450px;
    padding: 4px;
    overflow: auto;
    margin-top: 5px;
    border-radius: 8px;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > ul li {
    margin: 0;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > ul li > a {
    padding: 6px 8px;
    min-height: 32px;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > ul li > a .layout-menuitem-text {
    margin-left: 0.5rem;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > ul li > a > span {
    visibility: visible;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > ul li > a > i.layout-submenu-toggler {
    visibility: visible;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > ul li ul li a {
    padding-left: 16px;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > ul li ul li ul li a {
    padding-left: 24px;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > ul li ul li ul li ul li a {
    padding-left: 32px;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > ul li ul li ul li ul li ul li a {
    padding-left: 40px;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > ul li ul li ul li ul li ul li ul li a {
    padding-left: 48px;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 56px;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li > ul li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 64px;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li.layout-root-menuitem > ul.layout-menu-enter-from {
    opacity: 0;
    transform: none;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li.layout-root-menuitem > ul.layout-menu-leave-to {
    opacity: 0;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li.layout-root-menuitem > ul.layout-menu-leave-active, .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu > li.layout-root-menuitem > ul.layout-menu-enter-active {
    transition: none;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action .layout-inline-menu-text,
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action .layout-inline-menu-icon {
    display: none !important;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action-panel {
    padding: 0 0.5rem;
    padding-left: 1.5rem;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item {
    padding: 5px 12px 5px 12px;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item a, .layout-wrapper.layout-slim .layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item button {
    width: 100%;
    border-left: 0 none;
    padding: 8px;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item a span, .layout-wrapper.layout-slim .layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item button span {
    display: none !important;
  }
  .layout-wrapper.layout-slim .layout-main {
    margin-left: 5.857rem;
  }
  .layout-wrapper.layout-slim .layout-main .layout-topbar {
    display: none;
  }
}
@media (min-width: 992px) {
  .layout-wrapper.layout-horizontal .layout-menu-wrapper {
    display: none;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-left {
    display: flex;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-left .menu-button-wrapper .menu-button {
    display: none;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-right {
    display: flex;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item a,
  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item button {
    display: block;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item > .search-input-wrapper {
    display: none;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item > ul {
    display: block;
    padding: 0;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper {
    position: static;
    display: block;
    z-index: auto;
    width: auto;
    height: auto;
    overflow: visible;
    -moz-transition: none;
    -o-transition: none;
    -webkit-transition: none;
    transition: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .menu-logo {
    display: none;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container {
    overflow: visible;
    padding-bottom: 0;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu {
    padding: 0 3rem;
    overflow: visible;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu li.active-menuitem > a i.layout-submenu-toggler,
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu li.active-menuitem button i.layout-submenu-toggler {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li {
    padding: 0;
    margin: 0 8px;
    display: inline-block;
    position: relative;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li:hover > ul {
    display: block;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li a,
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li button {
    margin: 0px;
    padding: 0.75rem;
    user-select: none;
    cursor: pointer;
    border-radius: 6px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li a > span,
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li button > span {
    margin: 0 8px;
    line-height: 14px;
    visibility: visible;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li a > i,
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li button > i {
    font-size: 14px;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li a > i.layout-submenu-toggler,
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li button > i.layout-submenu-toggler {
    -moz-transition: transform 0.2s;
    -o-transition: transform 0.2s;
    -webkit-transition: transform 0.2s;
    transition: transform 0.2s;
    margin-left: auto;
    font-size: 12px;
    visibility: visible;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li.layout-root-menuitem > div {
    display: none;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li.layout-root-menuitem > li.layout-root-menuitem > ul {
    transition: none;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li.layout-root-menuitem > ul.layout-menu-enter-from {
    opacity: 0;
    transform: none;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li.layout-root-menuitem > ul.layout-menu-leave-to {
    opacity: 0;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li.layout-root-menuitem > ul.layout-menu-leave-active, .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li.layout-root-menuitem > ul.layout-menu-enter-active {
    transition: none;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul {
    display: none;
    list-style-type: none;
    width: 230px;
    position: absolute;
    padding: 10px;
    margin: 0;
    z-index: 100;
    max-height: 460px;
    overflow: auto;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul > li {
    border: 0 none;
    margin: 0;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li a {
    padding-left: 16px;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li ul li a {
    padding-left: 24px;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li ul li ul li a {
    padding-left: 32px;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li ul li ul li ul li a {
    padding-left: 40px;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li ul li ul li ul li ul li a {
    padding-left: 48px;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li ul li ul li ul li ul li ul li a {
    padding-left: 56px;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 64px;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul > li:hover ul {
    display: block;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul {
    list-style-type: none;
    padding: 0;
    display: none;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .logo {
    display: block;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .app-name {
    display: block;
  }
}
@media (min-width: 992px) {
  .layout-wrapper.layout-sidebar.layout-sidebar-static .layout-main {
    margin-left: 21rem;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 6.571rem;
    mix-blend-mode: normal;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .menu-logo {
    padding-left: 25px;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .menu-logo .app-name {
    display: none;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active {
    transform: translate3d(0px, 0px, 0px);
    width: 21rem;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .menu-logo .app-name {
    display: inline;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .menu-pin {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-menu-container {
    overflow: auto;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu {
    padding-right: 22px;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu li > a > span {
    visibility: visible;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu li > a > i.layout-submenu-toggler {
    visibility: visible;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu li > ul > li a {
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    padding-left: 16px;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu li ul li ul li a {
    padding-left: 24px;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu li ul li ul li ul li a {
    padding-left: 32px;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu li ul li ul li ul li ul li a {
    padding-left: 40px;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu li ul li ul li ul li ul li ul li a {
    padding-left: 48px;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu li ul li ul li ul li ul li ul li ul li a {
    padding-left: 56px;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 64px;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-inline-menu .layout-inline-menu-action .layout-inline-menu-text, .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-inline-menu .layout-inline-menu-action .layout-inline-menu-icon {
    visibility: visible;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-inline-menu .layout-inline-menu-action .layout-inline-menu-icon {
    transition: all 0.2s;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item a span, .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item button span {
    visibility: visible;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container {
    height: 100%;
    overflow: hidden;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu {
    list-style-type: none;
    margin: 0;
    padding: 0 26px 0 22px;
    overflow: hidden;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu > li .layout-menuitem-root-text {
    display: none;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul > li {
    margin-left: 6px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li {
    padding: 6px;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li.active-menuitem > a i.layout-submenu-toggler {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > a {
    margin: 0px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    padding: 8px;
    user-select: none;
    cursor: pointer;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > a > span {
    margin: 0 0.5rem;
    margin-left: 1rem;
    visibility: hidden;
    white-space: nowrap;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > a > i {
    font-size: 1.25rem;
    width: auto;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > a > i.layout-submenu-toggler {
    -moz-transition: transform 0.2s;
    -o-transition: transform 0.2s;
    -webkit-transition: transform 0.2s;
    transition: transform 0.2s;
    margin-left: auto;
    font-size: 12px;
    visibility: hidden;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > a.rotated-icon .layout-menuitem-icon {
    transform: rotate(90deg);
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > ul {
    list-style-type: none;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > ul li {
    padding: 6px 0 0 0;
    margin: 0;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > ul li a {
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > ul li ul li {
    padding: 0;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > ul li.layout-root-menuitem > a {
    display: -ms-flexbox;
    display: flex;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action .layout-inline-menu-text, .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action .layout-inline-menu-icon {
    white-space: nowrap;
    visibility: hidden;
  }
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item a span, .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item button span {
    white-space: nowrap;
    visibility: hidden;
  }
  .layout-wrapper.layout-sidebar .layout-main {
    margin-left: 92px;
  }
  .layout-wrapper.layout-sidebar .layout-main .layout-topbar {
    display: none;
  }
}
.layout-menu-wrapper .menu-logo .menu-pin {
  background: rgba(68, 72, 109, 0.07);
  color: #676B89;
}
.layout-menu-wrapper .menu-logo .menu-pin:hover {
  background: rgba(68, 72, 109, 0.12);
  color: #44486D;
}
.layout-menu-wrapper .layout-inline-menu.layout-inline-menu-active .layout-inline-menu-action img {
  border-color: #FCB71E;
  filter: drop-shadow(0px 0px 10px rgba(252, 183, 30, 0.3));
}
.layout-menu-wrapper .layout-inline-menu.layout-inline-menu-active .layout-inline-menu-action:hover img {
  border-color: #FCB71E;
  filter: drop-shadow(0px 0px 10px rgba(252, 183, 30, 0.3));
}
.layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action:hover img {
  border-color: #8A8EA6;
  filter: drop-shadow(0px 0px 10px rgba(15, 139, 253, 0.3));
}
.layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item a, .layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item button {
  color: #44486D;
}
.layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item a:hover, .layout-menu-wrapper .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item button:hover {
  background-color: rgba(68, 72, 109, 0.07);
}

@media (min-width: 992px) {
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu > li > a {
    color: #676B89;
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu > li > a:hover {
    color: #FCB71E;
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a {
    color: #FCB71E;
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a::before {
    background-color: #FCB71E;
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem {
    background-color: rgba(68, 72, 109, 0.07);
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem > a {
    background-color: rgba(68, 72, 109, 0.07);
    color: #44486D;
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li a {
    color: #44486D;
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li a:hover {
    background-color: rgba(68, 72, 109, 0.07);
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #44486D;
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu > li.layout-root-menuitem > div {
    color: #676B89;
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu > li ul li.active-menuitem > a {
    border-left-color: #FCB71E;
  }
  .layout-wrapper.layout-static .layout-menu-wrapper .layout-menu-container .layout-menu a.active-route {
    font-weight: bold;
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper, .layout-wrapper.layout-sidebar .layout-menu-wrapper {
    background: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%);
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu li a, .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li a {
    color: #676B89;
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu li a:hover, .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li a:hover {
    background-color: rgba(68, 72, 109, 0.07);
    color: #44486D;
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu li.active-menuitem, .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li.active-menuitem {
    background: rgba(68, 72, 109, 0.07);
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu li.active-menuitem > a:hover, .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li.active-menuitem > a:hover {
    background-color: rgba(68, 72, 109, 0.07);
    color: #44486D;
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu li.active-menuitem ul li.active-menuitem, .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li.active-menuitem ul li.active-menuitem {
    background: unset;
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu li > ul li > a, .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > ul li > a {
    color: #44486D;
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu li > ul li > a:hover, .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > ul li > a:hover {
    background-color: rgba(68, 72, 109, 0.07);
    color: #44486D;
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu li > ul li > a.active-route, .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > ul li > a.active-route {
    background-color: #FCB71E;
    color: #ffffff;
  }
  .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu li > ul li > a.active-route:hover, .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > ul li > a.active-route:hover {
    background-color: #FCB71E;
    color: #ffffff;
  }
  .layout-wrapper.layout-horizontal .layout-menu-wrapper {
    background-color: transparent;
  }
  .layout-wrapper.layout-horizontal .layout-menu-wrapper .layout-menu-container .layout-menu > li > a {
    color: #676B89;
  }
  .layout-wrapper.layout-horizontal .layout-menu-wrapper .layout-menu-container .layout-menu > li > a:hover {
    background-color: rgba(68, 72, 109, 0.07);
    color: #44486D;
  }
  .layout-wrapper.layout-horizontal .layout-menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    color: #FCB71E;
  }
  .layout-wrapper.layout-horizontal .layout-menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a i {
    color: #FCB71E;
  }
  .layout-wrapper.layout-horizontal .layout-menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a:hover {
    color: #FCB71E;
  }
  .layout-wrapper.layout-horizontal .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul {
    background: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%);
  }
  .layout-wrapper.layout-horizontal .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li > a {
    color: #44486D;
  }
  .layout-wrapper.layout-horizontal .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li > a:hover {
    background-color: rgba(68, 72, 109, 0.07);
    color: #44486D;
  }
  .layout-wrapper.layout-horizontal .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #FCB71E;
  }
  .layout-wrapper.layout-horizontal .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route {
    color: #FCB71E;
  }
  .layout-wrapper.layout-horizontal .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route:hover {
    color: #FCB71E;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper {
    background-color: transparent;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a {
    background-color: #FCB71E;
    color: #ffffff;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a:hover {
    background-color: #FCB71E;
    color: #ffffff;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > a {
    color: #44486D;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > a:hover {
    background-color: rgba(68, 72, 109, 0.07);
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > a.active-route {
    background-color: #FCB71E;
    color: #ffffff;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > a.active-route:hover {
    background-color: #FCB71E;
    color: #ffffff;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul {
    background: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%);
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li > a {
    color: #44486D;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li > a:hover {
    background-color: rgba(68, 72, 109, 0.07);
    color: #44486D;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #FCB71E;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li a {
    color: #44486D;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li a:hover {
    background-color: rgba(68, 72, 109, 0.07);
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #44486D;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route {
    background-color: #FCB71E;
    color: #ffffff;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route i {
    color: #ffffff;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route:hover {
    color: #ffffff;
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem {
    background-color: rgba(68, 72, 109, 0.07);
  }
  .layout-wrapper.layout-slim .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem > a {
    background-color: rgba(68, 72, 109, 0.07);
    color: #44486D;
  }
}
@media (max-width: 991px) {
  .layout-menu-wrapper {
    background: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%);
  }
  .layout-menu-wrapper .layout-menu-container .layout-menu > li > a {
    color: #676B89;
  }
  .layout-menu-wrapper .layout-menu-container .layout-menu > li > a:hover {
    background-color: rgba(68, 72, 109, 0.07);
    color: #44486D;
  }
  .layout-menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem {
    background: rgba(68, 72, 109, 0.07);
  }
  .layout-menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a {
    color: #676B89;
  }
  .layout-menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a i {
    color: #676B89;
  }
  .layout-menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a:hover {
    background-color: rgba(68, 72, 109, 0.07);
    color: #44486D;
  }
  .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li > a {
    color: #44486D;
  }
  .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li > a:hover {
    background-color: rgba(68, 72, 109, 0.07);
    color: #44486D;
  }
  .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route {
    background-color: #FCB71E;
    color: #ffffff;
  }
  .layout-menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route:hover {
    color: #ffffff;
  }
}
.layout-inline-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 0 none;
  padding-bottom: 1.5rem;
}
.layout-inline-menu.layout-inline-menu-active .layout-inline-menu-icon {
  transform: rotate(-180deg);
}
.layout-inline-menu .layout-inline-menu-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 2rem 2rem 1rem 2rem;
  color: #44486D;
}
.layout-inline-menu .layout-inline-menu-action img {
  border-radius: 22px;
  border: 2px solid transparent;
}
.layout-inline-menu .layout-inline-menu-action .layout-inline-menu-text {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  margin-left: 7px;
}
.layout-inline-menu .layout-inline-menu-action .layout-inline-menu-icon {
  margin-left: auto;
}
.layout-inline-menu .layout-inline-menu-action-panel {
  padding: 0 1.5rem;
}
.layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item {
  transition: all 0.2s;
  cursor: pointer;
  user-select: none;
  margin-top: 0.2rem;
}
.layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item:last-child {
  margin-bottom: 0.5rem;
}
.layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item a, .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  border-left: 8px solid transparent;
  border-radius: 8px;
  color: #44486D;
}
.layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item a i, .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item button i {
  margin-right: 0.75rem;
}
.layout-inline-menu .layout-inline-menu-action-panel.layout-inline-menu-action-panel-enter-from, .layout-inline-menu .layout-inline-menu-action-panel.layout-inline-menu-action-panel-leave-to {
  max-height: 0;
}
.layout-inline-menu .layout-inline-menu-action-panel.layout-inline-menu-action-panel-enter-to, .layout-inline-menu .layout-inline-menu-action-panel.layout-inline-menu-action-panel-leave-from {
  max-height: 1000px;
}
.layout-inline-menu .layout-inline-menu-action-panel.layout-inline-menu-action-panel-leave-active {
  overflow: hidden;
  transition: max-height 400ms cubic-bezier(0.86, 0, 0.07, 1);
}
.layout-inline-menu .layout-inline-menu-action-panel.layout-inline-menu-action-panel-enter-active {
  overflow: hidden;
  transition: max-height 1s ease-in-out;
}

.layout-config {
  width: 16em;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  padding: 1rem;
  overflow: auto;
  background: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%);
  color: #44486D !important;
  z-index: 999;
  border-left: 0 none;
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}
.layout-config.layout-config-active {
  transform: translateX(0);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.layout-config.layout-config-active .layout-config-content .layout-config-button i {
  transform: rotate(360deg);
}
.layout-config p {
  line-height: 1.5rem;
  color: #000000 !important;
}
.layout-config .layout-themes {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.layout-config .layout-themes > div {
  padding: 0.25rem;
}
.layout-config .layout-themes a, .layout-config .layout-themes button {
  width: 2rem;
  height: 2rem;
  display: block;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: transform 0.2s;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.layout-config .layout-themes a i, .layout-config .layout-themes button i {
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
  color: #ffffff;
}
.layout-config .layout-themes a:hover, .layout-config .layout-themes button:hover {
  transform: scale(1.1);
}

.layout-config-button {
  display: block;
  position: fixed;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  background: #FCB71E !important;
  color: #ffffff;
  text-align: center !important;
  top: 50%;
  right: 0;
  border-radius: 0;
  margin-top: -1.5rem !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  transition: background-color 0.2s;
  overflow: hidden;
  cursor: pointer;
  z-index: 999;
  box-shadow: -0.25rem 0 1rem rgba(0, 0, 0, 0.15);
}
.layout-config-button i {
  font-size: 2rem;
  line-height: inherit;
  transform: rotate(0deg);
  transition: transform 1s;
}
.layout-config-button:hover {
  background: #fcbf37 !important;
}

.layout-footer {
  height: 4rem;
  padding: 0 2rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.layout-footer .footer-logo-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.layout-footer .footer-logo-container img {
  width: 2rem;
  height: 2rem;
}
.layout-footer .footer-logo-container .app-name {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin-left: 0.5rem;
}
.layout-footer .copyright {
  font-size: 0.875rem;
  color: #676B89;
}

.layout-dashboard .widget-table .card-header {
  line-height: 24px;
  margin-bottom: 1rem;
}
.layout-dashboard .widget-table .p-datatable .p-button .p-button-icon {
  color: #44486D;
}

.layout-rightmenu-button-desktop {
  display: inline;
}

.layout-rightmenu-button-mobile {
  display: none;
}

.layout-rightmenu-button {
  background: linear-gradient(271.89deg, rgb(70, 82, 104), rgb(115, 130, 161));
  border-radius: 6px 0px 0px 6px;
  border-color: transparent;
  border-left: 0 none;
}
.layout-rightmenu-button:enabled:hover {
  background: linear-gradient(271.89deg, rgba(70, 82, 104, 0.5), rgba(115, 130, 161, 0.5));
  border-color: transparent;
}

.layout-rightmenu {
  height: 100%;
  width: 24.571rem;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  padding: 32px 16px;
  border-radius: 40px 0px 0px 40px;
  background: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%);
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}
.layout-rightmenu.layout-rightmenu-active {
  transform: translateX(0);
}
.layout-rightmenu .layout-rightmenu-close {
  width: 32px;
  height: 32px;
  background-color: rgba(68, 72, 109, 0.07);
  color: #44486D;
  position: absolute;
  right: 18px;
  text-align: center;
  padding: 9px;
  border-radius: 6px;
}
.layout-rightmenu .user-detail-wrapper {
  text-align: center;
  padding: 4.5rem 0 2rem 0;
}
.layout-rightmenu .user-detail-wrapper .user-name {
  font-size: 20px;
  color: #44486D;
  text-align: center;
  display: block;
  margin-top: 1.25rem;
  margin-bottom: 0.25rem;
}
.layout-rightmenu .user-tasks {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  border-bottom: 1px solid rgba(68, 72, 109, 0.2);
}
.layout-rightmenu .user-tasks .user-tasks-item {
  font-weight: 500;
}
.layout-rightmenu .user-tasks .user-tasks-item.in-progress .task-number {
  color: #FC6161;
}
.layout-rightmenu .user-tasks .user-tasks-item .task-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(68, 72, 109, 0.07);
  color: #44486D;
  text-align: center;
  padding: 9px;
  border-radius: 6px;
  width: 50px;
  height: 50px;
  font-size: 30px;
}
.layout-rightmenu .user-tasks .user-tasks-item .task-name {
  display: block;
  color: #44486D;
  margin-top: 0.75rem;
}
.layout-rightmenu .p-datepicker {
  padding-left: 0;
  padding-right: 0;
}
.layout-rightmenu .daily-plan-wrapper {
  margin-top: 2rem;
  color: #44486D;
}
.layout-rightmenu .daily-plan-wrapper ul {
  margin: 0;
  padding: 0;
  outline: 0;
  overflow: hidden;
  list-style-type: none;
}
.layout-rightmenu .daily-plan-wrapper ul li {
  margin-top: 8px;
  border-radius: 6px;
  background: rgba(68, 72, 109, 0.07);
  padding: 8px 12px;
}
.layout-rightmenu .daily-plan-wrapper ul li span {
  display: block;
  line-height: 20px;
  color: #44486D;
}
.layout-rightmenu .daily-plan-wrapper ul li span.event-time {
  font-weight: 600;
  color: #8A8EA6;
}

@media (max-width: 991px) {
  .layout-rightmenu-button-desktop {
    display: none;
  }
  .layout-rightmenu-button-mobile {
    display: inline;
  }
}
.widget-overview-box {
  height: 6rem;
  border-radius: 12px;
  padding: 14px 12px;
  position: relative;
}
.widget-overview-box img {
  position: absolute;
  bottom: 14px;
  right: 12px;
}
.widget-overview-box .overview-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
.widget-overview-box .overview-badge {
  height: 32px;
  width: 70px;
  border-radius: 6px;
  padding: 0.5em;
  margin-top: 0.5em;
  margin-right: 12px;
}
.widget-overview-box .overview-badge i {
  width: 24px;
}
.widget-overview-box .overview-badge span {
  font-size: 14px;
  line-height: 18px;
}
.widget-overview-box .overview-text {
  font-size: 30px;
  line-height: 35px;
}
.widget-overview-box.widget-overview-box-1 .overview-badge {
  background-color: #FC6161;
  box-shadow: 0px 6px 20px rgba(252, 97, 97, 0.3);
}
.widget-overview-box.widget-overview-box-2 .overview-badge {
  margin-right: 12px;
  background-color: #0BD18A;
  box-shadow: 0px 6px 20px rgba(11, 209, 138, 0.3);
}
.widget-overview-box.widget-overview-box-3 .overview-badge {
  background-color: #00D0DE;
  box-shadow: 0px 6px 20px rgba(0, 208, 222, 0.3);
}

.widget-visitor-graph .card-header {
  line-height: 24px;
}
.widget-visitor-graph .card-header .actions span {
  display: inline-flex;
  align-items: center;
}
.widget-visitor-graph .card-header .actions span:first-child {
  margin-right: 25px;
}
.widget-visitor-graph .card-header .actions span i {
  margin-left: 0.5rem;
}
.widget-visitor-graph .graph-content {
  margin-top: 1.5rem;
}
.widget-visitor-graph .graph-content h6 {
  margin-top: 1rem;
}
.widget-visitor-graph .graph-content p {
  color: #8A8EA6;
}
.widget-visitor-graph .graph h6 {
  margin-top: 1.5rem;
}

.widget-timeline.card {
  padding: 0;
}
.widget-timeline .timeline-header {
  padding: 16px;
}
.widget-timeline .timeline-header p {
  margin: 0;
}
.widget-timeline .timeline-header .header-icons i {
  margin-left: 0.5rem;
}
.widget-timeline .p-timeline {
  padding: 0 16px;
}
.widget-timeline .p-timeline.p-timeline-vertical .p-timeline-event-opposite {
  flex: 0;
  padding: 0;
}
.widget-timeline .p-timeline .p-timeline-event-content p, .widget-timeline .p-timeline .p-timeline-event-content h6 {
  margin: 0;
}
.widget-timeline .p-timeline .p-timeline-event-content span {
  color: #8A8EA6;
  font-size: 11px;
}
.widget-timeline .p-timeline .p-timeline-event-content:last-child {
  margin-bottom: 10px;
}
.widget-timeline .p-timeline .custom-marker {
  border-radius: 50%;
  padding: 0.25rem;
  width: 24px;
  height: 24px;
  text-align: center;
}
.widget-timeline .timeline-footer {
  border-top: 1px solid rgba(68, 72, 109, 0.2);
  padding: 16px;
}
.widget-timeline .timeline-footer a {
  color: #44486D;
}

.widget-country-graph .country-title {
  font-weight: 500;
  line-height: 24px;
}
.widget-country-graph .country-content ul {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  list-style-type: none;
}
.widget-country-graph .country-content ul li {
  padding: 8px 0;
  border-bottom: 1px solid rgba(68, 72, 109, 0.2);
}
.widget-country-graph .country-content ul li .color {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 0.5rem;
}

.widget-revenue-graph .card-header {
  line-height: 24px;
}
.widget-revenue-graph .card-header .actions span {
  display: inline-flex;
  align-items: center;
}
.widget-revenue-graph .card-header .actions span i {
  margin-left: 0.5rem;
}

.widget-performance .header {
  line-height: 24px;
  font-weight: 500;
}
.widget-performance .header .subtitle {
  font-size: 12px;
  color: #8A8EA6;
}
.widget-performance .content ul {
  padding: 0;
  outline: 0;
  list-style-type: none;
}
.widget-performance .content ul li {
  padding: 1rem 0;
  display: flex;
  align-items: center;
}
.widget-performance .content ul li .p-avatar {
  width: 3.571rem;
  height: 3.571rem;
}
.widget-performance .content ul li .p-avatar .p-badge {
  top: 4px;
  right: 4px;
}
.widget-performance .content ul li .person-info {
  margin-left: 0.5rem;
}
.widget-performance .content ul li .person-info .amount {
  font-size: 18px;
  color: #44486D;
}
.widget-performance .content ul li .person-info .name {
  font-size: 12px;
  color: #8A8EA6;
}

.widget-customer-graph .header .title {
  display: flex;
  justify-content: space-between;
  line-height: 24px;
}
.widget-customer-graph .header .title .actions i {
  margin-left: 0.5rem;
}
.widget-customer-graph .header .subtitle {
  font-size: 12px;
  color: #8A8EA6;
}
.widget-customer-graph .content {
  margin-top: 1rem;
}
.widget-customer-graph .content h2 {
  margin-bottom: 0;
}
.widget-customer-graph .content p {
  margin-left: 0.5rem;
  color: #8A8EA6;
  font-size: 12px;
  line-height: 1rem;
}

.widget-target .content h3 {
  margin-top: 1rem;
  margin-bottom: 0;
}
.widget-target .content .rate {
  font-size: 13px;
  line-height: 1rem;
  color: #0BD18A;
}
.widget-target .content .rate i {
  font-size: 12px;
}
.widget-target .content .rate span {
  color: #676B89;
}
.widget-target .values {
  margin-top: 1.5rem;
  min-height: 235px;
  position: relative;
  color: #FC6161;
  display: flex;
  justify-content: space-between;
}
.widget-target .values .item {
  min-height: inherit;
  width: 40px;
}
.widget-target .values .item span {
  position: absolute;
}
.widget-target .values .item span.day {
  bottom: 0;
  color: #676B89;
}
.widget-target .values .item.today span.day {
  color: #44486D;
}
.widget-target .values .item.success span:first-child {
  color: #0BD18A;
}
.widget-target .values .item.success .p-progressbar {
  box-shadow: 0px 0px 10px rgba(11, 209, 138, 0.3);
}
.widget-target .values .item.success .p-progressbar .p-progressbar-value {
  background-color: #0BD18A;
}
.widget-target .values .item .p-progressbar {
  transform: rotate(-90deg);
  top: 115px;
  left: -70px;
  width: 170px;
  background-color: rgba(68, 72, 109, 0.12);
}
.widget-target .values .item .p-progressbar .p-progressbar-value {
  background-color: #FC6161;
  box-shadow: 0px 0px 10px rgba(252, 97, 97, 0.3);
}

.widget-customer-carousel .p-carousel-indicators {
  display: none;
}
.widget-customer-carousel .customer-item-content {
  text-align: center;
}
.widget-customer-carousel .customer-item-content h5 {
  color: #8A8EA6;
}

.p-button-outlined.invoice-button {
  color: inherit;
}

.invoice {
  padding: 2.429rem;
}
.invoice .invoice-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.invoice .invoice-header .invoice-company .invoice-logo {
  height: 2.25rem;
}
.invoice .invoice-header .invoice-company .invoice-appname {
  height: 1.25rem;
  margin-left: 0.75rem;
}
.invoice .invoice-header .customer-id {
  line-height: 16px;
  text-align: right;
  color: #676B89;
}
.invoice .invoice-header .invoice-address {
  font-size: 0.786rem;
  line-height: 16px;
  color: #8A8EA6;
}
.invoice .invoice-to {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  line-height: 1.429rem;
  border-top: 1px solid rgba(68, 72, 109, 0.2);
}
.invoice .invoice-to .invoice-date {
  font-size: 0.786rem;
  font-weight: 500;
  color: #8A8EA6;
}
.invoice .invoice-to .invoice-id {
  font-size: 1.714rem;
  font-weight: 500;
}
.invoice .invoice-to .invoice-id span {
  margin-left: 0.5rem;
  font-size: 1.429rem;
  font-weight: 300;
  color: #8A8EA6;
}
.invoice .invoice-to .invoice-to-name {
  margin-top: 2rem;
  font-size: 1.143rem;
  font-weight: 600;
}
.invoice .invoice-to .invoice-to-info {
  margin-top: 0.25rem;
  color: #8A8EA6;
  font-size: 0.857rem;
}
.invoice .invoice-items {
  margin-top: 2rem;
}
.invoice .invoice-items table {
  width: 100%;
  border-collapse: collapse;
}
.invoice .invoice-items table thead tr {
  border-bottom: 1px solid rgba(68, 72, 109, 0.2);
}
.invoice .invoice-items table tbody tr:last-child {
  border-bottom: 1px solid rgba(68, 72, 109, 0.2);
}
.invoice .invoice-items table th {
  font-size: 0.714rem;
  color: #8A8EA6;
}
.invoice .invoice-items table th, .invoice .invoice-items table td {
  padding: 0.5rem 1rem;
  text-align: right;
}
.invoice .invoice-items table tr:first-child td {
  padding-top: 1rem;
}
.invoice .invoice-items table tr:last-child td {
  padding-bottom: 1rem;
}
.invoice .invoice-items table th:first-child, .invoice .invoice-items table td:first-child {
  text-align: left;
}
.invoice .invoice-summary {
  margin-top: 1rem;
}
.invoice .invoice-summary table {
  width: 100%;
  border-collapse: collapse;
}
.invoice .invoice-summary table tbody tr:last-child {
  font-weight: 600;
  border-bottom: 1px solid #FCB71E;
}
.invoice .invoice-summary table tbody tr:last-child td {
  padding-bottom: 1rem;
}
.invoice .invoice-summary table tbody tr:first-child td:first-child, .invoice .invoice-summary table tbody tr:nth-child(2) td:first-child {
  color: #8A8EA6;
}
.invoice .invoice-summary table tbody tr td:last-child {
  text-align: right;
}
.invoice .invoice-summary table tbody tr td {
  padding: 0.5rem 1rem;
}
.invoice .invoice-notes {
  margin-top: 2rem;
  padding-left: 0.5rem;
}

@media print {
  body * {
    visibility: hidden;
  }
  #invoice-content * {
    visibility: visible;
  }
  #invoice-content {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
  }
}
.help-page p {
  margin: 0;
}
.help-page .help-search {
  background: linear-gradient(179.2deg, rgba(15, 139, 253, 0.15) 0.69%, rgba(15, 139, 253, 0) 108.44%), rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(200px);
  padding: 0;
  margin-bottom: 4rem;
}
.help-page .help-search .help-search-content {
  padding: 50px 60px;
}
.help-page .help-search .help-search-effect {
  background: url("../../../layout/images/effect-onlight.png");
  background-repeat: no-repeat;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.help-page .help-search .search-container {
  font-size: 1.5rem;
  position: relative;
}
.help-page .help-search .search-container input {
  appearance: none;
  text-indent: 2.5rem;
  width: 100%;
}
.help-page .help-search .search-container input::placeholder {
  color: #8A8EA6;
}
.help-page .help-search .search-container i {
  color: #44486D;
  width: 2rem;
  position: absolute;
  margin-left: 1rem;
  top: 50%;
  margin-top: -0.5rem;
}
.help-page .status-card {
  margin-top: 30px;
}
.help-page .status-card h6 {
  margin-bottom: 0;
}
.help-page .status-card p {
  color: #8A8EA6;
}
.help-page .status-card .status-bars {
  margin-top: 1rem;
  display: -ms-flexbox;
  display: flex;
}
.help-page .status-card .status-bars .status-bar {
  flex: 1 1 0;
  -ms-flex: 1 1 0px;
  background: #0BD18A;
  box-shadow: 0px 0px 10px rgba(11, 209, 138, 0.3);
  height: 50px;
  margin-right: 0.25rem;
  transition: transform 0.2s;
}
.help-page .status-card .status-bars .status-bar:last-child {
  margin-right: 0;
}
.help-page .status-card .status-bars .status-bar.status-bar-failure {
  background: #FC6161;
  box-shadow: 0px 0px 10px rgba(252, 97, 97, 0.3);
}
.help-page .status-card .status-bars .status-bar:hover {
  transform: scale(1.1);
}
.help-page .status-card .status-bar-footer {
  padding: 1rem 0 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #8A8EA6;
}
.help-page .articles-card h6 {
  margin-bottom: 0;
}
.help-page .articles-card p {
  color: #8A8EA6;
}
.help-page .articles-card .blog-post {
  height: 130px;
  border-radius: 8px;
  margin: 1rem 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}
.help-page .articles-card .blog-post:last-child {
  margin-bottom: 1rem;
}
.help-page .articles-card .blog-post .blog-text {
  position: absolute;
  left: 74px;
  bottom: 12px;
  color: #FFFFFF;
}
.help-page .articles-card .blog-post .blog-text h6 {
  margin-bottom: 0;
  line-height: 20px;
}
.help-page .articles-card .blog-post .blog-text span {
  line-height: 16px;
  font-size: 12px;
}
.help-page .articles-card .blog-post .blog-profile {
  position: absolute;
  top: 68px;
  left: 12px;
}
.help-page .articles-card .blog-post .blog-profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.help-page .articles-card .blog-post:nth-child(1) {
  background-image: linear-gradient(180deg, rgba(28, 28, 44, 0) 33.85%, rgba(27, 27, 44, 0.8) 80.77%), url("../../layout/images/pages/help/blog1.jpg");
}
.help-page .articles-card .blog-post:nth-child(2) {
  background-image: linear-gradient(186.73deg, rgba(28, 28, 44, 0) 46.89%, rgba(27, 27, 44, 0.8) 95.1%), url("../../layout/images/pages/help/blog2.jpg");
}
.help-page .articles-card .blog-post:nth-child(3) {
  background-image: linear-gradient(180deg, rgba(28, 28, 44, 0) 33.85%, rgba(27, 27, 44, 0.8) 80.77%), url("../../layout/images/pages/help/blog3.jpg");
}

.login-body {
  background-color: #f5f5f5;
  display: flex;
  box-sizing: border-box;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.login-body .login-image {
  height: 100vh;
}
.login-body .login-image img {
  height: 100vh;
}
.login-body .login-panel {
  background-color: #fff;
  width: 498px;
  text-align: center;
  padding: 50px 49px 46px;
  box-sizing: border-box;
}
.login-body .login-panel .login-logo {
  width: 45px;
}
.login-body .login-panel .login-appname {
  font-weight: 700;
  font-size: 24px;
  margin-left: 1rem;
}
.login-body .login-panel .form-container input {
  display: block;
  margin-bottom: 10px;
}
.login-body .login-panel .form-container a,
.login-body .login-panel .form-container button {
  color: #8A8EA6;
  margin-bottom: 20px;
  font-size: 11px;
}
.login-body .login-panel .filed {
  text-align: left;
  padding-bottom: 18px;
}
.login-body .login-panel .filed .btn-eye-toggle {
  width: 10%;
  background-color: #f2f2f5;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.login-body .login-panel .button-container button {
  display: block;
  max-width: 100px;
  margin-bottom: 32px;
}
.login-body .login-panel .button-container > span {
  display: flex;
  font-size: 11px;
  color: #8A8EA6;
}
.login-body .login-panel .button-container > span button {
  font-size: 11px;
  cursor: pointer;
  margin-left: 0.25rem;
}
.login-body .login-panel .login-footer {
  position: absolute;
  bottom: 75px;
}
.login-body .login-panel .login-footer .login-footer-logo-container {
  padding-right: 1.5rem;
  border-right: 1px solid rgba(68, 72, 109, 0.2);
}
.login-body .login-panel .login-footer .login-footer-logo-container .login-footer-logo {
  width: 22px;
}
.login-body .login-panel .login-footer .login-footer-logo-container .login-footer-appname {
  width: 45px;
  margin-left: 0.5rem;
}
.login-body .login-panel .login-footer span {
  margin-left: 1rem;
  font-size: 11px;
  color: #8A8EA6;
}

@media (max-width: 991px) {
  .login-body .login-image {
    display: none;
  }
  .login-body .login-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 50px;
  }
}
.exception-body {
  background-image: url("../../../layout/images/pages/exception-onlight.png");
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.exception-body.notfound .exception-panel h1 {
  color: #0F8BFD;
  text-shadow: 0px 0px 50px rgba(15, 139, 253, 0.2);
}
.exception-body.notfound .exception-panel h3 {
  color: #013c72;
}
.exception-body.accessdenied .exception-panel h1 {
  color: #0F8BFD;
  text-shadow: 0px 0px 50px rgba(15, 139, 253, 0.2);
}
.exception-body.accessdenied .exception-panel h3 {
  color: #013c72;
}
.exception-body.error .exception-panel h1 {
  color: #FC6161;
  text-shadow: 0px 0px 50px rgba(252, 97, 97, 0.2);
}
.exception-body.error .exception-panel h3 {
  color: #fd9393;
  max-width: 720px;
}
.exception-body .exception-panel {
  text-align: center;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -200px;
}
.exception-body .exception-panel h1 {
  font-size: 140px;
  font-weight: 900;
  margin-bottom: 0;
}
.exception-body .exception-panel h3 {
  font-size: 80px;
  font-weight: 900;
  margin-top: -90px;
  margin-bottom: 50px;
}
.exception-body .exception-panel p {
  font-size: 24px;
  font-weight: 400;
  max-width: 320px;
}
.exception-body .exception-panel button {
  margin-top: 50px;
}
.exception-body .exception-footer {
  position: absolute;
  bottom: 60px;
  align-items: center;
  display: flex;
}
.exception-body .exception-footer .exception-logo {
  width: 34px;
}
.exception-body .exception-footer .exception-appname {
  width: 72px;
  margin-left: 1rem;
}

@media (max-width: 576px) {
  .exception-body {
    background-size: auto;
  }
  .exception-body.accessdenied .exception-panel h1, .exception-body.error .exception-panel h1 {
    font-size: 70px;
  }
  .exception-body.accessdenied .exception-panel h3, .exception-body.error .exception-panel h3 {
    font-size: 40px;
    margin-top: -30px;
  }
  .exception-body .exception-panel {
    margin-top: 0;
  }
  .exception-body .exception-panel h3 {
    line-height: 0.75;
  }
  .exception-body .exception-panel button {
    margin-top: 0;
  }
}
.landing-container {
  background: #100E23;
}
.landing-container .layout-topbar-logo .appname, .landing-container .layout-footer-logo .appname {
  margin-left: 0.5rem;
}
.landing-container #header {
  width: 100%;
  min-height: 1000px;
  background: url("../../layout/images/landing/landing-header-2x.jpg") top left no-repeat;
  background-size: cover;
  padding: 2.5rem 3rem;
}
.landing-container #header .header-menu-container .header-left-elements ul {
  padding-left: 6rem;
}
.landing-container #header .header-menu-container .header-right-elements .contact-button {
  background: rgba(68, 72, 109, 0.05);
  color: #44486D;
  font-weight: 500;
  border-radius: 8px;
}
.landing-container #header .header-menu-container .header-right-elements .contact-icons i {
  color: #44486D;
  opacity: 0.8;
  margin-right: 1rem;
  font-size: 18px;
}
.landing-container #header .header-menu-container .header-right-elements .contact-icons:hover i {
  color: #1d1f2e;
}
.landing-container #header .header-menu-container a {
  font-weight: 500;
  color: #44486D;
  cursor: pointer;
}
.landing-container #header .header-menu-container #menu {
  display: flex;
  align-items: center;
  flex-direction: row;
  list-style-type: none;
}
.landing-container #header .header-text {
  padding: 100px 60px;
}
.landing-container #header .header-text h1 {
  font-size: 80px;
  line-height: 95px;
  color: #44486D;
}
.landing-container #header .header-text h2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #44486D;
}
.landing-container #header .header-text .p-button {
  background: #04E3F2;
  border-color: #04E3F2;
  font-weight: bold;
  color: #000000;
  mix-blend-mode: multiply;
  border-radius: 8px;
  padding: 0.858rem 1.142rem;
}
.landing-container #header .header-features {
  padding: 100px 60px;
}
.landing-container #header .header-features .header-feature-box {
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  backdrop-filter: blur(40px);
  border-radius: 20px;
  padding: 30px 33px 36px 33px;
  max-width: 370px;
}
.landing-container #header .header-features .header-feature-box .title {
  display: block;
  font-size: 20px;
  line-height: 23px;
}
.landing-container #header .header-features .header-feature-box .content {
  font-size: 14px;
  line-height: 16px;
}
.landing-container #meet-atlantis {
  width: 100%;
  min-height: 620px;
  background: #100E23;
  position: relative;
  padding-top: 65px;
}
.landing-container #meet-atlantis .ellipsis-1 {
  position: absolute;
  right: 0;
  z-index: 11;
}
.landing-container #meet-atlantis .ellipsis-2 {
  position: absolute;
  left: 0;
  bottom: -100px;
  z-index: 11;
}
.landing-container #meet-atlantis .ellipsis-3 {
  filter: blur(20px);
  position: absolute;
  left: 130px;
  top: 40px;
}
.landing-container #meet-atlantis .ellipsis-4 {
  position: absolute;
  right: 310px;
  bottom: 0;
  z-index: 9;
}
.landing-container #meet-atlantis .atlantis-modes {
  z-index: 10;
}
.landing-container #meet-atlantis .atlantis-modes img {
  max-width: 800px;
  border-radius: 8px;
}
.landing-container .clip-background {
  background: #100E23;
  position: relative;
}
.landing-container .landing-wrapper-back {
  background: transparent;
  color: #F4F8FB;
  margin-top: calc(var(--clip-padding) * -1 - 300px);
  padding: calc(var(--clip-padding) * 4 - (var(--clip-padding) - var(--skew-padding))) 0 4em;
  padding-left: 3rem;
  padding-right: 3rem;
  position: absolute;
  width: 100%;
}
.landing-container .landing-wrapper-back #features-back {
  padding-left: 14rem;
  padding-right: 5rem;
}
.landing-container .landing-wrapper-back #features-back h2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
}
.landing-container .landing-wrapper-back #features-back .feature-boxes h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}
.landing-container .landing-wrapper-back #features-back .feature-boxes p {
  font-size: 18px;
  line-height: 26px;
  max-width: 420px;
}
.landing-container .landing-wrapper-back #features-back .feature-boxes.feature-boxes-1 {
  margin-bottom: 170px;
}
.landing-container .landing-wrapper-back #features-back .feature-boxes.feature-boxes-1 .widget-overview-box {
  background: #FFFFFF;
  box-shadow: 0px 8px 30px rgba(59, 56, 109, 0.07);
  border-radius: 12px;
  margin-bottom: 2rem;
  max-width: 350px;
}
.landing-container .landing-wrapper {
  background: linear-gradient(180deg, #F4F8FB 17.13%, #EEEEFA 65.89%);
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 5rem;
  position: absolute;
  color: #44486D;
  width: 100%;
}
.landing-container .landing-wrapper #features {
  padding-left: 14rem;
  padding-right: 5rem;
}
.landing-container .landing-wrapper #features h2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
}
.landing-container .landing-wrapper #features .feature-boxes h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}
.landing-container .landing-wrapper #features .feature-boxes p {
  font-size: 18px;
  line-height: 26px;
  max-width: 420px;
}
.landing-container .landing-wrapper #features .feature-boxes.feature-boxes-1 {
  margin-bottom: 170px;
}
.landing-container .landing-wrapper #features .feature-boxes.feature-boxes-1 .widget-overview-box {
  background: #FFFFFF;
  box-shadow: 0px 8px 30px rgba(59, 56, 109, 0.07);
  border-radius: 12px;
  margin-bottom: 2rem;
  max-width: 350px;
}
.landing-container .landing-wrapper #features .feature-boxes.feature-boxes-2 {
  margin-bottom: 140px;
}
.landing-container .landing-wrapper #features .feature-boxes.feature-boxes-2 .customer-box {
  background: #FFFFFF;
  box-shadow: 0px 8px 30px rgba(68, 72, 109, 0.07);
  border-radius: 8px;
  max-width: 180px;
  max-height: 210px;
  text-align: center;
}
.landing-container .landing-wrapper #features .feature-boxes.feature-boxes-2 .customer-box h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  white-space: nowrap;
}
.landing-container .landing-wrapper #features .feature-boxes.feature-boxes-2 .customer-box h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #878D9C;
}
.landing-container .landing-wrapper #features .feature-boxes.feature-boxes-2 .customer-box:nth-child(3) {
  opacity: 0.5;
}
.landing-container .landing-wrapper #features .feature-boxes.feature-boxes-2 .customer-box:last-child {
  opacity: 0.3;
}
.landing-container .landing-wrapper #features .feature-boxes.feature-boxes-2 > div:first-child {
  margin-left: -100px;
}
.landing-container .landing-wrapper #features .feature-boxes.feature-boxes-3 {
  margin-bottom: 120px;
}
.landing-container .landing-wrapper #features .feature-boxes.feature-boxes-3 ul {
  padding-left: 20px;
}
.landing-container .landing-wrapper #features .feature-boxes.feature-boxes-3 ul li {
  font-size: 18px;
  line-height: 26px;
}
.landing-container .landing-wrapper #features .feature-boxes.feature-boxes-3 .feature-widgets {
  position: relative;
}
.landing-container .landing-wrapper #features .feature-boxes.feature-boxes-3 .feature-widgets .progressbar-widget {
  max-width: 350px;
  position: absolute;
  right: 0;
  top: -45px;
}
.landing-container .landing-wrapper #features .feature-boxes.feature-boxes-3 .feature-widgets .chart-widget {
  max-height: 260px;
  opacity: 0.6;
}
.landing-container .landing-wrapper #pricing {
  padding-left: 14rem;
  padding-right: 5rem;
  margin-bottom: 8.5rem;
}
.landing-container .landing-wrapper #pricing h2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
}
.landing-container .landing-wrapper #pricing p {
  font-size: 18px;
  line-height: 26px;
  max-width: 650px;
  margin-bottom: 4.5rem;
}
.landing-container .landing-wrapper #pricing .pricing-content {
  width: 100%;
}
.landing-container .landing-wrapper #pricing .pricing-content .card {
  height: 100%;
  background: #FFFFFF;
  box-shadow: 0px 10px 50px rgba(29, 15, 57, 0.13);
  border-radius: 20px;
}
.landing-container .landing-wrapper #pricing .pricing-content .type {
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
}
.landing-container .landing-wrapper #pricing .pricing-content h1 {
  font-weight: 500;
  font-size: 50px;
  line-height: 60px;
}
.landing-container .landing-wrapper #pricing .pricing-content .options {
  padding: 0 2rem;
  margin-top: 0;
}
.landing-container .landing-wrapper #pricing .pricing-content .options li {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 26px;
  padding: 0.5rem;
  text-align: center;
}
.landing-container .landing-wrapper #pricing .pricing-content .buy-button {
  background: #EFEFFB;
  color: #44486D;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
}
.landing-container .landing-wrapper #pricing .pricing-content .active-buy-button {
  background: #00CAE6;
  color: #ffffff;
  border-radius: 8px;
  font-weight: 600;
  line-height: 24px;
}
.landing-container .landing-wrapper #footer {
  border-top: 1px solid rgba(68, 72, 109, 0.2);
}
.landing-container .landing-wrapper #footer .footer-header-container {
  padding-bottom: 1.5rem;
  border-bottom: 2px solid #EFEFFB;
  mix-blend-mode: multiply;
}
.landing-container .landing-wrapper #footer .footer-header-container .layout-footer-logo span {
  font-size: 14px;
  line-height: 26px;
  color: #44486D;
  opacity: 0.7;
  margin-left: 2.5rem;
}
.landing-container .landing-wrapper #footer .footer-header-container .footer-right-elements .contact-icons {
  cursor: pointer;
}
.landing-container .landing-wrapper #footer .footer-header-container .footer-right-elements .contact-icons i {
  color: #44486D;
  opacity: 0.8;
  margin-right: 1rem;
  font-size: 18px;
}
.landing-container .landing-wrapper #footer .footer-header-container .footer-right-elements .contact-icons:hover i {
  color: #1d1f2e;
}
.landing-container .landing-wrapper #footer .newsletter .header {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  opacity: 0.6;
}
.landing-container .landing-wrapper #footer .newsletter p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.6;
}
.landing-container .landing-wrapper #footer .newsletter input {
  background: #EFEFFB;
  mix-blend-mode: multiply;
  opacity: 0.8;
  color: #44486D;
  border-radius: 8px 0 0 8px;
  border-color: transparent;
  padding: 0.714rem 1rem;
  min-width: 400px;
}
.landing-container .landing-wrapper #footer .newsletter input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #44486D;
  mix-blend-mode: multiply;
  opacity: 0.25;
}
.landing-container .landing-wrapper #footer .newsletter .join-button {
  background: #7272B2;
  mix-blend-mode: multiply;
  border-radius: 0 8px 8px 0;
}

@media screen and (max-width: 991px) {
  .landing-container #header .header-features, .landing-container #header .header-text {
    padding: 100px 0;
  }
  .landing-container #header .header-menu-container .contact-button {
    margin-right: 1rem;
  }
  .landing-container #header .header-menu-container #menu {
    margin: 0;
    padding: 0;
    outline: 0;
    overflow: hidden;
    list-style-type: none;
    display: none;
    z-index: 100;
    position: fixed;
    top: 6rem;
    right: 5rem;
    width: 250px;
    background: linear-gradient(180deg, #F4F8FB 17.13%, #EEEEFA 65.89%);
    animation-duration: 0.2s;
  }
  .landing-container #header .header-menu-container #menu a {
    color: #44486D;
  }
  .landing-container #header .header-menu-container #menu > li {
    width: 100%;
  }
  .landing-container #header .header-menu-container #menu.menu-active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
  }
  .landing-container #meet-atlantis {
    min-height: 320px;
  }
  .landing-container #meet-atlantis .ellipsis-1, .landing-container #meet-atlantis .ellipsis-2,
  .landing-container #meet-atlantis .ellipsis-3, .landing-container #meet-atlantis .ellipsis-4 {
    display: none;
  }
  .landing-container #meet-atlantis .atlantis-modes img {
    max-height: 170px;
  }
  .landing-container .landing-wrapper-back {
    display: none;
  }
  .landing-container .landing-wrapper {
    padding: 2.5rem 3rem;
  }
  .landing-container .landing-wrapper.clip-path {
    clip-path: none;
    margin-top: 0;
  }
  .landing-container .landing-wrapper #features {
    padding-left: 0;
    padding-right: 0;
  }
  .landing-container .landing-wrapper #features .feature-boxes.feature-boxes-1, .landing-container .landing-wrapper #features .feature-boxes.feature-boxes-2, .landing-container .landing-wrapper #features .feature-boxes.feature-boxes-3 {
    margin-bottom: 50px;
  }
  .landing-container .landing-wrapper #features .feature-boxes.feature-boxes-3 .feature-widgets {
    overflow: hidden;
  }
  .landing-container .landing-wrapper #features .feature-boxes.feature-boxes-3 .feature-widgets .progressbar-widget {
    max-width: 350px;
    position: absolute;
    right: -150px;
    top: -15px;
  }
  .landing-container .landing-wrapper #pricing {
    padding-left: 0;
    padding-right: 0;
  }
  .landing-container .landing-wrapper #pricing .pricing-content .card {
    margin-right: 0 !important;
  }
  .landing-container .landing-wrapper #footer .newsletter p, .landing-container .landing-wrapper #footer .newsletter input {
    min-width: auto;
    max-width: 300px;
  }
}
:root {
  --width: 100vw;
  --full-width: 100vw;
  --angle: -15deg;
  /*-----------------
  Magic Number Alert:

  the 0.09719 is calculated by this formula:
  tan(11°) / 2
  11° is the current skewY value.
  ------------------*/
  --magic-number: 0.09719;
  --skew-padding: calc(var(--width) * var(--magic-number));
  --clip-padding: calc(var(--full-width) * var(--magic-number));
}
@media (min-width: 42em) {
  :root {
    --width: 42rem;
  }
}

.clip-path {
  position: relative;
  margin-top: calc(var(--clip-padding) * -1 - 300px);
  padding: calc(var(--clip-padding) * 4 - (var(--clip-padding) - var(--skew-padding))) 0 4em;
  clip-path: polygon(0% 18%, 100% 0%, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(0% 18%, 100% 0%, 100% 100%, 0% 100%);
}

/* Add your customizations of layout here */
:root {
  --surface-a:transparent;
  --surface-b:rgba(68, 72, 109, 0.07);
  --surface-c:rgba(68, 72, 109, 0.12);
  --surface-d:rgba(68, 72, 109, 0.17);
  --surface-e:rgba(255, 255, 255, 0.9);
  --surface-f:#8A8EA6;
  --text-color:#44486D;
  --text-color-secondary:#676B89;
  --primary-color:#3b5f78;
  --primary-color-text:#ffffff;
  --font-family:-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --surface-0: #ffffff;
  --surface-50: #e1e2e8;
  --surface-100: #cbccd6;
  --surface-200: #b4b6c5;
  --surface-300: #9ea0b3;
  --surface-400: #878aa2;
  --surface-500: #717490;
  --surface-600: #5a5e7f;
  --surface-700: #44486d;
  --surface-800: #3c3f60;
  --surface-900: #343753;
  --gray-50: #e1e2e8;
  --gray-100: #cbccd6;
  --gray-200: #b4b6c5;
  --gray-300: #9ea0b3;
  --gray-400: #878aa2;
  --gray-500: #717490;
  --gray-600: #5a5e7f;
  --gray-700: #44486d;
  --gray-800: #3c3f60;
  --gray-900: #343753;
  --content-padding:1.143rem;
  --inline-spacing:0.5rem;
  --border-radius:0px;
  --surface-ground:linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%);
  --surface-section:#ffffff;
  --surface-card:#ffffff;
  --surface-overlay:#ffffff;
  --surface-border:rgba(68, 72, 109, 0.2);
  --surface-hover:rgba(68, 72, 109, 0.07);
  --focus-ring: 0 none;
  --maskbg: rgba(0, 0, 0, 0.4);
}

/* Add your variable customizations of theme here */
* {
  box-sizing: border-box;
}

.p-component {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: normal;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}

.p-disabled, .p-component:disabled {
  opacity: 0.6;
}

.p-error {
  color: #FC6161;
}

.p-text-secondary {
  color: #676B89;
}

.pi {
  font-size: 1rem;
}

.p-link {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: transparent;
  }
}

:root {
  --blue-50:#f4fafe;
  --blue-100:#cae6fc;
  --blue-200:#a0d2fa;
  --blue-300:#75bef8;
  --blue-400:#4baaf5;
  --blue-500:#2196f3;
  --blue-600:#1c80cf;
  --blue-700:#1769aa;
  --blue-800:#125386;
  --blue-900:#0d3c61;
  --green-50:#f6fbf6;
  --green-100:#d4ecd5;
  --green-200:#b2ddb4;
  --green-300:#90cd93;
  --green-400:#6ebe71;
  --green-500:#4caf50;
  --green-600:#419544;
  --green-700:#357b38;
  --green-800:#2a602c;
  --green-900:#1e4620;
  --yellow-50:#fffcf5;
  --yellow-100:#fef0cd;
  --yellow-200:#fde4a5;
  --yellow-300:#fdd87d;
  --yellow-400:#fccc55;
  --yellow-500:#fbc02d;
  --yellow-600:#d5a326;
  --yellow-700:#b08620;
  --yellow-800:#8a6a19;
  --yellow-900:#644d12;
  --cyan-50:#f2fcfd;
  --cyan-100:#c2eff5;
  --cyan-200:#91e2ed;
  --cyan-300:#61d5e4;
  --cyan-400:#30c9dc;
  --cyan-500:#00bcd4;
  --cyan-600:#00a0b4;
  --cyan-700:#008494;
  --cyan-800:#006775;
  --cyan-900:#004b55;
  --pink-50:#fef4f7;
  --pink-100:#fac9da;
  --pink-200:#f69ebc;
  --pink-300:#f1749e;
  --pink-400:#ed4981;
  --pink-500:#e91e63;
  --pink-600:#c61a54;
  --pink-700:#a31545;
  --pink-800:#801136;
  --pink-900:#5d0c28;
  --indigo-50:#f5f6fb;
  --indigo-100:#d1d5ed;
  --indigo-200:#acb4df;
  --indigo-300:#8893d1;
  --indigo-400:#6372c3;
  --indigo-500:#3f51b5;
  --indigo-600:#36459a;
  --indigo-700:#2c397f;
  --indigo-800:#232d64;
  --indigo-900:#192048;
  --teal-50:#f2faf9;
  --teal-100:#c2e6e2;
  --teal-200:#91d2cc;
  --teal-300:#61beb5;
  --teal-400:#30aa9f;
  --teal-500:#009688;
  --teal-600:#008074;
  --teal-700:#00695f;
  --teal-800:#00534b;
  --teal-900:#003c36;
  --orange-50:#fff8f2;
  --orange-100:#fde0c2;
  --orange-200:#fbc791;
  --orange-300:#f9ae61;
  --orange-400:#f79530;
  --orange-500:#f57c00;
  --orange-600:#d06900;
  --orange-700:#ac5700;
  --orange-800:#874400;
  --orange-900:#623200;
  --bluegray-50:#f7f9f9;
  --bluegray-100:#d9e0e3;
  --bluegray-200:#bbc7cd;
  --bluegray-300:#9caeb7;
  --bluegray-400:#7e96a1;
  --bluegray-500:#607d8b;
  --bluegray-600:#526a76;
  --bluegray-700:#435861;
  --bluegray-800:#35454c;
  --bluegray-900:#263238;
  --purple-50:#faf4fb;
  --purple-100:#e7cbec;
  --purple-200:#d4a2dd;
  --purple-300:#c279ce;
  --purple-400:#af50bf;
  --purple-500:#9c27b0;
  --purple-600:#852196;
  --purple-700:#6d1b7b;
  --purple-800:#561561;
  --purple-900:#3e1046;
}

.p-autocomplete .p-autocomplete-loader {
  right: 0.571rem;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 2.857rem;
}
.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.2145rem 0.571rem;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: transparent;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
  border-color: #3b5f78;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.2145rem 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  color: #44486D;
  padding: 0;
  margin: 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.2145rem 0.571rem;
  margin-right: 0.5rem;
  background: #3b5f78;
  color: #ffffff;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}
.p-autocomplete.p-invalid.p-component > .p-inputtext {
  border-color: #FC6161;
}

.p-autocomplete-panel {
  background: #ececf9;
  color: #44486D;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.286rem;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.429rem 0.286rem;
  border: 0 none;
  color: #44486D;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #44486D;
  background: rgba(68, 72, 109, 0.07);
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #ffffff;
  background: #3b5f78;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  margin: 0;
  padding: 0.571rem 0.429rem;
  color: #8A8EA6;
  background: transparent;
  font-weight: 500;
}

.p-calendar.p-invalid.p-component > .p-inputtext {
  border-color: #FC6161;
}

.p-datepicker {
  padding: 0.857rem;
  background: transparent;
  color: #44486D;
  border: 1px solid transparent;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ececf9;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: transparent;
}
.p-datepicker .p-datepicker-header {
  padding: 0 0 0.75rem 0;
  color: #44486D;
  background: transparent;
  font-weight: 400;
  margin: 0;
  border-bottom: 0 none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #676B89;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #44486D;
  border-color: transparent;
  background: rgba(68, 72, 109, 0.07);
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
  border-color: #3b5f78;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 14px;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.357rem;
}
.p-datepicker table th > span {
  width: 2.571rem;
  height: 2.571rem;
}
.p-datepicker table td {
  padding: 0.357rem;
}
.p-datepicker table td > span {
  width: 2.571rem;
  height: 2.571rem;
  border-radius: 0px;
  transition: box-shadow 0.2s;
  border: 2px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: #ffffff;
  background: #3b5f78;
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-datepicker table td.p-datepicker-today > span {
  background: rgba(68, 72, 109, 0.07);
  color: #44486D;
  border-color: rgba(68, 72, 109, 0.12);
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #ffffff;
  background: #3b5f78;
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid rgba(68, 72, 109, 0.17);
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid rgba(68, 72, 109, 0.17);
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #676B89;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #44486D;
  border-color: transparent;
  background: rgba(68, 72, 109, 0.07);
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.357rem;
  transition: box-shadow 0.2s;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #ffffff;
  background: #3b5f78;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid rgba(68, 72, 109, 0.17);
  padding-right: 0.857rem;
  padding-left: 0.857rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.12);
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.12);
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th, .p-datepicker table td {
    padding: 0;
  }
}
.p-cascadeselect {
  background: rgba(68, 72, 109, 0.07);
  border: 1px solid transparent;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-cascadeselect:not(.p-disabled):hover {
  border-color: transparent;
}
.p-cascadeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
  border-color: #3b5f78;
}
.p-cascadeselect .p-cascadeselect-label {
  background: transparent;
  border: 0 none;
  padding: 0.429rem 0.571rem;
}
.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: #8A8EA6;
}
.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-cascadeselect .p-cascadeselect-trigger {
  background: transparent;
  color: #676B89;
  width: 2.357rem;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.p-cascadeselect.p-invalid.p-component {
  border-color: #FC6161;
}

.p-cascadeselect-panel {
  background: #ececf9;
  color: #44486D;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-cascadeselect-panel .p-cascadeselect-items {
  padding: 0.286rem;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: 0;
  border: 0 none;
  color: #44486D;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
  padding: 0.429rem 0.286rem;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: #ffffff;
  background: #3b5f78;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #44486D;
  background: rgba(68, 72, 109, 0.07);
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
  font-size: 0.875rem;
}

.p-input-filled .p-cascadeselect {
  background: transparent;
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: transparent;
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: rgba(68, 72, 109, 0.07);
}

.p-checkbox {
  width: 16px;
  height: 16px;
}
.p-checkbox .p-checkbox-box {
  border: 1px solid #3b5f78;
  background: rgba(68, 72, 109, 0.07);
  width: 16px;
  height: 16px;
  color: #44486D;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #3b5f78;
  background: #3b5f78;
}
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #2f4c60;
  background: #2f4c60;
  color: #ffffff;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: transparent;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
  border-color: #3b5f78;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #2f4c60;
  background: #2f4c60;
  color: #ffffff;
}
.p-checkbox.p-invalid > .p-checkbox-box {
  border-color: #FC6161;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: transparent;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #3b5f78;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: transparent;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #2f4c60;
}

.p-highlight .p-checkbox .p-checkbox-box {
  border-color: #ffffff;
}

.p-chips .p-chips-multiple-container {
  padding: 0.2145rem 0.571rem;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: transparent;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
  border-color: #3b5f78;
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.2145rem 0.571rem;
  margin-right: 0.5rem;
  background: #3b5f78;
  color: #ffffff;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.2145rem 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  color: #44486D;
  padding: 0;
  margin: 0;
}
.p-chips.p-invalid.p-component > .p-inputtext {
  border-color: #FC6161;
}

.p-colorpicker-preview {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: rgba(68, 72, 109, 0.07);
  border: 1px solid #191919;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #191919;
}

.p-colorpicker-overlay-panel {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-dropdown {
  background: rgba(68, 72, 109, 0.07);
  border: 1px solid transparent;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: transparent;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
  border-color: #3b5f78;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.571rem;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #8A8EA6;
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #676B89;
  width: 2.357rem;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: #676B89;
  right: 2.357rem;
}
.p-dropdown.p-invalid.p-component {
  border-color: #FC6161;
}

.p-dropdown-panel {
  background: #ececf9;
  color: #44486D;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-dropdown-panel .p-dropdown-header {
  padding: 0.429rem 0.286rem;
  border-bottom: 0 none;
  color: #44486D;
  background: transparent;
  margin: 0;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.571rem;
  margin-right: -1.571rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.571rem;
  color: #676B89;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter {
  padding-right: 3.142rem;
  margin-right: -3.142rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter-clear-icon {
  right: 2.142rem;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0.286rem;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.429rem 0.286rem;
  border: 0 none;
  color: #44486D;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #3b5f78;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #44486D;
  background: rgba(68, 72, 109, 0.07);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.571rem 0.429rem;
  color: #8A8EA6;
  background: transparent;
  font-weight: 500;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.429rem 0.286rem;
  color: #44486D;
  background: transparent;
}

.p-input-filled .p-dropdown {
  background: transparent;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: transparent;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: rgba(68, 72, 109, 0.07);
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
  background-color: transparent;
}

.p-editor-container .p-editor-toolbar {
  background: transparent;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid rgba(68, 72, 109, 0.17);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #676B89;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #676B89;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #676B89;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #44486D;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #44486D;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #44486D;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #44486D;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #44486D;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #44486D;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #ececf9;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 0.286rem;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #44486D;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #44486D;
  background: rgba(68, 72, 109, 0.07);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 0.429rem 0.286rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid rgba(68, 72, 109, 0.17);
}
.p-editor-container .p-editor-content .ql-editor {
  background: rgba(68, 72, 109, 0.07);
  color: #44486D;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #44486D;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #44486D;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #44486D;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #3b5f78;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #3b5f78;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #3b5f78;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #3b5f78;
}

.p-inputgroup-addon {
  background: rgba(68, 72, 109, 0.07);
  color: #676B89;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
  padding: 0.429rem 0.571rem;
  min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
  border-right: 1px solid transparent;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-inputwrapper > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.286rem;
}

.p-inputnumber.p-invalid.p-component > .p-inputtext {
  border-color: #FC6161;
}

.p-inputswitch {
  width: 2.714rem;
  height: 1.429rem;
}
.p-inputswitch .p-inputswitch-slider {
  background: rgba(68, 72, 109, 0.07);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 12px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: #676B89;
  width: 1.143rem;
  height: 1.143rem;
  left: 0.25rem;
  margin-top: -0.5715rem;
  border-radius: 8px;
  transition-duration: 0.2s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.143rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: rgba(68, 72, 109, 0.12);
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #3b5f78;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #FFFFFF;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #35566c;
}
.p-inputswitch.p-invalid {
  border-color: #FC6161;
}

.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #44486D;
  background: rgba(68, 72, 109, 0.07);
  padding: 0.429rem 0.571rem;
  border: 1px solid transparent;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
}
.p-inputtext:enabled:hover {
  border-color: transparent;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
  border-color: #3b5f78;
}
.p-inputtext.p-invalid.p-component {
  border-color: #FC6161;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.375375rem 0.499625rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.53625rem 0.71375rem;
}

.p-float-label > label {
  left: 0.571rem;
  color: #8A8EA6;
  transition-duration: 0.2s;
}

.p-float-label > label.p-error {
  color: #FC6161;
}

.p-input-icon-left > i:first-of-type,
.p-input-icon-left > svg:first-of-type,
.p-input-icon-left > .p-input-prefix {
  left: 0.571rem;
  color: #676B89;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.142rem;
}

.p-input-icon-left.p-float-label > label {
  left: 2.142rem;
}

.p-input-icon-right > i:last-of-type,
.p-input-icon-right > svg:last-of-type,
.p-input-icon-right > .p-input-suffix {
  right: 0.571rem;
  color: #676B89;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 2.142rem;
}

::-webkit-input-placeholder {
  color: #8A8EA6;
}

:-moz-placeholder {
  color: #8A8EA6;
}

::-moz-placeholder {
  color: #8A8EA6;
}

:-ms-input-placeholder {
  color: #8A8EA6;
}

.p-input-filled .p-inputtext {
  background-color: transparent;
}
.p-input-filled .p-inputtext:enabled:hover {
  background-color: transparent;
}
.p-input-filled .p-inputtext:enabled:focus {
  background-color: rgba(68, 72, 109, 0.07);
}

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.375375rem 0.499625rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.53625rem 0.71375rem;
}

.p-listbox {
  background: rgba(68, 72, 109, 0.07);
  color: #44486D;
  border: 1px solid transparent;
}
.p-listbox .p-listbox-header {
  padding: 0.429rem 0.286rem;
  border-bottom: 0 none;
  color: #44486D;
  background: transparent;
  margin: 0;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.571rem;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 0.571rem;
  color: #676B89;
}
.p-listbox .p-listbox-list {
  padding: 0.286rem;
}
.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.429rem 0.286rem;
  border: 0 none;
  color: #44486D;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #ffffff;
  background: #3b5f78;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-listbox .p-listbox-list .p-listbox-item-group {
  margin: 0;
  padding: 0.571rem 0.429rem;
  color: #8A8EA6;
  background: transparent;
  font-weight: 500;
}
.p-listbox .p-listbox-list .p-listbox-empty-message {
  padding: 0.429rem 0.286rem;
  color: #44486D;
  background: transparent;
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #44486D;
  background: rgba(68, 72, 109, 0.07);
}
.p-listbox.p-invalid {
  border-color: #FC6161;
}

.p-mention-panel {
  background: #ececf9;
  color: #44486D;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-mention-panel .p-mention-items {
  padding: 0.286rem;
}
.p-mention-panel .p-mention-items .p-mention-item {
  margin: 0;
  padding: 0.429rem 0.286rem;
  border: 0 none;
  color: #44486D;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-mention-panel .p-mention-items .p-mention-item:hover {
  color: #44486D;
  background: rgba(68, 72, 109, 0.07);
}
.p-mention-panel .p-mention-items .p-mention-item.p-highlight {
  color: #ffffff;
  background: #3b5f78;
}

.p-multiselect {
  background: rgba(68, 72, 109, 0.07);
  border: 1px solid transparent;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: transparent;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
  border-color: #3b5f78;
}
.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  padding-right: 1.571rem;
}
.p-multiselect .p-multiselect-label {
  padding: 0.429rem 0.571rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #8A8EA6;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.2145rem 0.571rem;
  margin-right: 0.5rem;
  background: #3b5f78;
  color: #ffffff;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #676B89;
  width: 2.357rem;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.p-multiselect .p-multiselect-clear-icon {
  color: #676B89;
  right: 2.357rem;
}
.p-multiselect.p-invalid.p-component {
  border-color: #FC6161;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.2145rem 0.571rem;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label.p-multiselect-items-label {
  padding: 0.429rem 0.571rem;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  padding-right: 1.571rem;
}

.p-multiselect-panel {
  background: #ececf9;
  color: #44486D;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.429rem 0.286rem;
  border-bottom: 0 none;
  color: #44486D;
  background: transparent;
  margin: 0;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
  margin: 0 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 1.571rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 0.571rem;
  color: #676B89;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  width: 2rem;
  height: 2rem;
  color: #676B89;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #44486D;
  border-color: transparent;
  background: rgba(68, 72, 109, 0.07);
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0.286rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.429rem 0.286rem;
  border: 0 none;
  color: #44486D;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #ffffff;
  background: #3b5f78;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #44486D;
  background: rgba(68, 72, 109, 0.07);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.571rem 0.429rem;
  color: #8A8EA6;
  background: transparent;
  font-weight: 500;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.429rem 0.286rem;
  color: #44486D;
  background: transparent;
}

.p-input-filled .p-multiselect {
  background: transparent;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: transparent;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: rgba(68, 72, 109, 0.07);
}

.p-password.p-invalid.p-component > .p-inputtext {
  border-color: #FC6161;
}

.p-password-panel {
  padding: 1.143rem;
  background: transparent;
  color: #44486D;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
  background: rgba(68, 72, 109, 0.12);
}
.p-password-panel .p-password-meter .p-password-strength.weak {
  background: #D32F2F;
}
.p-password-panel .p-password-meter .p-password-strength.medium {
  background: #FBC02D;
}
.p-password-panel .p-password-meter .p-password-strength.strong {
  background: #689F38;
}

.p-radiobutton {
  width: 20px;
  height: 20px;
}
.p-radiobutton .p-radiobutton-box {
  border: 2px solid #8A8EA6;
  background: rgba(68, 72, 109, 0.07);
  width: 20px;
  height: 20px;
  color: #44486D;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: transparent;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
  border-color: #3b5f78;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  background-color: #ffffff;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #3b5f78;
  background: #3b5f78;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #2f4c60;
  background: #2f4c60;
  color: #ffffff;
}
.p-radiobutton.p-invalid > .p-radiobutton-box {
  border-color: #FC6161;
}
.p-radiobutton:focus {
  outline: 0 none;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: transparent;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: transparent;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #3b5f78;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #2f4c60;
}

.p-highlight .p-radiobutton .p-radiobutton-box {
  border-color: #ffffff;
}

.p-rating .p-rating-icon {
  color: #44486D;
  margin-left: 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  font-size: 1.286rem;
}
.p-rating .p-rating-icon.p-rating-cancel {
  color: #FC6161;
}
.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}
.p-rating .p-rating-icon.pi-star-fill {
  color: #3b5f78;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #3b5f78;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #FC6161;
}

.p-highlight .p-rating .p-rating-icon {
  color: #ffffff;
}

.p-selectbutton .p-button {
  background: rgba(68, 72, 109, 0.07);
  border: 1px solid transparent;
  color: #44486D;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #676B89;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: rgba(68, 72, 109, 0.12);
  border-color: transparent;
  color: #44486D;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #676B89;
}
.p-selectbutton .p-button.p-highlight {
  background: #3b5f78;
  border-color: #3b5f78;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover {
  background: #35566c;
  border-color: #35566c;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton.p-invalid > .p-button {
  border-color: #FC6161;
}

.p-slider {
  background: rgba(68, 72, 109, 0.12);
  border: 0 none;
}
.p-slider.p-slider-horizontal {
  height: 0.429rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
  width: 0.429rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: #ffffff;
  border: 4px solid #3b5f78;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-slider .p-slider-range {
  background: #3b5f78;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #3b5f78;
  border-color: #3b5f78;
}

.p-treeselect {
  background: rgba(68, 72, 109, 0.07);
  border: 1px solid transparent;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-treeselect:not(.p-disabled):hover {
  border-color: transparent;
}
.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
  border-color: #3b5f78;
}
.p-treeselect .p-treeselect-label {
  padding: 0.429rem 0.571rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-treeselect .p-treeselect-label.p-placeholder {
  color: #8A8EA6;
}
.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.2145rem 0.571rem;
  margin-right: 0.5rem;
  background: rgba(68, 72, 109, 0.17);
  color: #44486D;
  border-radius: 16px;
}
.p-treeselect .p-treeselect-trigger {
  background: transparent;
  color: #676B89;
  width: 2.357rem;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.p-treeselect.p-invalid.p-component {
  border-color: #FC6161;
}

.p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: 0.2145rem 0.571rem;
}

.p-treeselect-panel {
  background: #ececf9;
  color: #44486D;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-treeselect-panel .p-treeselect-header {
  padding: 0.429rem 0.286rem;
  border-bottom: 0 none;
  color: #44486D;
  background: transparent;
  margin: 0;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  margin-right: 0.5rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
  padding-right: 1.571rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
  right: 0.571rem;
  color: #676B89;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
  padding-right: 3.142rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
  right: 2.142rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
  width: 2rem;
  height: 2rem;
  color: #676B89;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
  color: #44486D;
  border-color: transparent;
  background: rgba(68, 72, 109, 0.07);
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  padding: 0.429rem 0.286rem;
  color: #44486D;
  background: transparent;
}

.p-input-filled .p-treeselect {
  background: transparent;
}
.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background-color: transparent;
}
.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: rgba(68, 72, 109, 0.07);
}

.p-togglebutton.p-button {
  background: rgba(68, 72, 109, 0.07);
  border: 1px solid transparent;
  color: #44486D;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: #676B89;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: rgba(68, 72, 109, 0.12);
  border-color: transparent;
  color: #44486D;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #676B89;
}
.p-togglebutton.p-button.p-highlight {
  background: #3b5f78;
  border-color: #3b5f78;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover {
  background: #35566c;
  border-color: #35566c;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-invalid > .p-button {
  border-color: #FC6161;
}

.p-button {
  color: #ffffff;
  background: #3b5f78;
  border: 1px solid #3b5f78;
  padding: 0.429rem 0.571rem;
  font-size: 14px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
  background: #35566c;
  color: #ffffff;
  border-color: #35566c;
}
.p-button:enabled:active, .p-button:not(button):not(a):not(.p-disabled):active {
  background: #2f4c60;
  color: #ffffff;
  border-color: #2f4c60;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: #3b5f78;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover, .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgba(59, 95, 120, 0.04);
  color: #3b5f78;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active, .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgba(59, 95, 120, 0.16);
  color: #3b5f78;
  border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
  color: #676B89;
  border-color: #676B89;
}
.p-button.p-button-outlined.p-button-plain:enabled:hover, .p-button.p-button-outlined.p-button-plain:not(button):not(a):not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.07);
  color: #676B89;
}
.p-button.p-button-outlined.p-button-plain:enabled:active, .p-button.p-button-outlined.p-button-plain:not(button):not(a):not(.p-disabled):active {
  background: rgba(68, 72, 109, 0.17);
  color: #676B89;
}
.p-button.p-button-text {
  background-color: transparent;
  color: #3b5f78;
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover, .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgba(59, 95, 120, 0.04);
  color: #3b5f78;
  border-color: transparent;
}
.p-button.p-button-text:enabled:active, .p-button.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgba(59, 95, 120, 0.16);
  color: #3b5f78;
  border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
  color: #676B89;
}
.p-button.p-button-text.p-button-plain:enabled:hover, .p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.07);
  color: #676B89;
}
.p-button.p-button-text.p-button-plain:enabled:active, .p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):active {
  background: rgba(68, 72, 109, 0.17);
  color: #676B89;
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 14px;
  height: 14px;
  line-height: 14px;
  color: #3b5f78;
  background-color: #ffffff;
}
.p-button.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 2.286rem;
  padding: 0.429rem 0;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.286rem;
}
.p-button.p-button-sm {
  font-size: 12.25px;
  padding: 0.375375rem 0.499625rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-lg {
  font-size: 17.5px;
  padding: 0.53625rem 0.71375rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}
.p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
  margin-left: 0.5rem;
}
.p-button.p-button-loading-label-only.p-button-loading-right .p-button-label {
  margin-right: 0.5rem;
}
.p-button.p-button-loading-label-only.p-button-loading-top .p-button-label {
  margin-top: 0.5rem;
}
.p-button.p-button-loading-label-only.p-button-loading-bottom .p-button-label {
  margin-bottom: 0.5rem;
}
.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin: 0;
}

.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 2.286rem;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button, .p-fileupload-choose.p-button-secondary {
  color: #44486D;
  background: rgba(68, 72, 109, 0.07);
  border: 1px solid transparent;
}
.p-button.p-button-secondary:enabled:hover, .p-button.p-button-secondary:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary:enabled:hover, .p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.12);
  color: #44486D;
  border-color: transparent;
}
.p-button.p-button-secondary:enabled:focus, .p-button.p-button-secondary:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-secondary:enabled:focus, .p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):focus {
  box-shadow: none;
}
.p-button.p-button-secondary:enabled:active, .p-button.p-button-secondary:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary:enabled:active, .p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):active {
  background: rgba(68, 72, 109, 0.17);
  color: #44486D;
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined, .p-fileupload-choose.p-button-secondary.p-button-outlined {
  background-color: transparent;
  color: rgba(68, 72, 109, 0.07);
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.04);
  color: rgba(68, 72, 109, 0.07);
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgba(68, 72, 109, 0.16);
  color: rgba(68, 72, 109, 0.07);
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text, .p-fileupload-choose.p-button-secondary.p-button-text {
  background-color: transparent;
  color: rgba(68, 72, 109, 0.07);
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover, .p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-text:enabled:hover, .p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.04);
  border-color: transparent;
  color: rgba(68, 72, 109, 0.07);
}
.p-button.p-button-secondary.p-button-text:enabled:active, .p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary.p-button-text:enabled:active, .p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgba(68, 72, 109, 0.16);
  border-color: transparent;
  color: rgba(68, 72, 109, 0.07);
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button, .p-fileupload-choose.p-button-info {
  color: #FFFFFF;
  background: #873EFE;
  border: 1px solid transparent;
}
.p-button.p-button-info:enabled:hover, .p-button.p-button-info:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-info:enabled:hover, .p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):hover {
  background: #6C1AF2;
  color: #FFFFFF;
  border-color: transparent;
}
.p-button.p-button-info:enabled:focus, .p-button.p-button-info:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-info:enabled:focus, .p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):focus {
  box-shadow: none;
}
.p-button.p-button-info:enabled:active, .p-button.p-button-info:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-info:enabled:active, .p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):active {
  background: #5310C1;
  color: #FFFFFF;
  border-color: transparent;
}
.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined, .p-fileupload-choose.p-button-info.p-button-outlined {
  background-color: transparent;
  color: #873EFE;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover, .p-button.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-info.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgba(135, 62, 254, 0.04);
  color: #873EFE;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:active, .p-button.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-info.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgba(135, 62, 254, 0.16);
  color: #873EFE;
  border: 1px solid;
}
.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text, .p-fileupload-choose.p-button-info.p-button-text {
  background-color: transparent;
  color: #873EFE;
  border-color: transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover, .p-button.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-info.p-button-text:enabled:hover, .p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgba(135, 62, 254, 0.04);
  border-color: transparent;
  color: #873EFE;
}
.p-button.p-button-info.p-button-text:enabled:active, .p-button.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-info.p-button-text:enabled:active, .p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgba(135, 62, 254, 0.16);
  border-color: transparent;
  color: #873EFE;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button, .p-fileupload-choose.p-button-success {
  color: #0A061A;
  background: #0BD18A;
  border: 1px solid transparent;
}
.p-button.p-button-success:enabled:hover, .p-button.p-button-success:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-success:enabled:hover, .p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):hover {
  background: #049B65;
  color: #0A061A;
  border-color: transparent;
}
.p-button.p-button-success:enabled:focus, .p-button.p-button-success:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-success:enabled:focus, .p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):focus {
  box-shadow: none;
}
.p-button.p-button-success:enabled:active, .p-button.p-button-success:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-success:enabled:active, .p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):active {
  background: #017E52;
  color: #0A061A;
  border-color: transparent;
}
.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined, .p-fileupload-choose.p-button-success.p-button-outlined {
  background-color: transparent;
  color: #0BD18A;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover, .p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-success.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgba(11, 209, 138, 0.04);
  color: #0BD18A;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active, .p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-success.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgba(11, 209, 138, 0.16);
  color: #0BD18A;
  border: 1px solid;
}
.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text, .p-fileupload-choose.p-button-success.p-button-text {
  background-color: transparent;
  color: #0BD18A;
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover, .p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-success.p-button-text:enabled:hover, .p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgba(11, 209, 138, 0.04);
  border-color: transparent;
  color: #0BD18A;
}
.p-button.p-button-success.p-button-text:enabled:active, .p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-success.p-button-text:enabled:active, .p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgba(11, 209, 138, 0.16);
  border-color: transparent;
  color: #0BD18A;
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button, .p-fileupload-choose.p-button-warning {
  color: #44486D;
  background: #EEE500;
  border: 1px solid transparent;
}
.p-button.p-button-warning:enabled:hover, .p-button.p-button-warning:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning:enabled:hover, .p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):hover {
  background: #D1C901;
  color: #44486D;
  border-color: transparent;
}
.p-button.p-button-warning:enabled:focus, .p-button.p-button-warning:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-warning:enabled:focus, .p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):focus {
  box-shadow: none;
}
.p-button.p-button-warning:enabled:active, .p-button.p-button-warning:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-warning:enabled:active, .p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):active {
  background: #BAB302;
  color: #44486D;
  border-color: transparent;
}
.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined, .p-fileupload-choose.p-button-warning.p-button-outlined {
  background-color: transparent;
  color: #EEE500;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-button.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgba(238, 229, 0, 0.04);
  color: #EEE500;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active, .p-button.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-warning.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgba(238, 229, 0, 0.16);
  color: #EEE500;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text, .p-fileupload-choose.p-button-warning.p-button-text {
  background-color: transparent;
  color: #EEE500;
  border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover, .p-button.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning.p-button-text:enabled:hover, .p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgba(238, 229, 0, 0.04);
  border-color: transparent;
  color: #EEE500;
}
.p-button.p-button-warning.p-button-text:enabled:active, .p-button.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-warning.p-button-text:enabled:active, .p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgba(238, 229, 0, 0.16);
  border-color: transparent;
  color: #EEE500;
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button, .p-fileupload-choose.p-button-help {
  color: #FFFFFF;
  background: #EC4DBC;
  border: 1px solid transparent;
}
.p-button.p-button-help:enabled:hover, .p-button.p-button-help:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-help:enabled:hover, .p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):hover {
  background: #E80EA6;
  color: #FFFFFF;
  border-color: transparent;
}
.p-button.p-button-help:enabled:focus, .p-button.p-button-help:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-help:enabled:focus, .p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):focus {
  box-shadow: none;
}
.p-button.p-button-help:enabled:active, .p-button.p-button-help:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-help:enabled:active, .p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):active {
  background: #B30C81;
  color: #FFFFFF;
  border-color: transparent;
}
.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined, .p-fileupload-choose.p-button-help.p-button-outlined {
  background-color: transparent;
  color: #EC4DBC;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover, .p-button.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-help.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgba(236, 77, 188, 0.04);
  color: #EC4DBC;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:active, .p-button.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-help.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgba(236, 77, 188, 0.16);
  color: #EC4DBC;
  border: 1px solid;
}
.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text, .p-fileupload-choose.p-button-help.p-button-text {
  background-color: transparent;
  color: #EC4DBC;
  border-color: transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover, .p-button.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-help.p-button-text:enabled:hover, .p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgba(236, 77, 188, 0.04);
  border-color: transparent;
  color: #EC4DBC;
}
.p-button.p-button-help.p-button-text:enabled:active, .p-button.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-help.p-button-text:enabled:active, .p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgba(236, 77, 188, 0.16);
  border-color: transparent;
  color: #EC4DBC;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button, .p-fileupload-choose.p-button-danger {
  color: #FFFFFF;
  background: #FC6161;
  border: 1px solid transparent;
}
.p-button.p-button-danger:enabled:hover, .p-button.p-button-danger:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger:enabled:hover, .p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):hover {
  background: #E73A3A;
  color: #FFFFFF;
  border-color: transparent;
}
.p-button.p-button-danger:enabled:focus, .p-button.p-button-danger:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-danger:enabled:focus, .p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):focus {
  box-shadow: none;
}
.p-button.p-button-danger:enabled:active, .p-button.p-button-danger:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-danger:enabled:active, .p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):active {
  background: #C42424;
  color: #FFFFFF;
  border-color: transparent;
}
.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined, .p-fileupload-choose.p-button-danger.p-button-outlined {
  background-color: transparent;
  color: #FC6161;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgba(252, 97, 97, 0.04);
  color: #FC6161;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active, .p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-danger.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgba(252, 97, 97, 0.16);
  color: #FC6161;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text, .p-fileupload-choose.p-button-danger.p-button-text {
  background-color: transparent;
  color: #FC6161;
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover, .p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger.p-button-text:enabled:hover, .p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgba(252, 97, 97, 0.04);
  border-color: transparent;
  color: #FC6161;
}
.p-button.p-button-danger.p-button-text:enabled:active, .p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-danger.p-button-text:enabled:active, .p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgba(252, 97, 97, 0.16);
  border-color: transparent;
  color: #FC6161;
}

.p-button.p-button-link {
  color: #3b5f78;
  background: transparent;
  border: transparent;
}
.p-button.p-button-link:enabled:hover, .p-button.p-button-link:not(button):not(a):not(.p-disabled):hover {
  background: transparent;
  color: #3b5f78;
  border-color: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label, .p-button.p-button-link:not(button):not(a):not(.p-disabled):hover .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:enabled:focus, .p-button.p-button-link:not(button):not(a):not(.p-disabled):focus {
  background: transparent;
  box-shadow: none;
  border-color: transparent;
}
.p-button.p-button-link:enabled:active, .p-button.p-button-link:not(button):not(a):not(.p-disabled):active {
  background: transparent;
  color: #3b5f78;
  border-color: transparent;
}

.p-speeddial-button.p-button.p-button-icon-only {
  width: 4rem;
  height: 4rem;
}
.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
  font-size: 1.3rem;
}

.p-speeddial-action {
  width: 3rem;
  height: 3rem;
  background: #676B89;
  color: #fff;
}
.p-speeddial-action:hover {
  background: #44486D;
  color: #fff;
}

.p-speeddial-direction-up .p-speeddial-item {
  margin: 0.25rem;
}
.p-speeddial-direction-up .p-speeddial-item:first-child {
  margin-bottom: 0.5rem;
}

.p-speeddial-direction-down .p-speeddial-item {
  margin: 0.25rem;
}
.p-speeddial-direction-down .p-speeddial-item:first-child {
  margin-top: 0.5rem;
}

.p-speeddial-direction-left .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-left .p-speeddial-item:first-child {
  margin-right: 0.5rem;
}

.p-speeddial-direction-right .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-right .p-speeddial-item:first-child {
  margin-left: 0.5rem;
}

.p-speeddial-circle .p-speeddial-item,
.p-speeddial-semi-circle .p-speeddial-item,
.p-speeddial-quarter-circle .p-speeddial-item {
  margin: 0;
}
.p-speeddial-circle .p-speeddial-item:first-child, .p-speeddial-circle .p-speeddial-item:last-child,
.p-speeddial-semi-circle .p-speeddial-item:first-child,
.p-speeddial-semi-circle .p-speeddial-item:last-child,
.p-speeddial-quarter-circle .p-speeddial-item:first-child,
.p-speeddial-quarter-circle .p-speeddial-item:last-child {
  margin: 0;
}

.p-speeddial-mask {
  background-color: rgba(0, 0, 0, 0.4);
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: #676B89;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #44486D;
  border-color: transparent;
  background: rgba(68, 72, 109, 0.07);
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-carousel .p-carousel-indicators {
  padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: rgba(68, 72, 109, 0.12);
  width: 1.429rem;
  height: 0.572rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: rgba(68, 72, 109, 0.15);
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #3b5f78;
  color: #ffffff;
}

.p-datatable .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: transparent;
  color: #8A8EA6;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 1px 0 1px 0;
  padding: 0.571rem 0.571rem;
  font-weight: 500;
}
.p-datatable .p-datatable-footer {
  background: transparent;
  color: #8A8EA6;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0.571rem 0.571rem;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: #8A8EA6;
  background: transparent;
  transition: box-shadow 0.2s;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 0.571rem 0.571rem;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: #8A8EA6;
  background: transparent;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #8A8EA6;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #ffffff;
  background: #3b5f78;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: rgba(68, 72, 109, 0.07);
  color: #8A8EA6;
}
.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: #8A8EA6;
}
.p-datatable .p-sortable-column.p-highlight {
  background: rgba(68, 72, 109, 0.12);
  color: #8A8EA6;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #8A8EA6;
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background: rgba(68, 72, 109, 0.12);
  color: #8A8EA6;
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: #8A8EA6;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: 0 none;
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: transparent;
  color: #44486D;
  transition: box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 0 0 0 0;
  padding: 0.429rem 0.571rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #676B89;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #44486D;
  border-color: transparent;
  background: rgba(68, 72, 109, 0.07);
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr > td > .p-column-title {
  font-weight: 500;
}
.p-datatable .p-datatable-tbody > tr > td.p-highlight {
  background: #3b5f78;
  color: #ffffff;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #3b5f78;
  color: #ffffff;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #3b5f78;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #3b5f78;
}
.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: rgba(68, 72, 109, 0.07);
  color: #44486D;
}
.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:focus {
  outline: 0.15rem solid #3b5f78;
  outline-offset: -0.15rem;
}
.p-datatable.p-datatable-selectable-cell .p-datatable-tbody > tr.p-selectable-row > td.p-selectable-cell:not(.p-highlight):hover {
  background: rgba(68, 72, 109, 0.07);
  color: #44486D;
}
.p-datatable.p-datatable-selectable-cell .p-datatable-tbody > tr.p-selectable-row > td.p-selectable-cell:focus {
  outline: 0.15rem solid #3b5f78;
  outline-offset: -0.15rem;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: rgba(68, 72, 109, 0.07);
  color: #44486D;
}
.p-datatable .p-column-resizer-helper {
  background: #3b5f78;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: transparent;
}
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot {
  background-color: transparent;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background: transparent;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
  background: #3b5f78;
  color: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler {
  color: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler:hover {
  color: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd + .p-row-expanded {
  background: transparent;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.2855rem 0.2855rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.2855rem 0.2855rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.2145rem 0.2855rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 0.71375rem 0.71375rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 0.71375rem 0.71375rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 0.53625rem 0.71375rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

.p-datatable-drag-selection-helper {
  background: rgba(59, 95, 120, 0.16);
}

.p-dataview .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: transparent;
  color: #8A8EA6;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 1px 0 1px 0;
  padding: 0.571rem 0.571rem;
  font-weight: 500;
}
.p-dataview .p-dataview-content {
  background: transparent;
  color: #44486D;
  border: 0 none;
  padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid rgba(68, 72, 109, 0.07);
  border-width: 0 0 0 0;
}
.p-dataview .p-dataview-footer {
  background: transparent;
  color: #8A8EA6;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}

.p-datascroller .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-datascroller .p-paginator-bottom {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-datascroller .p-datascroller-header {
  background: transparent;
  color: #8A8EA6;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 1px 0 1px 0;
  padding: 0.571rem 0.571rem;
  font-weight: 500;
}
.p-datascroller .p-datascroller-content {
  background: transparent;
  color: #44486D;
  border: 0 none;
  padding: 0;
}
.p-datascroller.p-datascroller-inline .p-datascroller-list > li {
  border: solid rgba(68, 72, 109, 0.07);
  border-width: 0 0 0 0;
}
.p-datascroller .p-datascroller-footer {
  background: transparent;
  color: #8A8EA6;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: #676B89;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-menu-button:hover {
  color: #44486D;
  border-color: transparent;
  background: rgba(68, 72, 109, 0.07);
}
.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: rgba(68, 72, 109, 0.07);
  color: #44486D;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: #3b5f78;
  color: #ffffff;
}
.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #676B89;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-clear-button:hover {
  color: #44486D;
  border-color: transparent;
  background: rgba(68, 72, 109, 0.07);
}
.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}

.p-column-filter-overlay {
  background: #ececf9;
  color: #44486D;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  min-width: 21rem;
}
.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0.286rem;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.429rem 0.286rem;
  border: 0 none;
  color: #44486D;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: #ffffff;
  background: #3b5f78;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #44486D;
  background: rgba(68, 72, 109, 0.07);
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid rgba(68, 72, 109, 0.17);
  margin: 0.25rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.429rem 0.286rem;
  border-bottom: 0 none;
  color: #44486D;
  background: transparent;
  margin: 0;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1.143rem;
  border-bottom: 1px solid rgba(68, 72, 109, 0.17);
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.714rem 1.143rem;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1.143rem;
}

.fc {
  /* FullCalendar 4 */
  /* FullCalendar 5 */
}
.fc.fc-unthemed .fc-view-container th {
  background: transparent;
  border: 1px solid rgba(68, 72, 109, 0.17);
  color: #44486D;
}
.fc.fc-unthemed .fc-view-container td.fc-widget-content {
  border: 1px solid rgba(68, 72, 109, 0.17);
  color: #44486D;
}
.fc.fc-unthemed .fc-view-container td.fc-head-container {
  border: 1px solid rgba(68, 72, 109, 0.17);
}
.fc.fc-unthemed .fc-view-container .fc-view {
  background: transparent;
}
.fc.fc-unthemed .fc-view-container .fc-row {
  border-right: 1px solid rgba(68, 72, 109, 0.17);
}
.fc.fc-unthemed .fc-view-container .fc-event {
  background: #35566c;
  border: 1px solid #35566c;
  color: #ffffff;
}
.fc.fc-unthemed .fc-view-container .fc-divider {
  background: transparent;
  border: 1px solid rgba(68, 72, 109, 0.17);
}
.fc.fc-unthemed .fc-toolbar .fc-button {
  color: #ffffff;
  background: #3b5f78;
  border: 1px solid #3b5f78;
  font-size: 14px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  display: flex;
  align-items: center;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
  background: #35566c;
  color: #ffffff;
  border-color: #35566c;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
  background: #2f4c60;
  color: #ffffff;
  border-color: #2f4c60;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "\e900";
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "\e901";
}
.fc.fc-unthemed .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: rgba(68, 72, 109, 0.07);
  border: 1px solid transparent;
  color: #44486D;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: rgba(68, 72, 109, 0.12);
  border-color: transparent;
  color: #44486D;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #3b5f78;
  border-color: #3b5f78;
  color: #ffffff;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #35566c;
  border-color: #35566c;
  color: #ffffff;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
  z-index: 1;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
  border-color: rgba(68, 72, 109, 0.17);
}
.fc.fc-theme-standard .fc-view-harness th {
  background: transparent;
  border-color: rgba(68, 72, 109, 0.17);
  color: #44486D;
}
.fc.fc-theme-standard .fc-view-harness td {
  color: #44486D;
  border-color: rgba(68, 72, 109, 0.17);
}
.fc.fc-theme-standard .fc-view-harness .fc-view {
  background: transparent;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover {
  background: none;
  border: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
  border: 1px solid rgba(68, 72, 109, 0.17);
  padding: 0.714rem 1.143rem;
  background: transparent;
  color: #44486D;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: "PrimeIcons" !important;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  color: #676B89;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before {
  content: "\e90b";
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:hover {
  color: #44486D;
  border-color: transparent;
  background: rgba(68, 72, 109, 0.07);
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
  padding: 1.143rem;
  border: 1px solid rgba(68, 72, 109, 0.17);
  background: transparent;
  color: #44486D;
  border-top: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
  color: #ffffff;
  background: #35566c;
  border-color: #35566c;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
  color: #ffffff;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
  background: #35566c;
  border-color: #35566c;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
  background: rgba(68, 72, 109, 0.07);
  color: #44486D;
}
.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
  background: transparent;
}
.fc.fc-theme-standard .fc-toolbar .fc-button {
  color: #ffffff;
  background: #3b5f78;
  border: 1px solid #3b5f78;
  font-size: 14px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
  background: #35566c;
  color: #ffffff;
  border-color: #35566c;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
  background: #2f4c60;
  color: #ffffff;
  border-color: #2f4c60;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
  opacity: 0.6;
  color: #ffffff;
  background: #3b5f78;
  border: 1px solid #3b5f78;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "\e900";
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "\e901";
}
.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: rgba(68, 72, 109, 0.07);
  border: 1px solid transparent;
  color: #44486D;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: rgba(68, 72, 109, 0.12);
  border-color: transparent;
  color: #44486D;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #3b5f78;
  border-color: #3b5f78;
  color: #ffffff;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #35566c;
  border-color: #35566c;
  color: #ffffff;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
  z-index: 1;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.fc.fc-theme-standard .fc-highlight {
  color: #ffffff;
  background: #3b5f78;
}

.p-orderlist .p-orderlist-controls {
  padding: 1.143rem;
}
.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
  background: transparent;
  color: #44486D;
  border: 1px solid rgba(68, 72, 109, 0.17);
  padding: 0.714rem 1.143rem;
  font-weight: 500;
  border-bottom: 0 none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-orderlist .p-orderlist-list {
  border: 1px solid rgba(68, 72, 109, 0.17);
  background: transparent;
  color: #44486D;
  padding: 0.286rem;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.429rem 0.286rem;
  margin: 0;
  border: 0 none;
  color: #44486D;
  background: transparent;
  transition: transform 0.2s, box-shadow 0.2s;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background: rgba(68, 72, 109, 0.07);
  color: #44486D;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #ffffff;
  background: #3b5f78;
}

@media screen and (max-width: 769px) {
  .p-orderlist {
    flex-direction: column;
  }
  .p-orderlist .p-orderlist-controls {
    padding: 1.143rem;
    flex-direction: row;
  }
  .p-orderlist .p-orderlist-controls .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-orderlist .p-orderlist-controls .p-button:last-child {
    margin-right: 0;
  }
}
.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: rgba(68, 72, 109, 0.07);
  color: #44486D;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: #3b5f78;
  color: #ffffff;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #111b23;
}
.p-organizationchart .p-organizationchart-line-down {
  background: rgba(68, 72, 109, 0.17);
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid rgba(68, 72, 109, 0.17);
  border-color: rgba(68, 72, 109, 0.17);
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid rgba(68, 72, 109, 0.17);
  border-color: rgba(68, 72, 109, 0.17);
}
.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid rgba(68, 72, 109, 0.17);
  background: transparent;
  color: #44486D;
  padding: 1.143rem;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}

.p-paginator {
  background: transparent;
  color: #44486D;
  border: solid rgba(68, 72, 109, 0.17);
  border-width: 0;
  padding: 0.571rem 0.571rem;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: #44486D;
  min-width: 1.429rem;
  height: 1.429rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: rgba(68, 72, 109, 0.07);
  border-color: transparent;
  color: #44486D;
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  height: 1.429rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-page-input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 1.429rem;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: #44486D;
  min-width: 1.429rem;
  height: 1.429rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #44486D;
  min-width: 1.429rem;
  height: 1.429rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #3b5f78;
  border-color: #3b5f78;
  color: #ffffff;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: rgba(68, 72, 109, 0.07);
  border-color: transparent;
  color: #44486D;
}

.p-picklist .p-picklist-buttons {
  padding: 1.143rem;
}
.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
  background: transparent;
  color: #44486D;
  border: 1px solid rgba(68, 72, 109, 0.17);
  padding: 0.714rem 1.143rem;
  font-weight: 500;
  border-bottom: 0 none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-picklist .p-picklist-list {
  border: 1px solid rgba(68, 72, 109, 0.17);
  background: transparent;
  color: #44486D;
  padding: 0.286rem;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.429rem 0.286rem;
  margin: 0;
  border: 0 none;
  color: #44486D;
  background: transparent;
  transition: transform 0.2s, box-shadow 0.2s;
}
.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background: rgba(68, 72, 109, 0.07);
  color: #44486D;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #ffffff;
  background: #3b5f78;
}

@media screen and (max-width: 769px) {
  .p-picklist {
    flex-direction: column;
  }
  .p-picklist .p-picklist-buttons {
    padding: 1.143rem;
    flex-direction: row;
  }
  .p-picklist .p-picklist-buttons .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-picklist .p-picklist-buttons .p-button:last-child {
    margin-right: 0;
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
    content: "\e930";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
    content: "\e92c";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
    content: "\e933";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
    content: "\e92f";
  }
}
.p-tree {
  border: 1px solid rgba(68, 72, 109, 0.17);
  background: transparent;
  color: #44486D;
  padding: 1.143rem;
}
.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  transition: box-shadow 0.2s;
  padding: 0.429rem 0.571rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #676B89;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #44486D;
  border-color: transparent;
  background: rgba(68, 72, 109, 0.07);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: #44486D;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #44486D;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #3b5f78;
  color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: rgba(68, 72, 109, 0.07);
  color: #44486D;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: rgba(68, 72, 109, 0.07);
  color: #44486D;
}
.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.571rem;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.571rem;
  color: #676B89;
}
.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}
.p-tree .p-treenode-droppoint {
  height: 0.5rem;
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background: #2f4c60;
}

.p-treetable .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-paginator-bottom {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-treetable-header {
  background: transparent;
  color: #8A8EA6;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 1px 0 1px 0;
  padding: 0.571rem 0.571rem;
  font-weight: 500;
}
.p-treetable .p-treetable-footer {
  background: transparent;
  color: #8A8EA6;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
}
.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 0.571rem 0.571rem;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: #8A8EA6;
  background: transparent;
  transition: box-shadow 0.2s;
}
.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 0.571rem 0.571rem;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: #8A8EA6;
  background: transparent;
}
.p-treetable .p-sortable-column {
  outline-color: #3b5f78;
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #8A8EA6;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #ffffff;
  background: #3b5f78;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: rgba(68, 72, 109, 0.07);
  color: #8A8EA6;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #8A8EA6;
}
.p-treetable .p-sortable-column.p-highlight {
  background: rgba(68, 72, 109, 0.12);
  color: #8A8EA6;
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #8A8EA6;
}
.p-treetable .p-treetable-tbody > tr {
  background: transparent;
  color: #44486D;
  transition: box-shadow 0.2s;
}
.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 0 0 0 0;
  padding: 0.429rem 0.571rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #676B89;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #44486D;
  border-color: transparent;
  background: rgba(68, 72, 109, 0.07);
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox {
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #44486D;
}
.p-treetable .p-treetable-tbody > tr:focus {
  outline: 0.15rem solid #3b5f78;
  outline-offset: -0.15rem;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: #3b5f78;
  color: #ffffff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #ffffff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #ffffff;
}
.p-treetable.p-treetable-selectable .p-treetable-tbody > tr:not(.p-highlight):hover, .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: rgba(68, 72, 109, 0.07);
  color: #44486D;
}
.p-treetable.p-treetable-selectable .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler, .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: #44486D;
}
.p-treetable .p-column-resizer-helper {
  background: #3b5f78;
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: transparent;
}
.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.499625rem 0.499625rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.2855rem 0.2855rem;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.2145rem 0.2855rem;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 0.71375rem 0.71375rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 0.71375rem 0.71375rem;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 0.53625rem 0.71375rem;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}

.p-timeline .p-timeline-event-marker {
  border: 2px solid #3b5f78;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: rgba(68, 72, 109, 0.07);
}
.p-timeline .p-timeline-event-connector {
  background-color: rgba(68, 72, 109, 0.17);
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.714rem 1.143rem;
  border: 1px solid rgba(68, 72, 109, 0.17);
  color: #44486D;
  background: transparent;
  font-weight: 500;
  transition: box-shadow 0.2s;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: rgba(68, 72, 109, 0.07);
  border-color: rgba(68, 72, 109, 0.17);
  color: #44486D;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: rgba(68, 72, 109, 0.12);
  border-color: rgba(68, 72, 109, 0.17);
  color: #44486D;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: rgba(68, 72, 109, 0.17);
  background: rgba(68, 72, 109, 0.12);
  color: #44486D;
}
.p-accordion .p-accordion-content {
  padding: 1.143rem;
  border: 1px solid rgba(68, 72, 109, 0.17);
  background: transparent;
  color: #44486D;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.p-accordion .p-accordion-tab {
  margin-bottom: 0;
}
.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}
.p-accordion .p-accordion-tab .p-accordion-content {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-content {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.p-card {
  background: transparent;
  color: #44486D;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.p-card .p-card-body {
  padding: 0.857rem 1.143rem;
}
.p-card .p-card-title {
  font-size: 1.143rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #8A8EA6;
}
.p-card .p-card-content {
  padding: 1rem 0;
}
.p-card .p-card-footer {
  padding: 1rem 0 0 0;
}

.p-fieldset {
  border: 1px solid rgba(68, 72, 109, 0.17);
  background: transparent;
  color: #44486D;
}
.p-fieldset .p-fieldset-legend {
  padding: 0.714rem 1.143rem;
  border: 1px solid rgba(68, 72, 109, 0.17);
  color: #44486D;
  background: transparent;
  font-weight: 500;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 0.714rem 1.143rem;
  color: #44486D;
  transition: box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: rgba(68, 72, 109, 0.07);
  border-color: rgba(68, 72, 109, 0.17);
  color: #44486D;
}
.p-fieldset .p-fieldset-content {
  padding: 1.143rem;
}

.p-divider .p-divider-content {
  background-color: transparent;
}
.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}
.p-divider.p-divider-horizontal:before {
  border-top: 1px rgba(68, 72, 109, 0.17);
}
.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}
.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}
.p-divider.p-divider-vertical:before {
  border-left: 1px rgba(68, 72, 109, 0.17);
}
.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}

.p-panel .p-panel-header {
  border: 1px solid rgba(68, 72, 109, 0.17);
  padding: 0.714rem 1.143rem;
  background: transparent;
  color: #44486D;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-panel .p-panel-header .p-panel-title {
  font-weight: 500;
}
.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: #676B89;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #44486D;
  border-color: transparent;
  background: rgba(68, 72, 109, 0.07);
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.429rem 0.571rem;
}
.p-panel .p-panel-content {
  padding: 1.143rem;
  border: 1px solid rgba(68, 72, 109, 0.17);
  background: transparent;
  color: #44486D;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top: 0 none;
}
.p-panel .p-panel-footer {
  padding: 0.714rem 1.143rem;
  border: 1px solid rgba(68, 72, 109, 0.17);
  background: transparent;
  color: #44486D;
  border-top: 0 none;
}

.p-splitter {
  border: 1px solid rgba(68, 72, 109, 0.17);
  background: transparent;
  color: #44486D;
}
.p-splitter .p-splitter-gutter {
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  background: rgba(68, 72, 109, 0.17);
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: rgba(68, 72, 109, 0.17);
}
.p-splitter .p-splitter-gutter-resizing {
  background: rgba(68, 72, 109, 0.17);
}

.p-scrollpanel .p-scrollpanel-bar {
  background: transparent;
  border: 0 none;
}

.p-tabview .p-tabview-nav {
  background: transparent;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 0 0 2px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0.857rem;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid transparent;
  border-width: 0 0 2px 0;
  border-color: transparent transparent transparent transparent;
  background: transparent;
  color: #8A8EA6;
  padding: 0.571rem 0.429rem;
  font-weight: 500;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 none;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: transparent;
  border-color: #44486D;
  color: #44486D;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: transparent;
  border-color: #3b5f78;
  color: #3b5f78;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-nav-btn.p-link {
  background: transparent;
  color: #3b5f78;
  width: 2.286rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}
.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 none;
}
.p-tabview .p-tabview-panels {
  background: transparent;
  padding: 1.143rem 0;
  border: 0 none;
  color: #44486D;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.p-toolbar {
  background: transparent;
  border: 1px solid rgba(68, 72, 109, 0.17);
  padding: 0.714rem 1.143rem;
}
.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

.p-confirm-popup {
  background: #ececf9;
  color: #44486D;
  border: 0 none;
  box-shadow: 0px 18px 50px -4px rgba(68, 72, 109, 0.1);
}
.p-confirm-popup .p-confirm-popup-content {
  padding: 1.143rem;
}
.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0 1rem 1rem 1rem;
}
.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}
.p-confirm-popup:after {
  border: solid transparent;
  border-color: rgba(236, 236, 249, 0);
  border-bottom-color: #ececf9;
}
.p-confirm-popup:before {
  border: solid transparent;
  border-color: rgba(236, 236, 249, 0);
  border-bottom-color: #ececf9;
}
.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: #ececf9;
}
.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: #ececf9;
}
.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}

.p-dialog {
  box-shadow: 0px 18px 50px -4px rgba(68, 72, 109, 0.1);
  border: 0 none;
}
.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ececf9;
  color: #44486D;
  padding: 1.286rem 1.714rem;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 500;
  font-size: 1.143rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #676B89;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #44486D;
  border-color: transparent;
  background: rgba(68, 72, 109, 0.07);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: #ececf9;
  color: #44486D;
  padding: 0 1.714rem 1.714rem 1.714rem;
}
.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #ececf9;
  color: #44486D;
  padding: 0 1.714rem 1.714rem 1.714rem;
  text-align: right;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-overlaypanel {
  background: #ececf9;
  color: #44486D;
  border: 0 none;
  box-shadow: 0px 18px 50px -4px rgba(68, 72, 109, 0.1);
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 1.143rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: #3b5f78;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #35566c;
  color: #ffffff;
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(236, 236, 249, 0);
  border-bottom-color: #ececf9;
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(236, 236, 249, 0);
  border-bottom-color: #ececf9;
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ececf9;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #ececf9;
}

.p-sidebar {
  background: #ececf9;
  color: #44486D;
  border: 0 none;
  box-shadow: 0px 18px 50px -4px rgba(68, 72, 109, 0.1);
}
.p-sidebar .p-sidebar-header {
  padding: 0.714rem 1.143rem;
}
.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  width: 2rem;
  height: 2rem;
  color: #676B89;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
  color: #44486D;
  border-color: transparent;
  background: rgba(68, 72, 109, 0.07);
}
.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding-top: 0;
}
.p-sidebar .p-sidebar-content {
  padding: 1.143rem;
}

.p-tooltip .p-tooltip-text {
  background: #ececf9;
  color: #44486D;
  padding: 0.429rem 0.571rem;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #ececf9;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #ececf9;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #ececf9;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #ececf9;
}

.p-fileupload .p-fileupload-buttonbar {
  background: transparent;
  padding: 0.714rem 1.143rem;
  border: 1px solid rgba(68, 72, 109, 0.17);
  color: #44486D;
  border-bottom: 0 none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-content {
  background: transparent;
  padding: 2rem 1rem;
  border: 1px solid rgba(68, 72, 109, 0.17);
  color: #44486D;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.p-fileupload .p-progressbar {
  height: 0.25rem;
}
.p-fileupload .p-fileupload-row > div {
  padding: 0.429rem 0.571rem;
}
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-breadcrumb {
  background: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%);
  border: 1px solid rgba(68, 72, 109, 0.17);
  padding: 0.857rem;
}
.p-breadcrumb ul li .p-menuitem-link {
  transition: box-shadow 0.2s;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #676B89;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #676B89;
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #676B89;
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #676B89;
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #676B89;
}

.p-contextmenu {
  padding: 0.429rem;
  background: #ececf9;
  color: #676B89;
  border: 1px solid transparent;
  box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
  width: 21rem;
}
.p-contextmenu .p-menuitem-link {
  padding: 0.571rem 0.429rem;
  color: #676B89;
  border-radius: 0px;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #676B89;
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #676B89;
  margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #676B89;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.07);
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #44486D;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #44486D;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #44486D;
}
.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-contextmenu .p-submenu-list {
  padding: 0.429rem;
  background: #ececf9;
  border: 1px solid transparent;
  box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: rgba(68, 72, 109, 0.07);
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #44486D;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #44486D;
}
.p-contextmenu .p-menu-separator {
  border-top: 1px solid rgba(68, 72, 109, 0.17);
  margin: 0.25rem 0;
}
.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-dock .p-dock-list {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
}
.p-dock .p-dock-item {
  padding: 0.5rem;
}
.p-dock .p-dock-action {
  width: 4rem;
  height: 4rem;
}
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-next {
  margin: 0 0.9rem;
}
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-next {
  margin: 0 1.3rem;
}
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-current, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-current {
  margin: 0 1.5rem;
}
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-next {
  margin: 0.9rem 0;
}
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-next {
  margin: 1.3rem 0;
}
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-current, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-current {
  margin: 1.5rem 0;
}

@media screen and (max-width: 960px) {
  .p-dock.p-dock-top .p-dock-list-container, .p-dock.p-dock-bottom .p-dock-list-container {
    overflow-x: auto;
    width: 100%;
  }
  .p-dock.p-dock-top .p-dock-list-container .p-dock-list, .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
    margin: 0 auto;
  }
  .p-dock.p-dock-left .p-dock-list-container, .p-dock.p-dock-right .p-dock-list-container {
    overflow-y: auto;
    height: 100%;
  }
  .p-dock.p-dock-left .p-dock-list-container .p-dock-list, .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
    margin: auto 0;
  }
  .p-dock .p-dock-list .p-dock-item {
    transform: none;
    margin: 0;
  }
}
.p-megamenu {
  padding: 0.857rem;
  background: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%);
  color: #676B89;
  border: 1px solid rgba(68, 72, 109, 0.17);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.571rem 0.429rem;
  color: #676B89;
  border-radius: 0px;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #676B89;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #676B89;
  margin-right: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #676B89;
  margin-left: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.07);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #44486D;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #44486D;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #44486D;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.07);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #44486D;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #44486D;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #44486D;
}
.p-megamenu .p-menuitem-link {
  padding: 0.571rem 0.429rem;
  color: #676B89;
  border-radius: 0px;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #676B89;
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #676B89;
  margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #676B89;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.07);
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #44486D;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #44486D;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #44486D;
}
.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-megamenu .p-megamenu-panel {
  background: #ececf9;
  color: #676B89;
  border: 1px solid transparent;
  box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
}
.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.571rem 0.429rem;
  color: #8A8EA6;
  background: transparent;
  font-weight: 500;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-megamenu .p-megamenu-submenu {
  padding: 0.429rem;
  width: 21rem;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid rgba(68, 72, 109, 0.17);
  margin: 0.25rem 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: rgba(68, 72, 109, 0.07);
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #44486D;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #44486D;
}
.p-megamenu.p-megamenu-vertical {
  width: 21rem;
  padding: 0.429rem;
}

.p-menu {
  padding: 0.429rem;
  background: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%);
  color: #676B89;
  border: 1px solid rgba(68, 72, 109, 0.17);
  width: 21rem;
}
.p-menu .p-menuitem-link {
  padding: 0.571rem 0.429rem;
  color: #676B89;
  border-radius: 0px;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: #676B89;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #676B89;
  margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
  color: #676B89;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.07);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #44486D;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #44486D;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #44486D;
}
.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-menu.p-menu-overlay {
  background: #ececf9;
  border: 1px solid transparent;
  box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.571rem 0.429rem;
  color: #8A8EA6;
  background: transparent;
  font-weight: 500;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-menu .p-menu-separator {
  border-top: 1px solid rgba(68, 72, 109, 0.17);
  margin: 0.25rem 0;
}

.p-menubar {
  padding: 0.857rem;
  background: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%);
  color: #676B89;
  border: 1px solid rgba(68, 72, 109, 0.17);
}
.p-menubar .p-menuitem-link {
  padding: 0.571rem 0.429rem;
  color: #676B89;
  border-radius: 0px;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #676B89;
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: #676B89;
  margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
  color: #676B89;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.07);
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #44486D;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #44486D;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #44486D;
}
.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.571rem 0.429rem;
  color: #676B89;
  border-radius: 0px;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #676B89;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #676B89;
  margin-right: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #676B89;
  margin-left: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.07);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #44486D;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #44486D;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #44486D;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.07);
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #44486D;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #44486D;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #44486D;
}
.p-menubar .p-submenu-list {
  padding: 0.429rem;
  background: #ececf9;
  border: 1px solid transparent;
  box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
  width: 21rem;
}
.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid rgba(68, 72, 109, 0.17);
  margin: 0.25rem 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: rgba(68, 72, 109, 0.07);
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #44486D;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #44486D;
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: #676B89;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-menubar .p-menubar-button:hover {
    color: #44486D;
    background: rgba(68, 72, 109, 0.07);
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.429rem;
    background: #ececf9;
    border: 1px solid transparent;
    box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid rgba(68, 72, 109, 0.17);
    margin: 0.25rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.571rem 0.429rem;
    color: #676B89;
    border-radius: 0px;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #676B89;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #676B89;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #676B89;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.07);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #44486D;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #44486D;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #44486D;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 1.713rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 2.855rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 3.997rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 5.139rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 6.281rem;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 0.714rem 1.143rem;
  border: 1px solid rgba(68, 72, 109, 0.17);
  color: #44486D;
  background: transparent;
  font-weight: 500;
  transition: box-shadow 0.2s;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: rgba(68, 72, 109, 0.07);
  border-color: rgba(68, 72, 109, 0.17);
  color: #44486D;
}
.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: rgba(68, 72, 109, 0.12);
  border-color: rgba(68, 72, 109, 0.17);
  color: #44486D;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: rgba(68, 72, 109, 0.17);
  background: rgba(68, 72, 109, 0.12);
  color: #44486D;
}
.p-panelmenu .p-panelmenu-content {
  padding: 0.429rem;
  border: 1px solid rgba(68, 72, 109, 0.17);
  background: transparent;
  color: #44486D;
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.571rem 0.429rem;
  color: #676B89;
  border-radius: 0px;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: #676B89;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: #676B89;
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: #676B89;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.07);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #44486D;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #44486D;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #44486D;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.p-slidemenu {
  padding: 0.429rem;
  background: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%);
  color: #676B89;
  border: 1px solid rgba(68, 72, 109, 0.17);
  width: 21rem;
}
.p-slidemenu .p-menuitem-link {
  padding: 0.571rem 0.429rem;
  color: #676B89;
  border-radius: 0px;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #676B89;
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #676B89;
  margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #676B89;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.07);
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #44486D;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #44486D;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #44486D;
}
.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-slidemenu.p-slidemenu-overlay {
  background: #ececf9;
  border: 1px solid transparent;
  box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
}
.p-slidemenu .p-slidemenu-list {
  padding: 0.429rem;
  background: #ececf9;
  border: 1px solid transparent;
  box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: rgba(68, 72, 109, 0.07);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: #44486D;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: #44486D;
}
.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid rgba(68, 72, 109, 0.17);
  margin: 0.25rem 0;
}
.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
  padding: 0.571rem 0.429rem;
  color: #676B89;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: box-shadow 0.2s;
  background: transparent;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #8A8EA6;
  border: 0 none;
  background: transparent;
  min-width: 1.714rem;
  height: 1.714rem;
  line-height: 1.714rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 12px;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #8A8EA6;
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #3b5f78;
  color: #ffffff;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 400;
  color: #44486D;
}
.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid rgba(68, 72, 109, 0.17);
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -0.857rem;
}

.p-tabmenu .p-tabmenu-nav {
  background: transparent;
  border: 1px solid rgba(68, 72, 109, 0.17);
  border-width: 0 0 2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0.857rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid transparent;
  border-width: 0 0 2px 0;
  border-color: transparent transparent transparent transparent;
  background: transparent;
  color: #8A8EA6;
  padding: 0.571rem 0.429rem;
  font-weight: 500;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
  height: calc(100% + 2px);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 none;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: transparent;
  border-color: #44486D;
  color: #44486D;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: transparent;
  border-color: #3b5f78;
  color: #3b5f78;
}

.p-tieredmenu {
  padding: 0.429rem;
  background: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%);
  color: #676B89;
  border: 1px solid rgba(68, 72, 109, 0.17);
  width: 21rem;
}
.p-tieredmenu .p-menuitem-link {
  padding: 0.571rem 0.429rem;
  color: #676B89;
  border-radius: 0px;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #676B89;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #676B89;
  margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #676B89;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.07);
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #44486D;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #44486D;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #44486D;
}
.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: #ececf9;
  border: 1px solid transparent;
  box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
}
.p-tieredmenu .p-submenu-list {
  padding: 0.429rem;
  background: #ececf9;
  border: 1px solid transparent;
  box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: rgba(68, 72, 109, 0.07);
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #44486D;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #44486D;
}
.p-tieredmenu .p-menu-separator {
  border-top: 1px solid rgba(68, 72, 109, 0.17);
  margin: 0.25rem 0;
}
.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-inline-message {
  padding: 0.429rem 0.571rem;
  margin: 0;
}
.p-inline-message.p-inline-message-info {
  background: rgba(68, 72, 109, 0.07);
  border: solid #873EFE;
  border-width: 0 0 0 20px;
  color: #44486D;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #44486D;
}
.p-inline-message.p-inline-message-success {
  background: rgba(68, 72, 109, 0.07);
  border: solid #0BD18A;
  border-width: 0 0 0 20px;
  color: #44486D;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #44486D;
}
.p-inline-message.p-inline-message-warn {
  background: rgba(68, 72, 109, 0.07);
  border: solid #EEE500;
  border-width: 0 0 0 20px;
  color: #44486D;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #44486D;
}
.p-inline-message.p-inline-message-error {
  background: rgba(68, 72, 109, 0.07);
  border: solid #FC6161;
  border-width: 0 0 0 20px;
  color: #44486D;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #44486D;
}
.p-inline-message .p-inline-message-icon {
  font-size: 1.286rem;
  margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 1rem 0;
}
.p-message .p-message-wrapper {
  padding: 1.143rem 1.357rem;
}
.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-message.p-message-info {
  background: rgba(68, 72, 109, 0.07);
  border: solid #873EFE;
  border-width: 0 0 0 20px;
  color: #44486D;
}
.p-message.p-message-info .p-message-icon {
  color: #44486D;
}
.p-message.p-message-info .p-message-close {
  color: #44486D;
}
.p-message.p-message-success {
  background: rgba(68, 72, 109, 0.07);
  border: solid #0BD18A;
  border-width: 0 0 0 20px;
  color: #44486D;
}
.p-message.p-message-success .p-message-icon {
  color: #44486D;
}
.p-message.p-message-success .p-message-close {
  color: #44486D;
}
.p-message.p-message-warn {
  background: rgba(68, 72, 109, 0.07);
  border: solid #EEE500;
  border-width: 0 0 0 20px;
  color: #44486D;
}
.p-message.p-message-warn .p-message-icon {
  color: #44486D;
}
.p-message.p-message-warn .p-message-close {
  color: #44486D;
}
.p-message.p-message-error {
  background: rgba(68, 72, 109, 0.07);
  border: solid #FC6161;
  border-width: 0 0 0 20px;
  color: #44486D;
}
.p-message.p-message-error .p-message-icon {
  color: #44486D;
}
.p-message.p-message-error .p-message-close {
  color: #44486D;
}
.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 400;
}
.p-message .p-message-icon {
  font-size: 1.286rem;
  margin-right: 0.5rem;
}

.p-toast {
  opacity: 0.9;
}
.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: none;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 1.143rem 1.357rem;
  border-width: 0 0 0 20px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 1.286rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 400;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: 1.286rem;
  height: 1.286rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}
.p-toast .p-toast-message.p-toast-message-info {
  background: rgba(68, 72, 109, 0.07);
  border: solid #873EFE;
  border-width: 0 0 0 20px;
  color: #44486D;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #44486D;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: rgba(68, 72, 109, 0.07);
  border: solid #0BD18A;
  border-width: 0 0 0 20px;
  color: #44486D;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #44486D;
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: rgba(68, 72, 109, 0.07);
  border: solid #EEE500;
  border-width: 0 0 0 20px;
  color: #44486D;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #44486D;
}
.p-toast .p-toast-message.p-toast-message-error {
  background: rgba(68, 72, 109, 0.07);
  border: solid #FC6161;
  border-width: 0 0 0 20px;
  color: #44486D;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #44486D;
}

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: #44486D;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-close:hover {
  background: rgba(68, 72, 109, 0.15);
  color: #44486D;
}
.p-galleria .p-galleria-item-nav {
  background: transparent;
  color: #44486D;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0px;
  margin: 0 0.5rem;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.15);
  color: #44486D;
}
.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: transparent;
  padding: 1rem;
}
.p-galleria .p-galleria-indicators {
  padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: rgba(68, 72, 109, 0.12);
  width: 1.429rem;
  height: 0.286rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(68, 72, 109, 0.15);
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #3b5f78;
  color: #ffffff;
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: #676B89;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #44486D;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #3b5f78;
  color: #ffffff;
}
.p-galleria .p-galleria-thumbnail-container {
  background: #ececf9;
  padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: #44486D;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(68, 72, 109, 0.15);
  color: #44486D;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
  transition: box-shadow 0.2s;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}

.p-galleria-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-preview-indicator {
  background-color: transparent;
  color: #f8f9fa;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-image-preview-container:hover > .p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0.5);
}

.p-image-toolbar {
  padding: 1rem;
}

.p-image-action.p-link {
  color: #f8f9fa;
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-image-action.p-link:last-child {
  margin-right: 0;
}
.p-image-action.p-link:hover {
  color: #f8f9fa;
  background-color: rgba(255, 255, 255, 0.1);
}
.p-image-action.p-link i {
  font-size: 1.5rem;
}

.p-avatar {
  background-color: rgba(68, 72, 109, 0.17);
}
.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}
.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}
.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}
.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}

.p-avatar-group .p-avatar {
  border: 2px solid transparent;
}

.p-chip {
  background-color: rgba(68, 72, 109, 0.17);
  color: #44486D;
  border-radius: 16px;
  padding: 0 0.571rem;
}
.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.2145rem;
  margin-bottom: 0.2145rem;
}
.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}
.p-chip img {
  width: 1.929rem;
  height: 1.929rem;
  margin-left: -0.571rem;
  margin-right: 0.5rem;
}
.p-chip .p-chip-remove-icon {
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-left: 0.5rem;
}
.p-chip .p-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-scrolltop.p-link {
  background: rgba(0, 0, 0, 0.7);
}
.p-scrolltop.p-link:hover {
  background: rgba(0, 0, 0, 0.8);
}
.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: transparent;
}

.p-skeleton {
  background-color: rgba(68, 72, 109, 0.07);
}
.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-tag {
  background: #3b5f78;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  padding: 0 0.429rem;
}
.p-tag.p-tag-success {
  background-color: #0BD18A;
  color: #0A061A;
}
.p-tag.p-tag-info {
  background-color: #873EFE;
  color: #FFFFFF;
}
.p-tag.p-tag-warning {
  background-color: #EEE500;
  color: #44486D;
}
.p-tag.p-tag-danger {
  background-color: #FC6161;
  color: #FFFFFF;
}
.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 1rem;
}

.p-inplace .p-inplace-display {
  padding: 0.429rem 0.571rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: rgba(68, 72, 109, 0.07);
  color: #44486D;
}
.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}

.p-progressbar {
  border: 0 none;
  height: 0.571rem;
  background: rgba(68, 72, 109, 0.12);
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #3b5f78;
}
.p-progressbar .p-progressbar-label {
  color: #44486D;
  line-height: 0.571rem;
}

.p-terminal {
  background: transparent;
  color: #44486D;
  border: 1px solid rgba(68, 72, 109, 0.17);
  padding: 1.143rem;
}
.p-terminal .p-terminal-input {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.p-badge {
  background: #3b5f78;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  min-width: 1.429rem;
  height: 1.429rem;
  line-height: 1.429rem;
}
.p-badge.p-badge-secondary {
  background-color: rgba(68, 72, 109, 0.07);
  color: #44486D;
}
.p-badge.p-badge-success {
  background-color: #0BD18A;
  color: #0A061A;
}
.p-badge.p-badge-info {
  background-color: #873EFE;
  color: #FFFFFF;
}
.p-badge.p-badge-warning {
  background-color: #EEE500;
  color: #44486D;
}
.p-badge.p-badge-danger {
  background-color: #FC6161;
  color: #FFFFFF;
}
.p-badge.p-badge-lg {
  font-size: 1.5rem;
  min-width: 2.1435rem;
  height: 2.1435rem;
  line-height: 2.1435rem;
}
.p-badge.p-badge-xl {
  font-size: 2rem;
  min-width: 2.858rem;
  height: 2.858rem;
  line-height: 2.858rem;
}

.p-tag {
  background: #3b5f78;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  padding: 0 0.429rem;
}
.p-tag.p-tag-success {
  background-color: #0BD18A;
  color: #0A061A;
}
.p-tag.p-tag-info {
  background-color: #873EFE;
  color: #FFFFFF;
}
.p-tag.p-tag-warning {
  background-color: #EEE500;
  color: #44486D;
}
.p-tag.p-tag-danger {
  background-color: #FC6161;
  color: #FFFFFF;
}

.p-virtualscroller-loading-icon {
  font-size: 2rem;
}

.p-button:not(.p-button-outlined, .p-button-text, .p-button-link, .p-togglebutton) {
  box-shadow: 0px 0px 10px rgba(59, 95, 120, 0.25);
}

.p-buttonset .p-button {
  box-shadow: none;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  box-shadow: none;
}
.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: rgba(68, 72, 109, 0.07);
  color: #44486D;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(68, 72, 109, 0.04);
  color: #44486D;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(68, 72, 109, 0.16);
  color: #44486D;
}
.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
  color: #44486D;
}
.p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  color: #44486D;
}
.p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  color: #44486D;
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
  box-shadow: 0px 0px 10px rgba(135, 62, 254, 0.25);
}
.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
  box-shadow: none;
}
.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
  box-shadow: none;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  box-shadow: 0px 0px 10px rgba(11, 209, 138, 0.25);
}
.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
  box-shadow: none;
}
.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
  box-shadow: none;
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
  box-shadow: 0px 0px 10px rgba(238, 229, 0, 0.25);
}
.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  box-shadow: none;
}
.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
  box-shadow: none;
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
  box-shadow: 0px 0px 10px rgba(236, 77, 188, 0.25);
}
.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
  box-shadow: none;
}
.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
  box-shadow: none;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
  box-shadow: 0px 0px 10px rgba(252, 97, 97, 0.25);
}
.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  box-shadow: none;
}
.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
  box-shadow: none;
}

.p-datepicker table th > span {
  color: #8A8EA6;
  font-size: 12px;
  font-weight: 500;
}
.p-datepicker table td > span {
  background: rgba(68, 72, 109, 0.07);
}

.p-card .p-card-subtitle {
  font-size: 12px;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #676B89;
  color: #44486D;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #676B89;
}

.p-contextmenu .p-menuitem-link {
  border: 1px solid transparent;
}
.p-contextmenu .p-menuitem-link:focus {
  border-color: #8A8EA6;
}

.p-datatable .p-column-filter-menu-button {
  color: #8A8EA6;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: none;
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: linear-gradient(0deg, rgba(59, 95, 120, 0.1), rgba(59, 95, 120, 0.1)), #FFFFFF;
  color: #44486D;
}
.p-datatable.p-datatable-striped .p-datatable-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.p-datatable.p-datatable-striped .p-sortable-column {
  border-radius: 0;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr > td:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr > td:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: linear-gradient(0deg, rgba(59, 95, 120, 0.1), rgba(59, 95, 120, 0.1)), #FFFFFF;
  color: #44486D;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #44486D;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #44486D;
}
.p-datatable.p-datatable-scrollable .p-datatable-thead th {
  background: #ececf9;
}
.p-datatable.p-datatable-scrollable .p-datatable-tbody .p-frozen-column {
  background: #ececf9;
}
.p-datatable.p-datatable-scrollable .p-datatable-tbody .p-rowgroup-header {
  background: #ececf9;
}

.p-dialog {
  border-radius: 20px;
}
.p-dialog .p-dialog-header {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.p-dialog .p-dialog-footer {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.p-fieldset .p-fieldset-content {
  padding: 0.429rem;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #8A8EA6;
  color: #676B89;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #8A8EA6;
  color: #676B89;
}

.p-megamenu .p-megamenu-panel .p-megamenu-submenu-header {
  font-size: 0.857rem;
}
.p-megamenu .p-menuitem-link {
  border: 1px solid transparent;
}
.p-megamenu .p-menuitem-link:focus {
  border-color: #8A8EA6;
}

.p-menu .p-submenu-header {
  font-size: 0.857rem;
}
.p-menu .p-menuitem-link {
  border: 1px solid transparent;
}
.p-menu .p-menuitem-link:focus {
  border-color: #8A8EA6;
}

.p-menubar .p-menuitem-link {
  border: 1px solid transparent;
}
.p-menubar .p-menuitem-link:focus {
  border-color: #8A8EA6;
}

.p-orderlist .p-orderlist-list-container {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
  border-radius: 8px;
}
.p-orderlist .p-orderlist-header {
  border: 0 none;
  border-width: 0 0 0 0;
  padding: 1.143rem;
}
.p-orderlist .p-orderlist-filter-container {
  border: 0 none;
  border-width: 0 0 0 0;
}
.p-orderlist .p-orderlist-list {
  border: 0 none;
  border-width: 0 0 0 0;
  padding: 0.571rem;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.714rem 0.571rem;
  border: 1px solid transparent;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #44486D;
  background: linear-gradient(0deg, rgba(59, 95, 120, 0.1), rgba(59, 95, 120, 0.1)), #FFFFFF;
  border-color: #3b5f78;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: rgba(68, 72, 109, 0.12);
  border-color: transparent;
  color: #44486D;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-top: 0;
  padding-bottom: 0;
}

.p-panelmenu .p-panelmenu-header > a {
  color: #8A8EA6;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  border: 1px solid transparent;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  border-color: #8A8EA6;
}

.p-password .p-password-panel {
  background: #ececf9;
}

.p-picklist .p-picklist-list-wrapper {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
  border-radius: 8px;
}
.p-picklist .p-picklist-header {
  border: 0 none;
  border-width: 0 0 0 0;
  padding: 1.143rem;
}
.p-picklist .p-picklist-filter-container {
  border: 0 none;
  border-width: 0 0 0 0;
}
.p-picklist .p-picklist-list {
  border: 0 none;
  border-width: 0 0 0 0;
  padding: 0.571rem;
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.714rem 0.571rem;
  border: 1px solid transparent;
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #44486D;
  background: linear-gradient(0deg, rgba(59, 95, 120, 0.1), rgba(59, 95, 120, 0.1)), #FFFFFF;
  border-color: #3b5f78;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #676B89;
}

.p-sidebar.p-sidebar-full {
  border-radius: 0;
}
.p-sidebar.p-sidebar-top {
  border-radius: 0 0 40px 40px;
}
.p-sidebar.p-sidebar-left {
  border-radius: 0 40px 40px 0;
}
.p-sidebar.p-sidebar-right {
  border-radius: 40px 0 0 40px;
}
.p-sidebar.p-sidebar-bottom {
  border-radius: 40px 40px 0 0;
}

.p-slidemenu .p-menuitem-link {
  border: 1px solid transparent;
}
.p-slidemenu .p-menuitem-link:focus {
  border-color: #8A8EA6;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  background: rgba(68, 72, 109, 0.12);
}
.p-steps .p-steps-item .p-menuitem-link:hover {
  cursor: pointer;
}
.p-steps .p-steps-item .p-menuitem-link:hover .p-steps-number {
  background: rgba(68, 72, 109, 0.17);
  color: #44486D;
}
.p-steps .p-steps-item .p-menuitem-link:hover .p-steps-title {
  color: #44486D;
}
.p-steps .p-steps-item.p-highlight .p-menuitem-link .p-steps-number {
  background: #3b5f78;
  color: #ffffff;
  box-shadow: 0px 0px 10px rgba(59, 95, 120, 0.25);
}
.p-steps .p-steps-item.p-highlight .p-menuitem-link .p-steps-title {
  color: #44486D;
}
.p-steps .p-steps-item.p-highlight .p-menuitem-link:hover {
  cursor: pointer;
}
.p-steps .p-steps-item.p-highlight .p-menuitem-link:hover .p-steps-number {
  background: #3b5f78;
  color: #ffffff;
}
.p-steps .p-steps-item.p-highlight .p-menuitem-link:hover .p-steps-title {
  color: #44486D;
}

.p-tieredmenu .p-menuitem-link {
  border: 1px solid transparent;
}
.p-tieredmenu .p-menuitem-link:focus {
  border-color: #8A8EA6;
}

.p-toast .p-toast-message-content {
  background: #ececf9;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: linear-gradient(0deg, rgba(59, 95, 120, 0.1), rgba(59, 95, 120, 0.1)), #FFFFFF;
  color: #44486D;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #44486D;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: #44486D;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  background: #ececf9;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background-color: #ececf9;
  color: inherit;
}

.p-treetable .p-column-filter-menu-button {
  color: #8A8EA6;
}
.p-treetable .p-sortable-column:focus {
  box-shadow: none;
  outline: 0 none;
}
.p-treetable .p-treetable-tbody > tr > td:first-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.p-treetable .p-treetable-tbody > tr > td:last-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: linear-gradient(0deg, rgba(59, 95, 120, 0.1), rgba(59, 95, 120, 0.1)), #FFFFFF;
  color: #44486D;
}

/* Add your customizations of the theme here */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #e0e0e0;
}

::-webkit-scrollbar-track {
  background-color: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background-color: #c6c6c6;
}

body {
  margin: 0;
  line-height: 1.5;
  color: #525252;
}

body.landing-body {
  background-color: #fff;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin-top: 0;
  margin-bottom: 20px;
}

input,
textarea,
button,
select {
  outline: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  outline: none;
  text-decoration: none;
}

body .ui-widget,
body .ui-widget .ui-widget {
  font-family: inherit;
}

th,
td {
  vertical-align: top;
  text-align: left;
}

ol,
ul {
  list-style: none;
}

.ci {
  speak: none;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
  -moz-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
}

/**
 * Container
 */
.sv-search-box {
  position: relative;
}

/**
 * input
 */
.sv-search-box__input {
  outline: 2px solid transparent;
  outline-offset: -2px;
  background-color: #f4f4f4;
  color: #161616;
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  border: none;
  width: 100%;
  border-bottom: 1px solid #8d8d8d;
  padding: 0 40px;
}
.sv-search-box__input::-moz-placeholder {
  color: #8d8d8d;
  opacity: 1;
}
.sv-search-box__input:-ms-input-placeholder {
  color: #8d8d8d;
}
.sv-search-box__input::-webkit-input-placeholder {
  color: #8d8d8d;
}
.sv-search-box__input:focus {
  outline-color: #3b5f78;
}

/**
 * input
 */
.sv-search-box__search-icon,
.sv-search-box__clear-icon,
.sv-search-box__adv-toggle {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid transparent;
  height: 100%;
  top: 0;
  color: #393939;
}

.sv-search-box__search-icon {
  pointer-events: none;
}

.sv-search-box__clear-icon,
.sv-search-box__adv-toggle {
  cursor: pointer;
  outline: 2px solid transparent;
  outline-offset: -2px;
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), border 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.sv-search-box__clear-icon:after,
.sv-search-box__adv-toggle:after {
  content: "";
  position: absolute;
  height: calc(100% - 3px);
  border-left: 2px solid #f4f4f4;
  top: 2px;
  width: 100%;
  transition: border 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.sv-search-box__clear-icon:hover,
.sv-search-box__adv-toggle:hover {
  background-color: #e5e5e5;
  border-bottom-color: #8d8d8d;
}
.sv-search-box__clear-icon:hover:after,
.sv-search-box__adv-toggle:hover:after {
  border-left-color: #e5e5e5;
}
.sv-search-box__clear-icon:active,
.sv-search-box__adv-toggle:active {
  background-color: #e0e0e0;
}
.sv-search-box__clear-icon:active, .sv-search-box__clear-icon:focus,
.sv-search-box__adv-toggle:active,
.sv-search-box__adv-toggle:focus {
  outline-color: #3b5f78;
}
.sv-search-box__clear-icon:active:after, .sv-search-box__clear-icon:focus:after,
.sv-search-box__adv-toggle:active:after,
.sv-search-box__adv-toggle:focus:after {
  border-left-color: #3b5f78;
}

.sv-search-box__clear-icon {
  visibility: hidden;
  opacity: 0;
}

.sv-search-box__input:focus ~ .sv-search-box__clear-icon:hover,
.sv-search-box__input:focus ~ .sv-search-box__adv-toggle:hover {
  outline-color: #3b5f78;
}

.sv-search-box.-show-clear .sv-search-box__clear-icon {
  visibility: visible;
  opacity: 1;
}

.sv-search-box.-has-search-adv .sv-search-box__clear-icon:after {
  border-right: 2px solid #f4f4f4;
}
.sv-search-box.-has-search-adv .sv-search-box__clear-icon:hover:after {
  border-right-color: #e5e5e5;
}
.sv-search-box.-has-search-adv .sv-search-box__clear-icon:active:after, .sv-search-box.-has-search-adv .sv-search-box__clear-icon:focus:after {
  border-right-color: #3b5f78;
}
.sv-search-box.-has-search-adv .sv-search-box__adv-toggle {
  right: 0;
}

/**
 * Size
 */
.sv-search-box.-size-small .sv-search-box__input {
  font-size: 14px;
  height: 32px;
  line-height: 18px;
  letter-spacing: 0.16px;
}
.sv-search-box.-size-small .sv-search-box__search-icon,
.sv-search-box.-size-small .sv-search-box__clear-icon,
.sv-search-box.-size-small .sv-search-box__adv-toggle {
  width: 32px;
  font-size: 16px;
}
.sv-search-box.-size-small .sv-search-box__search-icon {
  left: 4px;
}
.sv-search-box.-size-small .sv-search-box__clear-icon {
  right: 0;
}
.sv-search-box.-size-small.-has-search-adv.-show-clear .sv-search-box__input {
  padding-right: 72px;
}
.sv-search-box.-size-small.-has-search-adv .sv-search-box__clear-icon {
  right: 32px;
}

.sv-search-box.-size-large .sv-search-box__input {
  font-size: 16px;
  height: 48px;
  line-height: 22px;
  padding: 0 64px 0 48px;
}
.sv-search-box.-size-large .sv-search-box__search-icon,
.sv-search-box.-size-large .sv-search-box__clear-icon,
.sv-search-box.-size-large .sv-search-box__adv-toggle {
  width: 48px;
  font-size: 20px;
}
.sv-search-box.-size-large .sv-search-box__search-icon {
  left: 0;
}
.sv-search-box.-size-large .sv-search-box__clear-icon {
  right: 0;
}
.sv-search-box.-size-large.-has-search-adv .sv-search-box__input {
  padding-right: 112px;
}
.sv-search-box.-size-large.-has-search-adv .sv-search-box__clear-icon {
  right: 48px;
}

/**
 * Disabled
 */
.sv-search-box.-disabled .sv-search-box__input,
.sv-search-box.-disabled .sv-search-box__search-icon,
.sv-search-box.-disabled .sv-search-box__clear-icon,
.sv-search-box.-disabled .sv-search-box__adv-toggle {
  cursor: not-allowed;
  color: #c6c6c6;
}
.sv-search-box.-disabled .sv-search-box__input {
  border-bottom-color: #f4f4f4;
}
.sv-search-box.-disabled .sv-search-box__input::-moz-placeholder {
  color: #c6c6c6;
  opacity: 1;
}
.sv-search-box.-disabled .sv-search-box__input:-ms-input-placeholder {
  color: #c6c6c6;
}
.sv-search-box.-disabled .sv-search-box__input::-webkit-input-placeholder {
  color: #c6c6c6;
}
.sv-search-box.-disabled .sv-search-box__clear-icon,
.sv-search-box.-disabled .sv-search-box__adv-toggle {
  background-color: transparent;
  border-color: transparent;
  outline: none;
}
.sv-search-box.-disabled .sv-search-box__clear-icon:after,
.sv-search-box.-disabled .sv-search-box__adv-toggle:after {
  display: none;
}

/*------------------------------------
  CUSTOM OVERLAY
//------------------------------------*/
.sv-custom-overlay-panel {
  margin-top: 0;
}

.sv-custom-overlay-panel-wrapper {
  position: relative;
  z-index: 10;
}

.sv-custom-overlay-panel {
  position: absolute;
  width: 200px;
  z-index: 10;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}
.sv-custom-overlay-panel.-large-size {
  width: 250px;
}
.sv-custom-overlay-panel.-top-left {
  bottom: 100%;
  right: 0;
}
.sv-custom-overlay-panel.-top-right {
  bottom: 100%;
  left: 0;
}
.sv-custom-overlay-panel.-bottom-left {
  top: 100%;
  right: 0;
}
.sv-custom-overlay-panel.-bottom-right {
  top: 100%;
  left: 0;
}

/*------------------------------------
  OVERLAY MENU
//------------------------------------*/
.sv-overlay-menu.ui-overlaypanel {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  border: 0;
  margin-top: 4px;
}
.sv-overlay-menu.ui-overlaypanel:before, .sv-overlay-menu.ui-overlaypanel:after {
  display: none;
}
.sv-overlay-menu.ui-overlaypanel .ui-overlaypanel-content {
  padding: 0;
}

/**
 * Style 1
 */
.sv-overlay-menu.style-1 .sv-overlay-menu__item {
  padding: 8px 28px;
  cursor: pointer;
}
.sv-overlay-menu.style-1 .sv-overlay-menu__item:hover {
  background: #e0e0e0;
}

/**
 * Style 2
 */
.sv-overlay-menu.style-2 {
  width: 220px;
  background: #f4f4f4;
}
.sv-overlay-menu.style-2 .sv-overlay-menu__title {
  color: #8d8d8d;
  padding: 8px;
  display: flex;
  align-items: center;
}
.sv-overlay-menu.style-2 .sv-overlay-menu__clear-btn {
  padding: 2px 24px 2px 8px;
  margin-right: 8px;
  display: block;
  font-size: 12px;
  line-height: 20px;
  border-radius: 40px;
  background: #3b5f78;
  color: #fff;
  position: relative;
  cursor: pointer;
}
.sv-overlay-menu.style-2 .sv-overlay-menu__clear-icon {
  position: absolute;
  font-size: 14px;
  z-index: 1;
  height: 100%;
  width: 20px;
  top: 0;
  right: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sv-overlay-menu.style-2 .sv-overlay-menu__item-wrapper {
  padding: 0 16px 10px 16px;
}
.sv-overlay-menu.style-2 .sv-overlay-menu__item-wrapper:hover {
  padding-bottom: 11px;
  margin-bottom: -1px;
  background: #e5e5e5;
  color: #8d8d8d;
}
.sv-overlay-menu.style-2 .sv-overlay-menu__item {
  border-top: 1px solid #dde1e6;
  padding-top: 9px;
  color: #525252;
}
.sv-overlay-menu.style-2 .sv-overlay-menu__item-wrapper.-activated {
  background: #e0e0e0;
  color: #8d8d8d;
}
.sv-overlay-menu.style-2 .sv-overlay-menu__item-wrapper.-activated:hover {
  background: #cacaca;
}

/*------------------------------------
  TOPBAR
//------------------------------------*/
.sv-topbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #3b5f78;
  z-index: 999;
}

.sv-topbar__content-container {
  display: flex;
}

/**
* Button
*/
.sv-topbar-btn {
  font-size: 20px;
  color: #fff;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.sv-topbar-btn:not(.-highlight) {
  -webkit-transition: background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -o-transition: background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.sv-topbar-btn:not(.-highlight):hover, .sv-topbar-btn:not(.-highlight).-activated {
  background-color: #4b5d73;
}
.sv-topbar-btn.-highlight {
  background-color: #ffa22b;
  color: #3b5f78;
}

/**
* Left content
*/
.sv-topbar__left-content {
  display: flex;
  align-items: center;
  height: 48px;
}

.sv-topbar-logo-wrapper {
  display: block;
  margin: 0 8px;
}

.sv-topbar-logo {
  display: block;
  width: 32px;
  height: 32px;
}

.sv-topbar-info {
  font-size: 16px;
  color: #fff;
}

/**
* Right content
*/
.sv-topbar__right-content {
  flex: 5 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sv-topbar-subsystems-overlay {
  display: flex;
  flex-wrap: wrap;
  width: 320px;
}

.sv-topbar-subsystems-item {
  cursor: pointer;
  width: 50%;
  text-align: center;
  padding: 15px;
  -webkit-transition: background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -o-transition: background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  border-bottom: 1px solid #e0e0e0;
}
.sv-topbar-subsystems-item:nth-last-child(-n+2) {
  border-bottom: 0;
}
.sv-topbar-subsystems-item:nth-child(2n+1) {
  border-right: 1px solid #e0e0e0;
}
.sv-topbar-subsystems-item__icon {
  color: #3b5f78;
  font-size: 48px;
  line-height: 1;
  margin-bottom: 12px;
}
.sv-topbar-subsystems-item__subtitle, .sv-topbar-subsystems-item__title {
  color: #393939;
}
.sv-topbar-subsystems-item__title {
  font-weight: 700;
}
.sv-topbar-subsystems-item:hover {
  background-color: #e5e5e5;
}

.sv-topbar-user-setting-overlay {
  width: 260px;
  color: inherit;
}

.sv-topbar-user-info-wrapper {
  padding-top: 22px;
  padding-bottom: 22px;
}

.sv-topbar-user-info {
  position: relative;
  padding-left: 74px;
  padding-right: 16px;
}
.sv-topbar-user-info__avatar {
  border-radius: 100%;
  width: 44px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  margin: auto 0;
}
.sv-topbar-user-info__name {
  margin-bottom: 4px;
  color: #393939;
}
.sv-topbar-user-info__role {
  color: #6f6f6f;
}

.sv-topbar-user-setting-action-item {
  display: block;
  border-top: 1px solid #e0e0e0;
  padding: 14px 16px 14px 55px;
  line-height: 20px;
  color: inherit;
  background-color: #f4f4f4;
  position: relative;
  -webkit-transition: background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -o-transition: background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.sv-topbar-user-setting-action-item:hover {
  background-color: #e5e5e5;
}
.sv-topbar-user-setting-action-item__icon {
  color: #3b5f78;
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sv-topbar-user-setting-action-item__title {
  font-weight: bold;
}

/*------------------------------------
RiGHT SIDEBAR
//------------------------------------*/
.sv-right-sidebar-area {
  position: fixed;
  right: 0;
  height: calc(100vh - 48px);
  top: 48px;
  max-width: 320px;
  width: 100%;
  background-color: #fff;
  box-shadow: -2px 1px 4px 0 #e0e0e0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  -webkit-transition: right 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -o-transition: right 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: right 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.sv-right-sidebar-area.-show {
  right: 0;
}
.sv-right-sidebar-area.-hide {
  right: -340px;
}

.sv-right-sidebar-tabs-container {
  display: flex;
}

.sv-right-sidebar-tab-item {
  flex: 1 1;
  border-bottom: 2px solid #e0e0e0;
  cursor: pointer;
  line-height: 20px;
  padding: 10px 16px;
  -webkit-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -o-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.sv-right-sidebar-tab-item:hover {
  color: #161616;
  border-color: #c6c6c6;
}
.sv-right-sidebar-tab-item.-activated {
  font-weight: 700;
  color: #161616;
  border-color: #3b5f78;
}
.sv-right-sidebar-tab-item + .sv-right-sidebar-tab-item {
  margin-left: 2px;
}

.sv-right-sidebar-panel-container {
  overflow-y: auto;
}

/*------------------------------------
  LEFT MENU AREA
//------------------------------------*/
.sv-page-menu-area {
  padding-top: 48px;
  position: fixed;
  z-index: 99;
  height: 100vh;
  overflow: hidden;
  display: flex;
  width: 280px;
  flex-direction: column;
  top: 0;
  left: 0;
  border-right: 1px solid #dde1e6;
  background-color: #fff;
}

/*------------------------------------
  SEARCH BOX
//------------------------------------*/
.sv-main-menu-search-box-container {
  border-bottom: 1px solid #dde1e6;
}

.sv-main-menu-search-box {
  position: relative;
}
.sv-main-menu-search-box__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 100%;
  z-index: 2;
  color: #3b5f78;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.sv-main-menu-search-box__input {
  height: 48px;
  display: block;
}

.sv-main-menu-search-box__input .p-autocomplete {
  display: block;
  width: 100%;
  height: 100%;
}
.sv-main-menu-search-box__input .p-inputtext.p-inputtext {
  padding-left: 56px;
  padding-right: 40px;
  width: 100%;
  height: 100%;
  outline: none;
  border: 0;
  background-color: #f4f4f4;
}
.sv-main-menu-search-box__input .p-autocomplete-panel {
  border-radius: 0;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 4px 0 #e0e0e0;
}
.sv-main-menu-search-box__input .p-autocomplete-list-item.p-autocomplete-list-item.p-autocomplete-list-item {
  color: inherit;
  line-height: 20px;
  padding: 7px 16px;
}
.sv-main-menu-search-box__input .p-autocomplete-list-item.p-autocomplete-list-item.p-autocomplete-list-item.p-state-highlight {
  color: inherit;
  background-color: #e0e0e0;
}
.sv-main-menu-search-box__input .p-autocomplete-list-item.p-autocomplete-list-item.p-autocomplete-list-item mark {
  background: transparent;
  font-weight: bold;
  color: #3b5f78;
}

/*------------------------------------
  MENU
//------------------------------------*/
.sv-page-menu-wrapper {
  flex: 1 1;
  overflow: hidden;
}
.sv-page-menu-wrapper__overflow-panel {
  width: 279px;
  height: 100%;
  overflow-y: auto;
}

.sv-page-menu,
.sv-page-submenu {
  padding: 0;
  margin: 0;
  list-style: none;
}

/*ul*/
.sv-page-submenu {
  overflow: hidden;
}

/*a*/
.sv-page-menu-item-link {
  color: inherit;
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 20px;
}
.sv-page-menu-item-link__label {
  display: inline-block;
}

/**
 * Menu level 0 (Category)
 */
.sv-page-menu-item--level-0 > .sv-page-menu-item-link {
  background-color: #e0e0e0;
  text-transform: uppercase;
  font-weight: 700;
  color: #393939;
  padding: 10px 16px;
}

/**
 * Menu level 1
 */
.sv-page-submenu--level-1 {
  height: auto !important;
}

.sv-page-submenu--level-1 .sv-page-menu-item > .sv-page-menu-item-link {
  padding-left: 56px;
  padding-right: 16px;
  -webkit-transition: all 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -o-transition: all 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.sv-page-submenu--level-1 .sv-page-menu-item > .sv-page-menu-item-link .sv-page-menu-item-link__toggle-icon {
  display: none;
}
.sv-page-submenu--level-1 .sv-page-menu-item:not(.-activated) > .sv-page-menu-item-link:hover {
  color: #393939;
  background-color: #e5e5e5;
}
.sv-page-submenu--level-1 .sv-page-menu-item.-activated > .sv-page-menu-item-link {
  color: #393939;
  background-color: #e0e0e0;
}
.sv-page-submenu--level-1 .sv-page-menu-item.-has-submenu.sv-page-submenu {
  display: none;
}
.sv-page-submenu--level-1 .sv-page-menu-item.-has-submenu > .sv-page-menu-item-link {
  padding-right: 40px;
}
.sv-page-submenu--level-1 .sv-page-menu-item.-has-submenu > .sv-page-menu-item-link .sv-page-menu-item-link__toggle-icon {
  position: absolute;
  width: 40px;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.sv-page-submenu--level-1 .sv-page-menu-item.-has-submenu > .sv-page-menu-item-link .sv-page-menu-item-link__toggle-icon:before {
  -webkit-transition: all 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -o-transition: all 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.sv-page-submenu--level-1 .sv-page-menu-item.-has-submenu.-activated > .sv-page-menu-item-link .sv-page-menu-item-link__toggle-icon:before {
  transform: rotate(90deg);
}

.sv-page-menu-item--level-1 > .sv-page-menu-item-link {
  font-weight: 700;
  padding-top: 14px;
  padding-bottom: 14px;
}
.sv-page-menu-item--level-1 > .sv-page-menu-item-link.active {
  background-color: #ffe8d1;
}
.sv-page-menu-item--level-1 > .sv-page-menu-item-link .sv-page-menu-item-link__main-icon {
  position: absolute;
  width: 48px;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #3b5f78;
  -webkit-transition: all 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -o-transition: all 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.sv-page-menu-item--level-1.-activated > .sv-page-menu-item-link .sv-page-menu-item-link__main-icon {
  color: #fff;
  background-color: #3b5f78;
}

/**
 * Menu level 2
 */
.sv-page-submenu--level-2 .sv-page-menu-item-link {
  padding-top: 10px;
  padding-bottom: 10px;
}

.sv-page-submenu--level-2 .sv-page-menu-item:not(.has-submenu) > .sv-page-menu-item-link {
  padding-left: 52px;
  border-left: 4px solid transparent;
}
.sv-page-submenu--level-2 .sv-page-menu-item:not(.has-submenu) > .sv-page-menu-item-link.active {
  background-color: #ffe8d1;
}
.sv-page-submenu--level-2 .sv-page-menu-item:not(.has-submenu).-activated > .sv-page-menu-item-link {
  border-color: #3b5f78;
}

.sv-page-menu-item--level-2 > .sv-page-menu-item-link {
  font-weight: 700;
}
.sv-page-menu-item--level-2.-has-submenu.-activated > .sv-page-menu-item-link {
  background-color: transparent;
}

.sv-page-menu-item--level-3 > .sv-page-menu-item-link .sv-page-menu-item-link__label {
  position: relative;
  padding-left: 12px;
}
.sv-page-menu-item--level-3 > .sv-page-menu-item-link .sv-page-menu-item-link__label:before {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #3b5f78;
  position: absolute;
  left: 0;
  top: 8px;
}

.-activated > .sv-page-submenu.sv-page-submenu.sv-page-submenu {
  height: auto !important;
}

.-has-submenu > .sv-page-submenu {
  height: 0px;
}

.sv-notification-group-label {
  line-height: 20px;
  padding: 10px 16px;
  font-weight: bold;
  background-color: #f4f4f4;
}

.sv-notification-item {
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 16px 12px 56px;
  position: relative;
  cursor: pointer;
  -webkit-transition: background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -o-transition: background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.sv-notification-item__icon {
  background-color: #f4f4f4;
  width: 32px;
  height: 32px;
  color: #3b5f78;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 16px;
  background: transparent;
}
.sv-notification-item__title {
  color: #393939;
  margin-bottom: 8px;
}
.sv-notification-item.-unseen .sv-notification-item__title {
  font-weight: 700;
}
.sv-notification-item:hover {
  background-color: #e5e5e5;
}

/*------------------------------------
  GENERAL
//------------------------------------*/
.sv-page-content-area-wrapper {
  position: relative;
  flex: 1 1;
}

.sv-page-content-area {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

/*------------------------------------
  HEADER
//------------------------------------*/
.sv-page-content-area__header {
  padding: 0 16px;
}

.sv-page-breadcrumb-area {
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

/**
 * Page title
 */
.sv-page-title-area {
  padding-top: 24px;
  padding-bottom: 8px;
  display: flex;
}
.sv-page-title-area__left-block, .sv-page-title-area__right-block {
  margin-bottom: 16px;
}
.sv-page-title-area__left-block {
  flex: 1 1;
  min-width: 0;
  padding-right: 16px;
  display: flex;
}

.sv-page-title {
  font-size: 20px;
  line-height: 32px;
  color: #262626;
  position: relative;
  padding-right: 24px;
  margin-bottom: 0;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
}
.sv-page-title__helper {
  position: absolute;
  color: #0f62fe;
  font-size: 16px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  top: 4px;
  right: 0;
  cursor: pointer;
}

.sv-page-content-area__header .sv-search-box {
  width: 320px;
}

/*------------------------------------
  BODY
//------------------------------------*/
.sv-page-content-area__body {
  flex: 1 1;
  padding: 16px;
  overflow: hidden;
}

.sv-page-content-area__header + .sv-page-content-area__body {
  padding-top: 0;
}

.sv-page-container {
  height: 100vh;
}

.sv-page-body-area {
  padding-top: 48px;
  height: 100%;
  display: flex;
}

.sv-page-body-area {
  -webkit-transition: padding 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -o-transition: padding 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: padding 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.sv-page-menu-area, .sv-topbar {
  -webkit-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -o-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.sv-page-container.-expansion-mode-inactive .sv-topbar {
  top: 0;
}

.sv-page-container.-expansion-mode-active .sv-topbar {
  top: -48px;
}

@media (min-width: 1025px) {
  .sv-page-container.-menu-expanded-in-desktop .sv-topbar-menu-collapse-toggle .sv-topbar-btn__icon.-state-inactive {
    display: none;
  }
  .sv-page-container.-menu-expanded-in-desktop .sv-page-body-area {
    padding-left: 280px;
  }
  .sv-page-container.-menu-expanded-in-desktop .sv-page-menu-area {
    width: 280px;
  }
  .sv-page-container.-menu-collapsed-in-desktop .sv-topbar-menu-collapse-toggle .sv-topbar-btn__icon.-state-active {
    display: none;
  }
  .sv-page-container.-menu-collapsed-in-desktop .sv-page-body-area {
    padding-left: 48px;
  }
  .sv-page-container.-menu-collapsed-in-desktop .sv-page-menu-area {
    width: 48px;
  }
  .sv-page-container.-menu-collapsed-in-desktop .sv-page-menu-area:not(:hover) .sv-page-menu-item--level-0 > .sv-page-menu-item-link {
    display: none;
  }
  .sv-page-container.-menu-collapsed-in-desktop .sv-page-menu-area:not(:hover) .sv-page-submenu--level-2 {
    display: none;
  }
  .sv-page-container.-menu-collapsed-in-desktop .sv-page-menu-area:not(:hover) .ui-autocomplete-panel {
    display: none;
  }
  .sv-page-container.-menu-collapsed-in-desktop.-expansion-mode-inactive .sv-page-menu-area:hover {
    width: 280px;
  }
  .sv-page-container.-expansion-mode-inactive .sv-page-menu-area {
    left: 0;
  }
  .sv-page-container.-expansion-mode-active .sv-page-body-area {
    padding: 0;
  }
  .sv-page-container.-expansion-mode-active .sv-page-menu-area {
    left: -280px;
  }
}
@media (max-width: 1024px) {
  .sv-page-content-area {
    width: 100vw;
  }
  .sv-page-container.-menu-expanded-in-small-device .sv-topbar-menu-collapse-toggle .sv-topbar-btn__icon.-state-inactive {
    display: none;
  }
  .sv-page-container.-menu-expanded-in-small-device .sv-page-body-area {
    padding-left: 280px;
  }
  .sv-page-container.-menu-expanded-in-small-device .sv-page-menu-area {
    width: 280px;
  }
  .sv-page-container.-menu-collapsed-in-small-device .sv-topbar-menu-collapse-toggle .sv-topbar-btn__icon.-state-active {
    display: none;
  }
  .sv-page-container.-menu-collapsed-in-small-device .sv-page-menu-area {
    left: -280px;
  }
  .sv-page-container.-expansion-mode-inactive.-menu-expanded-in-small-device .sv-page-menu-area {
    left: 0;
  }
  .sv-page-container.-expansion-mode-active .sv-page-body-area {
    padding: 0;
  }
  .sv-page-container.-expansion-mode-active .sv-page-menu-area {
    left: -280px;
  }
}
.sv-page-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #424242;
  opacity: 0.7;
  top: 0;
  left: 0;
  z-index: 990;
  display: none;
}

/*------------------------------------
  GENERAL
//------------------------------------*/
.sv-data-view-area {
  display: flex;
}
.sv-data-view-area, .sv-data-view-area__left-block, .sv-data-view-area__main-block {
  height: 100%;
}

/*------------------------------------
  LEFT BLOCK
//------------------------------------*/
.sv-data-view-area__left-block {
  flex: 1 1;
  overflow: hidden;
  min-width: 320px;
  padding-right: 24px;
}

.sv-data-tree-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sv-data-tree-content-block {
  position: relative;
  overflow: hidden;
  flex: 1 1;
}
.sv-data-tree-content-block .sv-tree {
  overflow: auto;
  display: block;
  height: 100%;
  border: 0px;
  padding: 0px;
}
.sv-data-tree-content-block .sv-tree .p-tree {
  padding: 0px;
}

.sv-data-tree-tool-toggle {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 0px;
}

.sv-dropdown .p-dropdown-item {
  white-space: pre-wrap;
}

/*------------------------------------
  MAIN BLOCK
//------------------------------------*/
.sv-data-view-area__main-block {
  display: flex;
  flex-direction: column;
  flex: 5 1;
  overflow: hidden;
}
.sv-data-view-area__main-block .sv-table-wrapper {
  flex: 1 1;
}

.sv-data-view-main-action-area,
.sv-data-view-record-action-area {
  display: flex;
}
.sv-data-view-main-action-area__left-block, .sv-data-view-main-action-area__right-block,
.sv-data-view-record-action-area__left-block,
.sv-data-view-record-action-area__right-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.sv-data-view-main-action-area__left-block .sv-btn,
.sv-data-view-record-action-area__left-block .sv-btn {
  margin-right: 8px;
  margin-bottom: 8px;
}
.sv-data-view-main-action-area__right-block,
.sv-data-view-record-action-area__right-block {
  justify-content: flex-end;
}
.sv-data-view-main-action-area__right-block .sv-btn,
.sv-data-view-record-action-area__right-block .sv-btn {
  margin-left: 8px;
  margin-bottom: 8px;
}
.sv-data-view-main-action-area__right-block .sv-page-size,
.sv-data-view-record-action-area__right-block .sv-page-size {
  width: 80px;
  font-size: 14px;
  letter-spacing: 0.16px;
  text-align: center;
  border: 1px solid #ced4da;
}

.sv-data-view-record-action-area__left-block {
  align-items: center;
  flex: 1 1 400px;
}

.sv-btn_action.sv-btn_action.sv-btn_action {
  color: #fff;
}

.sv-row-btn_action.sv-row-btn_action.sv-row-btn_action {
  color: black;
}

/**
 * Main action
 */
.sv-data-view-main-action-area {
  padding-bottom: 8px;
}

/**
 * Record action
 */
.sv-data-view-record-action-area {
  background: #3b5f78;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
}

.sv-data-view-record-action-btn {
  color: #fff;
  font-size: 20px;
  background: transparent;
}

/**
 * Table
 */
.sv-data-view-area .sv-table-wrapper {
  overflow: hidden;
  flex: 1 1;
}

.sv-table thead tr {
  background-color: #e0e0e0;
}
.sv-table td {
  color: #525252;
}

.sv-table.sv-table.sv-table.sv-table tbody > tr {
  background-color: #fff;
}
.sv-table.sv-table.sv-table.sv-table tbody > tr .p-button.p-button-icon-only.p-button-rounded {
  height: 1rem;
}
.sv-table.sv-table.sv-table.sv-table tbody > tr .sv-table-action-column-hover {
  display: none;
}
.sv-table.sv-table.sv-table.sv-table tbody > tr:hover {
  background: #e5e5e5;
}
.sv-table.sv-table.sv-table.sv-table tbody > tr:hover .sv-table-action-column-hover {
  display: flex;
}
.sv-table.sv-table.sv-table.sv-table th,
.sv-table.sv-table.sv-table.sv-table td {
  border: 0;
  padding-left: 8px;
  padding-right: 8px;
  line-height: 20px;
}
.sv-table.sv-table.sv-table.sv-table th {
  background-color: inherit;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #161616;
}
.sv-table.sv-table.sv-table.sv-table th .p-sortable-column-icon {
  font-size: 12px;
}
.sv-table.sv-table.sv-table.sv-table th.p-sortable-column:hover {
  background-color: #cacaca;
}
.sv-table.sv-table.sv-table.sv-table th.p-sortable-column:focus {
  color: #161616;
  outline: 2px solid #3b5f78;
  outline-offset: -2px;
}
.sv-table.sv-table.sv-table.sv-table th.p-sortable-column.p-highlight {
  color: #161616;
}
.sv-table.sv-table.sv-table.sv-table th.p-sortable-column.p-highlight .p-sortable-column-icon {
  color: inherit;
}
.sv-table.sv-table.sv-table.sv-table td {
  background: inherit;
  padding-top: 6px;
  padding-bottom: 6px;
  border-top: 1px solid #e0e0e0;
}

.sv-table,
.sv-table .p-datatable,
.sv-table .p-datatable-scrollable-view {
  height: 100%;
}
.sv-table .p-datatable {
  display: flex;
  flex-direction: column;
}
.sv-table .p-datatable-scrollable-wrapper {
  flex: 1 1;
  overflow: hidden;
}
.sv-table .p-datatable-scrollable-view {
  display: flex;
  flex-direction: column;
}
.sv-table .ui-table-scrollable-header {
  overflow: hidden;
  border: 0;
}
.sv-table .p-datatable-scrollable-body {
  flex: 1 10;
  overflow-y: auto;
}
.sv-table .p-datatable-scrollable-header-box {
  padding-right: 0px !important;
}

/**
 * Table row icon
 */
.sv-tr-icon-group {
  display: flex;
  flex-wrap: wrap;
  margin: -2px;
}
.sv-tr-icon-group.-align-left {
  justify-content: flex-start;
}
.sv-tr-icon-group.-align-center {
  justify-content: center;
}
.sv-tr-icon-group.-align-right {
  justify-content: flex-end;
}

.sv-tr-icon {
  height: 20px;
  font-size: 16px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
}

/**
 * Table expanded row
 */
.sv-table.-expandable-row tr {
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}

tr.sv-expanded-tr-top-side.sv-expanded-tr-top-side {
  border: 2px solid #3b5f78;
  border-bottom: 0;
}

tr.sv-expanded-tr-bottom-side.sv-expanded-tr-bottom-side {
  border: 2px solid #3b5f78;
  border-top: 0;
}

.sv-table.sv-table.sv-table.sv-table tbody > tr.sv-expanded-tr-bottom-side:hover {
  background-color: #fff;
}

.sv-table.sv-table.sv-table.sv-table td.sv-expanded-tr-cell {
  padding: 0;
}

/**
 * Table with status style border left
 */
.sv-table.-status-style-border-left tr {
  border-left-style: solid;
  border-left-width: 3px;
}
.sv-table.-status-style-border-left thead tr {
  border-left-color: #e0e0e0;
}
.sv-table.-status-style-border-left tbody > tr:not(.sv-tr-status) {
  border-left-color: #fff;
}
.sv-table.-status-style-border-left tbody > tr:not(.sv-tr-status):hover {
  border-left-color: #e5e5e5;
}
.sv-table.-status-style-border-left tbody > tr.sv-expanded-tr-top-side,
.sv-table.-status-style-border-left tbody > tr.sv-expanded-tr-bottom-side {
  border-left-width: 2px;
}
.sv-table.-status-style-border-left tbody > tr.sv-expanded-tr-top-side, .sv-table.-status-style-border-left tbody > tr.sv-expanded-tr-top-side:hover,
.sv-table.-status-style-border-left tbody > tr.sv-expanded-tr-bottom-side,
.sv-table.-status-style-border-left tbody > tr.sv-expanded-tr-bottom-side:hover {
  border-left-color: #3b5f78;
}

.sv-tr-status td {
  color: #161616;
}

tr.sv-tr-status-danger.sv-tr-status-danger {
  border-left-color: #da1e28;
}

tr.sv-tr-status-warning.sv-tr-status-warning {
  border-left-color: #fdd13a;
}

tr.sv-tr-status-success.sv-tr-status-success {
  border-left-color: #198038;
}

/*------------------------------------
  MODAL
//------------------------------------*/
.sv-modal {
  /*3x*/
  /*3x*/
  /**
     * Footer
     */
}
.sv-modal.force-full-width {
  max-width: 100% !important;
}
.sv-modal.p-dialog-maximized .ui-dialog-content {
  max-height: none !important;
}
.sv-modal:not(.ui-dialog-maximized) .ui-dialog-content {
  height: auto !important;
}
.sv-modal .field label {
  font-weight: bold;
}
.sv-modal .field label .required {
  color: #f00;
  margin-left: 5px;
}
.sv-modal .p-dialog {
  background-color: #ffffff;
  border-radius: 0;
}
.sv-modal .p-dialog-content.p-dialog-content {
  border: none;
  padding: 0;
  background: #fff;
  color: #495057;
}
.sv-modal .p-dialog-header.p-dialog-header {
  border: none;
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 32px 16px;
  font-weight: normal;
  background-color: #fff;
  border-radius: 0;
}
.sv-modal .p-dialog-title.p-dialog-title.p-dialog-title {
  line-height: 32px;
  font-size: 20px;
  font-weight: 500;
  color: #262626;
}
.sv-modal .p-dialog-header-icon.p-dialog-header-icon.p-dialog-header-icon {
  font-size: 18px;
  height: 32px;
  width: 32px;
  position: absolute;
  top: 0;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #525252;
  border-radius: 0;
}
.sv-modal .p-dialog-header-icon.p-dialog-header-icon.p-dialog-header-icon.p-dialog-header-close {
  right: 0;
}
.sv-modal .p-dialog-header-icon.p-dialog-header-icon.p-dialog-header-icon.p-dialog-header-maximize {
  right: 30px;
  margin-right: 0;
}
.sv-modal .p-dialog-header-icon.p-dialog-header-icon.p-dialog-header-icon:hover {
  background-color: #e5e5e5;
}
.sv-modal .p-dialog-header-icon.p-dialog-header-icon.p-dialog-header-icon:focus {
  box-shadow: 0 0 0 0;
}
.sv-modal .p-dialog-footer.p-dialog-footer {
  border: none;
  border-top: 1px solid #e0e0e0;
  padding: 24px 32px 16px 32px;
  background-color: #fff;
  border-radius: 0;
}

.sv-modal-footer-content .sv-btn {
  margin-bottom: 8px;
}

.sv-modal-footer-content__left-block {
  text-align: left;
}

.sv-modal-footer-content__left-block .sv-btn {
  margin-right: 8px;
}

.sv-modal-footer-content__right-block .sv-btn {
  margin-right: 0;
  margin-left: 8px;
}

.sv-modal-content-panel {
  padding-left: 32px;
  padding-right: 32px;
}
.sv-modal-content-panel.sv-form-container {
  padding-top: 24px;
  padding-bottom: 8px;
}
.sv-modal-content-panel .field label {
  font-weight: bold;
}
.sv-modal-content-panel .field label .required {
  color: #f00;
  margin-left: 5px;
}

/*------------------------------------
  TAB
//------------------------------------*/
.sv-modal.modal-with-tabs .ui-dialog-content {
  overflow: hidden;
}

/*p-tabView*/
.sv-modal-tabview .ui-tabview-nav.ui-tabview-nav.ui-tabview-nav {
  padding: 0 40px;
}
.sv-modal-tabview p-tabPanel,
.sv-modal-tabview .ui-tabview-panel {
  display: block;
  height: 100%;
}

.sv-modal-tab-panel-content {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
}

.user-info-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.user-info-wrapper .user-info-control-container {
  width: 400px;
  background-color: #fafafb;
  display: flex;
  flex-direction: column;
  padding-top: 20vh;
  align-items: center;
}
.user-info-wrapper .user-info-control-container .user-info-control__image {
  width: 150px;
  height: 150px;
}
.user-info-wrapper .user-info-control-container .user-info-control__image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.user-info-wrapper .user-info-control-container .user-info-control__buttons-container {
  margin-top: 50px;
}
.user-info-wrapper .user-info-control-container .user-info-control__buttons-container .user-info-control__button {
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.user-info-wrapper .user-info-control-container .user-info-control__buttons-container .user-info-control__button i,
.user-info-wrapper .user-info-control-container .user-info-control__buttons-container .user-info-control__button span {
  font-size: 15px;
  font-weight: bold;
}
.user-info-wrapper .user-info-control-container .user-info-control__buttons-container .user-info-control__button span {
  margin-left: 5px;
}
.user-info-wrapper .user-info-control-container .user-info-control__buttons-container .user-info-control__button:hover {
  color: #fcb71e;
}
.user-info-wrapper .user-info-type-container {
  margin-top: 5px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.user-info-wrapper .user-info-type-container .p-tabview .p-tabview-nav {
  border-bottom: none;
}
.user-info-wrapper .user-info-type-container .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #fcb71e;
}
.user-info-wrapper .user-info-type-container .field label {
  font-weight: bold;
}
.user-info-wrapper .user-info-type-container .field label .required {
  color: #f00;
  margin-left: 5px;
}

.table-init table {
  width: 100%;
}
.table-init table thead {
  display: table;
  /* to take the same width as tr */
  width: calc(100% - 17px);
  /* - 17px because of the scrollbar width */
}
.table-init table tbody {
  display: block;
  /* to enable vertical scrolling */
  overflow-y: scroll;
  /* keeps the scrollbar even if it doesn't need it; display purpose */
  max-height: calc(100vh - 250px);
  width: calc(100% - 17px);
}
.table-init table tbody tr {
  display: table;
  /* display purpose; th's border */
  width: 100%;
}

/*------------------------------------
  TOPBAR
//------------------------------------*/
.sv-page-login {
  background-color: #f5f5f5;
  display: flex;
  justify-content: right;
  align-items: center;
  height: 100vh;
  background-image: url("/assets/images/BackgrounLogin.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sv-page-login-content-area {
  background-color: rgba(255, 255, 255, 0.5019607843);
  width: 400px;
  padding: 25px 25px 23px;
  height: 100vh;
}

/**
 * Header
 */
.sv-page-login-content-area__header {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 37px;
  display: flex;
}

.sv-page-login-logo {
  width: 100px;
  height: 100px;
}

.sv-page-login-logo-accessory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.sv-page-login-logo-accessory__title {
  font-weight: 700;
  color: #525252;
  font-size: 14px;
  letter-spacing: 0.16px;
}
.sv-page-login-logo-accessory__slogan {
  font-weight: 400;
  font-size: 24px;
  padding-top: 2px;
  color: #262626;
}

/**
 * Body
 */
.sv-page-login-content-area__body {
  padding-top: 16px;
  padding-bottom: 15px;
}
.sv-page-login-content-area__body .sv-form-item {
  padding-bottom: 24px;
}
.sv-page-login-content-area__body .sv-form-item .sv-form-item__message-container {
  display: none;
}
.sv-page-login-content-area__body .sv-form-item.-error {
  padding-bottom: 2px;
}
.sv-page-login-content-area__body .sv-form-item.-error .sv-form-item__message-container {
  display: block;
}

.sv-page-login-password-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2px;
  margin-bottom: 28px;
}

/**
 * Footer
 */
.sv-page-login-content-area__footer {
  border-top: 1px solid #e0e0e0;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.h-hidden, [hidden] {
  display: none !important;
}

.h-visible {
  visibility: visible !important;
}

.h-invisible {
  visibility: hidden !important;
}

@media only screen and (min-width: 1025px) {
  .h-hidden-on-desktop {
    display: none;
  }
  .h-hidden-on-desktop-i {
    display: none !important;
  }
}
@media only screen and (max-width: 1024px) {
  .h-hidden-on-small-device {
    display: none;
  }
  .h-hidden-on-small-device-i {
    display: none !important;
  }
}
.h-block-el {
  display: block;
}

.h-inline-block-el {
  display: inline-block;
}

.h-flex-el {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.h-inline-flex-el {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.h-flex-el.-h-align-content-top, .h-inline-flex-el.-h-align-content-top {
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.h-flex-el.-h-align-content-middle, .h-inline-flex-el.-h-align-content-middle {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.h-flex-el.-h-align-content-bottom, .h-inline-flex-el.-h-align-content-bottom {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.h-flex-el.-h-align-content-center, .h-inline-flex-el.-h-align-content-center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.h-flex-el.-h-align-content-left, .h-inline-flex-el.-h-align-content-left {
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.h-flex-el.-h-align-content-right, .h-inline-flex-el.-h-align-content-right {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.h-flex-el.-h-space-between, .h-inline-flex-el.-h-space-between {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.h-flex-el.-h-wrap-content, .h-inline-flex-el.-h-wrap-content {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.h-flex-el.-h-x-direction, .h-inline-flex-el.-h-x-direction {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.h-flex-el.-h-x-reverse-direction, .h-inline-flex-el.-h-x-reverse-direction {
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.h-flex-el.-h-y-direction, .h-inline-flex-el.-h-y-direction {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.h-flex-el.-h-y-reverse-direction, .h-inline-flex-el.-h-y-reverse-direction {
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.h-flex-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.h-flex-ai--fs {
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.h-flex-ai--c {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.h-flex-ai--bl {
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}

.h-flex-ai--fe {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.h-flex-jc--fs {
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.h-flex-jc--c {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.h-flex-jc--fe {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.h-flex-jc--sb {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.h-flex-fd--r {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.h-flex-fd--rr {
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.h-flex-fd--c {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.h-flex-fd--cr {
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.h-block-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.h-cursor-pointer {
  cursor: pointer;
}

.h-cursor-not-allowed {
  cursor: not-allowed;
}

.h-overflow-hidden {
  overflow: hidden;
}

.h-overflow-auto {
  overflow: auto;
}

.h-overflow-x-auto {
  overflow-x: auto;
}

.h-overflow-y-auto {
  overflow-y: auto;
}

.h-overflow-x-hidden {
  overflow-x: hidden;
}

.h-overflow-y-hidden {
  overflow-y: hidden;
}

.h-vertical-align-top, th.h-align-top, td.h-align-top {
  vertical-align: top;
}

.h-vertical-align-middle, th.h-align-middle, td.h-align-middle {
  vertical-align: middle;
}

.h-vertical-align-bottom, th.h-align-bottom, td.h-align-bottom {
  vertical-align: bottom;
}

.h-x-line-top--size-1 {
  border-top: 1px solid #e0e0e0;
}

.h-x-line-bottom--size-1 {
  border-bottom: 1px solid #e0e0e0;
}

.h-y-line-left--size-1 {
  border-left: 1px solid #e0e0e0;
}

.h-y-line-right--size-1 {
  border-right: 1px solid #e0e0e0;
}

.ui-g-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
}

.ui-g.-row, .ui-g.-row.-small-gutter {
  margin-left: -8px;
  margin-right: -8px;
}
.ui-g.-row.-medium-gutter {
  margin-left: -12px;
  margin-right: -12px;
}
.ui-g.-row.-large-gutter {
  margin-left: -16px;
  margin-right: -16px;
}

.ui-g [class^=ui-g-], .ui-g [class*=" ui-g-"],
.ui-g [class^=ui-md-], .ui-g [class*=" ui-md-"],
.ui-g [class^=ui-lg-], .ui-g [class*=" ui-lg-"],
.ui-g [class^=ui-xl-], .ui-g [class*=" ui-xl-"], .ui-g.-small-gutter [class^=ui-g-], .ui-g.-small-gutter [class*=" ui-g-"],
.ui-g.-small-gutter [class^=ui-md-], .ui-g.-small-gutter [class*=" ui-md-"],
.ui-g.-small-gutter [class^=ui-lg-], .ui-g.-small-gutter [class*=" ui-lg-"],
.ui-g.-small-gutter [class^=ui-xl-], .ui-g.-small-gutter [class*=" ui-xl-"] {
  padding: 0 8px;
}

.ui-g.-medium-gutter [class^=ui-g-], .ui-g.-medium-gutter [class*=" ui-g-"],
.ui-g.-medium-gutter [class^=ui-md-], .ui-g.-medium-gutter [class*=" ui-md-"],
.ui-g.-medium-gutter [class^=ui-lg-], .ui-g.-medium-gutter [class*=" ui-lg-"],
.ui-g.-medium-gutter [class^=ui-xl-], .ui-g.-medium-gutter [class*=" ui-xl-"] {
  padding: 0 12px;
}

.ui-g.-large-gutter [class^=ui-g-], .ui-g.-large-gutter [class*=" ui-g-"],
.ui-g.-large-gutter [class^=ui-md-], .ui-g.-large-gutter [class*=" ui-md-"],
.ui-g.-large-gutter [class^=ui-lg-], .ui-g.-large-gutter [class*=" ui-lg-"],
.ui-g.-large-gutter [class^=ui-xl-], .ui-g.-large-gutter [class*=" ui-xl-"] {
  padding: 0 16px;
}

.ui-g [class^=ui-g-].ui-g-nopad, .ui-g [class*=" ui-g-"].ui-g-nopad,
.ui-g [class^=ui-md-].ui-g-nopad, .ui-g [class*=" ui-md-"].ui-g-nopad,
.ui-g [class^=ui-lg-].ui-g-nopad, .ui-g [class*=" ui-lg-"].ui-g-nopad,
.ui-g [class^=ui-xl-].ui-g-nopad, .ui-g [class*=" ui-xl-"].ui-g-nopad, .ui-g.-small-gutter [class^=ui-g-].ui-g-nopad, .ui-g.-small-gutter [class*=" ui-g-"].ui-g-nopad,
.ui-g.-small-gutter [class^=ui-md-].ui-g-nopad, .ui-g.-small-gutter [class*=" ui-md-"].ui-g-nopad,
.ui-g.-small-gutter [class^=ui-lg-].ui-g-nopad, .ui-g.-small-gutter [class*=" ui-lg-"].ui-g-nopad,
.ui-g.-small-gutter [class^=ui-xl-].ui-g-nopad, .ui-g.-small-gutter [class*=" ui-xl-"].ui-g-nopad,
.ui-g.-medium-gutter [class^=ui-g-].ui-g-nopad,
.ui-g.-medium-gutter [class*=" ui-g-"].ui-g-nopad,
.ui-g.-medium-gutter [class^=ui-md-].ui-g-nopad,
.ui-g.-medium-gutter [class*=" ui-md-"].ui-g-nopad,
.ui-g.-medium-gutter [class^=ui-lg-].ui-g-nopad,
.ui-g.-medium-gutter [class*=" ui-lg-"].ui-g-nopad,
.ui-g.-medium-gutter [class^=ui-xl-].ui-g-nopad,
.ui-g.-medium-gutter [class*=" ui-xl-"].ui-g-nopad,
.ui-g.-large-gutter [class^=ui-g-].ui-g-nopad,
.ui-g.-large-gutter [class*=" ui-g-"].ui-g-nopad,
.ui-g.-large-gutter [class^=ui-md-].ui-g-nopad,
.ui-g.-large-gutter [class*=" ui-md-"].ui-g-nopad,
.ui-g.-large-gutter [class^=ui-lg-].ui-g-nopad,
.ui-g.-large-gutter [class*=" ui-lg-"].ui-g-nopad,
.ui-g.-large-gutter [class^=ui-xl-].ui-g-nopad,
.ui-g.-large-gutter [class*=" ui-xl-"].ui-g-nopad {
  padding: 0;
}

.h-clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.h-m-4, .h-my-4, .h-mt-4 {
  margin-top: 4px;
}

.h-m-4, .h-my-4, .h-mb-4 {
  margin-bottom: 4px;
}

.h-m-4, .h-mx-4, .h-ml-4 {
  margin-left: 4px;
}

.h-m-4, .h-mx-4, .h-mr-4 {
  margin-right: 4px;
}

.h-m-8, .h-my-8, .h-mt-8 {
  margin-top: 8px;
}

.h-m-8, .h-my-8, .h-mb-8 {
  margin-bottom: 8px;
}

.h-m-8, .h-mx-8, .h-ml-8 {
  margin-left: 8px;
}

.h-m-8, .h-mx-8, .h-mr-8 {
  margin-right: 8px;
}

.h-m-12, .h-my-12, .h-mt-12 {
  margin-top: 12px;
}

.h-m-12, .h-my-12, .h-mb-12 {
  margin-bottom: 12px;
}

.h-m-12, .h-mx-12, .h-ml-12 {
  margin-left: 12px;
}

.h-m-12, .h-mx-12, .h-mr-12 {
  margin-right: 12px;
}

.h-m-16, .h-my-16, .h-mt-16 {
  margin-top: 16px;
}

.h-m-16, .h-my-16, .h-mb-16 {
  margin-bottom: 16px;
}

.h-m-16, .h-mx-16, .h-ml-16 {
  margin-left: 16px;
}

.h-m-16, .h-mx-16, .h-mr-16 {
  margin-right: 16px;
}

.h-m-20, .h-my-20, .h-mt-20 {
  margin-top: 20px;
}

.h-m-20, .h-my-20, .h-mb-20 {
  margin-bottom: 20px;
}

.h-m-20, .h-mx-20, .h-ml-20 {
  margin-left: 20px;
}

.h-m-20, .h-mx-20, .h-mr-20 {
  margin-right: 20px;
}

.h-m-24, .h-my-24, .h-mt-24 {
  margin-top: 24px;
}

.h-m-24, .h-my-24, .h-mb-24 {
  margin-bottom: 24px;
}

.h-m-24, .h-mx-24, .h-ml-24 {
  margin-left: 24px;
}

.h-m-24, .h-mx-24, .h-mr-24 {
  margin-right: 24px;
}

.h-m-28, .h-my-28, .h-mt-28 {
  margin-top: 28px;
}

.h-m-28, .h-my-28, .h-mb-28 {
  margin-bottom: 28px;
}

.h-m-28, .h-mx-28, .h-ml-28 {
  margin-left: 28px;
}

.h-m-28, .h-mx-28, .h-mr-28 {
  margin-right: 28px;
}

.h-m-32, .h-my-32, .h-mt-32 {
  margin-top: 32px;
}

.h-m-32, .h-my-32, .h-mb-32 {
  margin-bottom: 32px;
}

.h-m-32, .h-mx-32, .h-ml-32 {
  margin-left: 32px;
}

.h-m-32, .h-mx-32, .h-mr-32 {
  margin-right: 32px;
}

.h-m-n-4, .h-my-n-4, .h-mt-n-4 {
  margin-top: -4px;
}

.h-m-n-4, .h-my-n-4, .h-mb-n-4 {
  margin-bottom: -4px;
}

.h-m-n-4, .h-mx-n-4, .h-ml-n-4 {
  margin-left: -4px;
}

.h-m-n-4, .h-mx-n-4, .h-mr-n-4 {
  margin-right: -4px;
}

.h-m-n-8, .h-my-n-8, .h-mt-n-8 {
  margin-top: -8px;
}

.h-m-n-8, .h-my-n-8, .h-mb-n-8 {
  margin-bottom: -8px;
}

.h-m-n-8, .h-mx-n-8, .h-ml-n-8 {
  margin-left: -8px;
}

.h-m-n-8, .h-mx-n-8, .h-mr-n-8 {
  margin-right: -8px;
}

.h-m-n-12, .h-my-n-12, .h-mt-n-12 {
  margin-top: -12px;
}

.h-m-n-12, .h-my-n-12, .h-mb-n-12 {
  margin-bottom: -12px;
}

.h-m-n-12, .h-mx-n-12, .h-ml-n-12 {
  margin-left: -12px;
}

.h-m-n-12, .h-mx-n-12, .h-mr-n-12 {
  margin-right: -12px;
}

.h-m-n-16, .h-my-n-16, .h-mt-n-16 {
  margin-top: -16px;
}

.h-m-n-16, .h-my-n-16, .h-mb-n-16 {
  margin-bottom: -16px;
}

.h-m-n-16, .h-mx-n-16, .h-ml-n-16 {
  margin-left: -16px;
}

.h-m-n-16, .h-mx-n-16, .h-mr-n-16 {
  margin-right: -16px;
}

.h-m-n-20, .h-my-n-20, .h-mt-n-20 {
  margin-top: -20px;
}

.h-m-n-20, .h-my-n-20, .h-mb-n-20 {
  margin-bottom: -20px;
}

.h-m-n-20, .h-mx-n-20, .h-ml-n-20 {
  margin-left: -20px;
}

.h-m-n-20, .h-mx-n-20, .h-mr-n-20 {
  margin-right: -20px;
}

.h-m-n-24, .h-my-n-24, .h-mt-n-24 {
  margin-top: -24px;
}

.h-m-n-24, .h-my-n-24, .h-mb-n-24 {
  margin-bottom: -24px;
}

.h-m-n-24, .h-mx-n-24, .h-ml-n-24 {
  margin-left: -24px;
}

.h-m-n-24, .h-mx-n-24, .h-mr-n-24 {
  margin-right: -24px;
}

.h-m-n-28, .h-my-n-28, .h-mt-n-28 {
  margin-top: -28px;
}

.h-m-n-28, .h-my-n-28, .h-mb-n-28 {
  margin-bottom: -28px;
}

.h-m-n-28, .h-mx-n-28, .h-ml-n-28 {
  margin-left: -28px;
}

.h-m-n-28, .h-mx-n-28, .h-mr-n-28 {
  margin-right: -28px;
}

.h-m-n-32, .h-my-n-32, .h-mt-n-32 {
  margin-top: -32px;
}

.h-m-n-32, .h-my-n-32, .h-mb-n-32 {
  margin-bottom: -32px;
}

.h-m-n-32, .h-mx-n-32, .h-ml-n-32 {
  margin-left: -32px;
}

.h-m-n-32, .h-mx-n-32, .h-mr-n-32 {
  margin-right: -32px;
}

.h-p-4, .h-py-4, .h-pt-4 {
  padding-top: 4px;
}

.h-p-4, .h-py-4, .h-pb-4 {
  padding-bottom: 4px;
}

.h-p-4, .h-px-4, .h-pl-4 {
  padding-left: 4px;
}

.h-p-4, .h-px-4, .h-pr-4 {
  padding-right: 4px;
}

.h-p-8, .h-py-8, .h-pt-8 {
  padding-top: 8px;
}

.h-p-8, .h-py-8, .h-pb-8 {
  padding-bottom: 8px;
}

.h-p-8, .h-px-8, .h-pl-8 {
  padding-left: 8px;
}

.h-p-8, .h-px-8, .h-pr-8 {
  padding-right: 8px;
}

.h-p-12, .h-py-12, .h-pt-12 {
  padding-top: 12px;
}

.h-p-12, .h-py-12, .h-pb-12 {
  padding-bottom: 12px;
}

.h-p-12, .h-px-12, .h-pl-12 {
  padding-left: 12px;
}

.h-p-12, .h-px-12, .h-pr-12 {
  padding-right: 12px;
}

.h-p-16, .h-py-16, .h-pt-16 {
  padding-top: 16px;
}

.h-p-16, .h-py-16, .h-pb-16 {
  padding-bottom: 16px;
}

.h-p-16, .h-px-16, .h-pl-16 {
  padding-left: 16px;
}

.h-p-16, .h-px-16, .h-pr-16 {
  padding-right: 16px;
}

.h-p-20, .h-py-20, .h-pt-20 {
  padding-top: 20px;
}

.h-p-20, .h-py-20, .h-pb-20 {
  padding-bottom: 20px;
}

.h-p-20, .h-px-20, .h-pl-20 {
  padding-left: 20px;
}

.h-p-20, .h-px-20, .h-pr-20 {
  padding-right: 20px;
}

.h-p-24, .h-py-24, .h-pt-24 {
  padding-top: 24px;
}

.h-p-24, .h-py-24, .h-pb-24 {
  padding-bottom: 24px;
}

.h-p-24, .h-px-24, .h-pl-24 {
  padding-left: 24px;
}

.h-p-24, .h-px-24, .h-pr-24 {
  padding-right: 24px;
}

.h-p-28, .h-py-28, .h-pt-28 {
  padding-top: 28px;
}

.h-p-28, .h-py-28, .h-pb-28 {
  padding-bottom: 28px;
}

.h-p-28, .h-px-28, .h-pl-28 {
  padding-left: 28px;
}

.h-p-28, .h-px-28, .h-pr-28 {
  padding-right: 28px;
}

.h-p-32, .h-py-32, .h-pt-32 {
  padding-top: 32px;
}

.h-p-32, .h-py-32, .h-pb-32 {
  padding-bottom: 32px;
}

.h-p-32, .h-px-32, .h-pl-32 {
  padding-left: 32px;
}

.h-p-32, .h-px-32, .h-pr-32 {
  padding-right: 32px;
}

.h-fz-8 {
  font-size: 8px;
}

.h-fz-12 {
  font-size: 12px;
}

.h-fz-16 {
  font-size: 16px;
}

.h-fz-20 {
  font-size: 20px;
}

.h-fz-24 {
  font-size: 24px;
}

.h-fz-28 {
  font-size: 28px;
}

.h-fz-32 {
  font-size: 32px;
}

.h-fz-36 {
  font-size: 36px;
}

.h-fz-40 {
  font-size: 40px;
}

.h-fz-44 {
  font-size: 44px;
}

.h-fz-48 {
  font-size: 48px;
}

.h-fz-52 {
  font-size: 52px;
}

.h-fz-56 {
  font-size: 56px;
}

.h-fz-60 {
  font-size: 60px;
}

.h-fz-64 {
  font-size: 64px;
}

.h-fz-common {
  font-size: 14px;
}

.h-text-lowercase {
  text-transform: lowercase;
}

.h-text-uppercase {
  text-transform: uppercase;
}

.h-text-capitalize {
  text-transform: capitalize;
}

.h-text-underline,
.h-text-hover-underline.-activated,
.h-text-hover-underline:hover {
  text-decoration: underline;
}

.h-text-line-through {
  text-decoration: line-through;
}

.h-fs-inherit {
  font-style: inherit;
}

.h-fs-italic {
  font-style: italic;
}

.h-fs-normal {
  font-style: normal;
}

.h-align-left,
.h-text-left {
  text-align: left;
}

.h-align-center,
.h-text-center {
  text-align: center;
}

.h-align-right,
.h-text-right {
  text-align: right;
}

@media only screen and (max-width: 640px) {
  .h-align-sm-left,
  .h-text-sm-left {
    text-align: left;
  }
  .h-align-sm-center,
  .h-text-sm-center {
    text-align: center;
  }
  .h-align-sm-right,
  .h-text-sm-right {
    text-align: right;
  }
}
@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .h-align-md-left,
  .h-text-md-left {
    text-align: left;
  }
  .h-align-md-center,
  .h-text-md-center {
    text-align: center;
  }
  .h-align-md-right,
  .h-text-md-right {
    text-align: right;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .h-align-lg-left,
  .h-text-lg-left {
    text-align: left;
  }
  .h-align-lg-center,
  .h-text-lg-center {
    text-align: center;
  }
  .h-align-lg-right,
  .h-text-lg-right {
    text-align: right;
  }
}
@media only screen and (min-width: 1441px) {
  .h-align-xl-left,
  .h-text-xl-left {
    text-align: left;
  }
  .h-align-xl-center,
  .h-text-xl-center {
    text-align: center;
  }
  .h-align-xl-right,
  .h-text-xl-right {
    text-align: right;
  }
}
.h-prevent-line-break {
  white-space: nowrap;
}

.h-text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.h-overflow-break-line {
  word-break: break-all;
}

.h-overflow-break-word {
  word-break: break-word;
}

.h-fw-inherit {
  font-weight: inherit;
}

.h-fw-inherit-i {
  font-weight: inherit !important;
}

.h-fw-light {
  font-weight: 300;
}

.h-fw-light-i {
  font-weight: 300 !important;
}

.h-fw-regular {
  font-weight: 400;
}

.h-fw-regular-i {
  font-weight: 400 !important;
}

.h-fw-medium {
  font-weight: 500;
}

.h-fw-medium-i {
  font-weight: 500 !important;
}

.h-fw-semibold {
  font-weight: 600;
}

.h-fw-semibold-i {
  font-weight: 600 !important;
}

.h-fw-bold {
  font-weight: 700;
}

.h-fw-bold-i {
  font-weight: 700 !important;
}

.h-easing {
  transition-duration: 240ms;
}
.h-easing, .h-easing--flash, .h-easing--short, .h-easing--long {
  transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
}
.h-easing--flash {
  transition-duration: 70ms;
}
.h-easing--short {
  transition-duration: 110ms;
}
.h-easing--long {
  transition-duration: 400ms;
}

.sv-color--transparent,
.sv-color--transparent--hover:not(.-activated):hover,
.sv-color--transparent--active:not(.-activated):active,
.sv-color--transparent--activated.-activated {
  color: transparent;
}

.sv-bgc--transparent,
.sv-bgc--transparent--hover:not(.-activated):hover,
.sv-bgc--transparent--active:not(.-activated):active,
.sv-bgc--transparent--activated.-activated {
  background-color: transparent;
}

.sv-color-trigger:not(.-activated):hover .sv-color--transparent--triggered-hover {
  color: transparent;
}
.sv-color-trigger:not(.-activated):active .sv-color--transparent--triggered-active {
  color: transparent;
}
.sv-color-trigger.-activated .sv-color--transparent--triggered-activated {
  color: transparent;
}

.sv-bgc-trigger:not(.-activated):hover .sv-bgc--transparent--triggered-hover {
  background-color: transparent;
}
.sv-bgc-trigger:not(.-activated):active .sv-bgc--transparent--triggered-active {
  background-color: transparent;
}
.sv-bgc-trigger.-activated .sv-bgc--transparent--triggered-activated {
  background-color: transparent;
}

.sv-color--primary,
.sv-color--primary--hover:not(.-activated):hover,
.sv-color--primary--active:not(.-activated):active,
.sv-color--primary--activated.-activated {
  color: #3b5f78;
}

.sv-bgc--primary,
.sv-bgc--primary--hover:not(.-activated):hover,
.sv-bgc--primary--active:not(.-activated):active,
.sv-bgc--primary--activated.-activated {
  background-color: #3b5f78;
}

.sv-color-trigger:not(.-activated):hover .sv-color--primary--triggered-hover {
  color: #3b5f78;
}
.sv-color-trigger:not(.-activated):active .sv-color--primary--triggered-active {
  color: #3b5f78;
}
.sv-color-trigger.-activated .sv-color--primary--triggered-activated {
  color: #3b5f78;
}

.sv-bgc-trigger:not(.-activated):hover .sv-bgc--primary--triggered-hover {
  background-color: #3b5f78;
}
.sv-bgc-trigger:not(.-activated):active .sv-bgc--primary--triggered-active {
  background-color: #3b5f78;
}
.sv-bgc-trigger.-activated .sv-bgc--primary--triggered-activated {
  background-color: #3b5f78;
}

.sv-color--primary-alt,
.sv-color--primary-alt--hover:not(.-activated):hover,
.sv-color--primary-alt--active:not(.-activated):active,
.sv-color--primary-alt--activated.-activated {
  color: #4b5d73;
}

.sv-bgc--primary-alt,
.sv-bgc--primary-alt--hover:not(.-activated):hover,
.sv-bgc--primary-alt--active:not(.-activated):active,
.sv-bgc--primary-alt--activated.-activated {
  background-color: #4b5d73;
}

.sv-color-trigger:not(.-activated):hover .sv-color--primary-alt--triggered-hover {
  color: #4b5d73;
}
.sv-color-trigger:not(.-activated):active .sv-color--primary-alt--triggered-active {
  color: #4b5d73;
}
.sv-color-trigger.-activated .sv-color--primary-alt--triggered-activated {
  color: #4b5d73;
}

.sv-bgc-trigger:not(.-activated):hover .sv-bgc--primary-alt--triggered-hover {
  background-color: #4b5d73;
}
.sv-bgc-trigger:not(.-activated):active .sv-bgc--primary-alt--triggered-active {
  background-color: #4b5d73;
}
.sv-bgc-trigger.-activated .sv-bgc--primary-alt--triggered-activated {
  background-color: #4b5d73;
}

.sv-color--secondary,
.sv-color--secondary--hover:not(.-activated):hover,
.sv-color--secondary--active:not(.-activated):active,
.sv-color--secondary--activated.-activated {
  color: #ffa22b;
}

.sv-bgc--secondary,
.sv-bgc--secondary--hover:not(.-activated):hover,
.sv-bgc--secondary--active:not(.-activated):active,
.sv-bgc--secondary--activated.-activated {
  background-color: #ffa22b;
}

.sv-color-trigger:not(.-activated):hover .sv-color--secondary--triggered-hover {
  color: #ffa22b;
}
.sv-color-trigger:not(.-activated):active .sv-color--secondary--triggered-active {
  color: #ffa22b;
}
.sv-color-trigger.-activated .sv-color--secondary--triggered-activated {
  color: #ffa22b;
}

.sv-bgc-trigger:not(.-activated):hover .sv-bgc--secondary--triggered-hover {
  background-color: #ffa22b;
}
.sv-bgc-trigger:not(.-activated):active .sv-bgc--secondary--triggered-active {
  background-color: #ffa22b;
}
.sv-bgc-trigger.-activated .sv-bgc--secondary--triggered-activated {
  background-color: #ffa22b;
}

.sv-color--secondary-alt,
.sv-color--secondary-alt--hover:not(.-activated):hover,
.sv-color--secondary-alt--active:not(.-activated):active,
.sv-color--secondary-alt--activated.-activated {
  color: #fab902;
}

.sv-bgc--secondary-alt,
.sv-bgc--secondary-alt--hover:not(.-activated):hover,
.sv-bgc--secondary-alt--active:not(.-activated):active,
.sv-bgc--secondary-alt--activated.-activated {
  background-color: #fab902;
}

.sv-color-trigger:not(.-activated):hover .sv-color--secondary-alt--triggered-hover {
  color: #fab902;
}
.sv-color-trigger:not(.-activated):active .sv-color--secondary-alt--triggered-active {
  color: #fab902;
}
.sv-color-trigger.-activated .sv-color--secondary-alt--triggered-activated {
  color: #fab902;
}

.sv-bgc-trigger:not(.-activated):hover .sv-bgc--secondary-alt--triggered-hover {
  background-color: #fab902;
}
.sv-bgc-trigger:not(.-activated):active .sv-bgc--secondary-alt--triggered-active {
  background-color: #fab902;
}
.sv-bgc-trigger.-activated .sv-bgc--secondary-alt--triggered-activated {
  background-color: #fab902;
}

.sv-color--secondary-alt-2,
.sv-color--secondary-alt-2--hover:not(.-activated):hover,
.sv-color--secondary-alt-2--active:not(.-activated):active,
.sv-color--secondary-alt-2--activated.-activated {
  color: #fa8002;
}

.sv-bgc--secondary-alt-2,
.sv-bgc--secondary-alt-2--hover:not(.-activated):hover,
.sv-bgc--secondary-alt-2--active:not(.-activated):active,
.sv-bgc--secondary-alt-2--activated.-activated {
  background-color: #fa8002;
}

.sv-color-trigger:not(.-activated):hover .sv-color--secondary-alt-2--triggered-hover {
  color: #fa8002;
}
.sv-color-trigger:not(.-activated):active .sv-color--secondary-alt-2--triggered-active {
  color: #fa8002;
}
.sv-color-trigger.-activated .sv-color--secondary-alt-2--triggered-activated {
  color: #fa8002;
}

.sv-bgc-trigger:not(.-activated):hover .sv-bgc--secondary-alt-2--triggered-hover {
  background-color: #fa8002;
}
.sv-bgc-trigger:not(.-activated):active .sv-bgc--secondary-alt-2--triggered-active {
  background-color: #fa8002;
}
.sv-bgc-trigger.-activated .sv-bgc--secondary-alt-2--triggered-activated {
  background-color: #fa8002;
}

.sv-color--text-common,
.sv-color--text-common--hover:not(.-activated):hover,
.sv-color--text-common--active:not(.-activated):active,
.sv-color--text-common--activated.-activated {
  color: #525252;
}

.sv-bgc--text-common,
.sv-bgc--text-common--hover:not(.-activated):hover,
.sv-bgc--text-common--active:not(.-activated):active,
.sv-bgc--text-common--activated.-activated {
  background-color: #525252;
}

.sv-color-trigger:not(.-activated):hover .sv-color--text-common--triggered-hover {
  color: #525252;
}
.sv-color-trigger:not(.-activated):active .sv-color--text-common--triggered-active {
  color: #525252;
}
.sv-color-trigger.-activated .sv-color--text-common--triggered-activated {
  color: #525252;
}

.sv-bgc-trigger:not(.-activated):hover .sv-bgc--text-common--triggered-hover {
  background-color: #525252;
}
.sv-bgc-trigger:not(.-activated):active .sv-bgc--text-common--triggered-active {
  background-color: #525252;
}
.sv-bgc-trigger.-activated .sv-bgc--text-common--triggered-activated {
  background-color: #525252;
}

.sv-color--text-1,
.sv-color--text-1--hover:not(.-activated):hover,
.sv-color--text-1--active:not(.-activated):active,
.sv-color--text-1--activated.-activated {
  color: #161616;
}

.sv-bgc--text-1,
.sv-bgc--text-1--hover:not(.-activated):hover,
.sv-bgc--text-1--active:not(.-activated):active,
.sv-bgc--text-1--activated.-activated {
  background-color: #161616;
}

.sv-color-trigger:not(.-activated):hover .sv-color--text-1--triggered-hover {
  color: #161616;
}
.sv-color-trigger:not(.-activated):active .sv-color--text-1--triggered-active {
  color: #161616;
}
.sv-color-trigger.-activated .sv-color--text-1--triggered-activated {
  color: #161616;
}

.sv-bgc-trigger:not(.-activated):hover .sv-bgc--text-1--triggered-hover {
  background-color: #161616;
}
.sv-bgc-trigger:not(.-activated):active .sv-bgc--text-1--triggered-active {
  background-color: #161616;
}
.sv-bgc-trigger.-activated .sv-bgc--text-1--triggered-activated {
  background-color: #161616;
}

.sv-color--text-2,
.sv-color--text-2--hover:not(.-activated):hover,
.sv-color--text-2--active:not(.-activated):active,
.sv-color--text-2--activated.-activated {
  color: #262626;
}

.sv-bgc--text-2,
.sv-bgc--text-2--hover:not(.-activated):hover,
.sv-bgc--text-2--active:not(.-activated):active,
.sv-bgc--text-2--activated.-activated {
  background-color: #262626;
}

.sv-color-trigger:not(.-activated):hover .sv-color--text-2--triggered-hover {
  color: #262626;
}
.sv-color-trigger:not(.-activated):active .sv-color--text-2--triggered-active {
  color: #262626;
}
.sv-color-trigger.-activated .sv-color--text-2--triggered-activated {
  color: #262626;
}

.sv-bgc-trigger:not(.-activated):hover .sv-bgc--text-2--triggered-hover {
  background-color: #262626;
}
.sv-bgc-trigger:not(.-activated):active .sv-bgc--text-2--triggered-active {
  background-color: #262626;
}
.sv-bgc-trigger.-activated .sv-bgc--text-2--triggered-activated {
  background-color: #262626;
}

.sv-color--text-3,
.sv-color--text-3--hover:not(.-activated):hover,
.sv-color--text-3--active:not(.-activated):active,
.sv-color--text-3--activated.-activated {
  color: #393939;
}

.sv-bgc--text-3,
.sv-bgc--text-3--hover:not(.-activated):hover,
.sv-bgc--text-3--active:not(.-activated):active,
.sv-bgc--text-3--activated.-activated {
  background-color: #393939;
}

.sv-color-trigger:not(.-activated):hover .sv-color--text-3--triggered-hover {
  color: #393939;
}
.sv-color-trigger:not(.-activated):active .sv-color--text-3--triggered-active {
  color: #393939;
}
.sv-color-trigger.-activated .sv-color--text-3--triggered-activated {
  color: #393939;
}

.sv-bgc-trigger:not(.-activated):hover .sv-bgc--text-3--triggered-hover {
  background-color: #393939;
}
.sv-bgc-trigger:not(.-activated):active .sv-bgc--text-3--triggered-active {
  background-color: #393939;
}
.sv-bgc-trigger.-activated .sv-bgc--text-3--triggered-activated {
  background-color: #393939;
}

.sv-color--hover-ui,
.sv-color--hover-ui--hover:not(.-activated):hover,
.sv-color--hover-ui--active:not(.-activated):active,
.sv-color--hover-ui--activated.-activated {
  color: #e5e5e5;
}

.sv-bgc--hover-ui,
.sv-bgc--hover-ui--hover:not(.-activated):hover,
.sv-bgc--hover-ui--active:not(.-activated):active,
.sv-bgc--hover-ui--activated.-activated {
  background-color: #e5e5e5;
}

.sv-color-trigger:not(.-activated):hover .sv-color--hover-ui--triggered-hover {
  color: #e5e5e5;
}
.sv-color-trigger:not(.-activated):active .sv-color--hover-ui--triggered-active {
  color: #e5e5e5;
}
.sv-color-trigger.-activated .sv-color--hover-ui--triggered-activated {
  color: #e5e5e5;
}

.sv-bgc-trigger:not(.-activated):hover .sv-bgc--hover-ui--triggered-hover {
  background-color: #e5e5e5;
}
.sv-bgc-trigger:not(.-activated):active .sv-bgc--hover-ui--triggered-active {
  background-color: #e5e5e5;
}
.sv-bgc-trigger.-activated .sv-bgc--hover-ui--triggered-activated {
  background-color: #e5e5e5;
}

.p-button {
  background: #ffa22b;
  border: 1px solid #ffa22b;
  color: #000000;
}
.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
  background: #fab902;
  border: 1px solid #fab902;
  color: #000000;
}
.p-button:enabled:active, .p-button:not(button):not(a):not(.p-disabled):active {
  background: #fab902;
  border: 1px solid #fab902;
  color: #000000;
}

.p-dropdown-panel {
  background: #f4f4f4;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  border: 1px solid transparent;
  border-top-color: #e0e0e0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: #c6c6c6;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: #c6c6c6;
}

.p-multiselect-panel {
  background: #f4f4f4;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  border: 1px solid transparent;
  border-top-color: #e0e0e0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: #c6c6c6;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  background: #c6c6c6;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: #3b5f78;
}

.p-dialog {
  box-shadow: 0px 18px 50px -4px rgba(68, 72, 109, 0.1);
  border: 0 none;
}

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #f4f4f4;
  color: #44486d;
  padding: 1.286rem 1.714rem;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 500;
  font-size: 1.143rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #161616;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #161616;
  border-color: transparent;
  background: rgba(68, 72, 109, 0.07);
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}

.p-dialog .p-dialog-content {
  background: #f4f4f4;
  color: #161616;
  padding: 0 1.714rem 1.714rem 1.714rem;
}

.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #f4f4f4;
  color: #161616;
  padding: 0 1.714rem 1.714rem 1.714rem;
  text-align: right;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-overlaypanel {
  background: #f4f4f4;
  color: #161616;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0px 18px 50px -4px rgba(68, 72, 109, 0.1);
}

.sv-custom-overlay-panel.p-overlaypanel .p-overlaypanel-content {
  padding: 0px;
}

.p-scrollpanel-content {
  width: 100%;
}

.row-group-title {
  background-color: #e5e5e5 !important;
  font-weight: bold;
}

.p-checkbox-label {
  margin-left: 8px;
  cursor: pointer;
}

.p-checkbox .p-checkbox-box.p-highlight.p-checkbox-box.p-highlight {
  border-color: #3b5f78;
}

.-error .ng-invalid {
  border-color: #da1e28;
}
.-error .ng-invalid .p-component {
  border-color: #da1e28;
}

.fl {
  display: flex;
  flex-direction: row;
}

.fr {
  display: flex;
  flex-direction: row-reverse;
}